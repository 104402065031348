import clsx from 'clsx';

const RipplingLogo = ({ className }: { className?: string }) => (
  <svg
    className={clsx('w-full', className)}
    fill="currentColor"
    viewBox="0 0 127 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Logo">
      <path
        d="M2.87814 4.99046C2.87814 3.01064 1.8749 1.37442 0 0H4.35833C5.88785 1.17807 6.8253 2.97791 6.8253 4.99046C6.8253 7.003 5.88785 8.80284 4.35833 9.98091C5.77273 10.5699 6.57861 12.0098 6.57861 14.0714V17.9984H2.63144V14.0714C2.63144 12.108 1.69399 10.7336 0 9.98091C1.8749 8.60649 2.87814 6.97028 2.87814 4.99046ZM11.4303 4.99046C11.4303 3.01064 10.4271 1.37442 8.55219 0H12.9105C14.44 1.17807 15.3775 2.97791 15.3775 4.99046C15.3775 7.003 14.44 8.80284 12.9105 9.98091C14.3249 10.5699 15.1308 12.0098 15.1308 14.0714V17.9984H11.1836V14.0714C11.1836 12.108 10.2462 10.7336 8.55219 9.98091C10.4271 8.60649 11.4303 6.97028 11.4303 4.99046ZM19.9842 4.99046C19.9842 3.01064 18.9809 1.37442 17.106 0H21.4643C22.9939 1.17807 23.9313 2.97791 23.9313 4.99046C23.9313 7.003 22.9939 8.80284 21.4643 9.98091C22.8787 10.5699 23.6846 12.0098 23.6846 14.0714V17.9984H19.7375V14.0714C19.7375 12.108 18.8 10.7336 17.106 9.98091C18.9809 8.60649 19.9842 6.97028 19.9842 4.99046Z"
        id="Vector"
      />
      <path
        d="M35.9542 14.7286H32.9609V3.27344H40.3125C43.8485 3.27344 45.5919 4.58241 45.5919 6.72585C45.5919 8.18208 44.7366 9.27835 43.1413 9.83466C44.786 10.0801 45.5261 10.9473 45.5261 12.5017V14.7269H42.4999V12.6326C42.4999 11.3236 41.842 10.8 40.1152 10.8H35.9542V14.7286ZM40.1481 4.94238H35.9542V9.13109H40.1152C41.6282 9.13109 42.5657 8.29662 42.5657 6.98765C42.5657 5.69504 41.694 4.94238 40.1481 4.94238Z"
        id="Vector_2"
      />
      <path
        d="M50.7901 3.27344H47.7969V14.7286H50.7901V3.27344Z"
        id="Vector_3"
      />
      <path
        d="M59.9647 10.9964H56.3136V14.7269H53.3203V3.27344H60.0305C63.5665 3.27344 65.4249 4.74603 65.4249 7.10218C65.4249 9.54014 63.5336 10.9964 59.9647 10.9964ZM59.8989 4.94238H56.3136V9.32743H59.866C61.412 9.32743 62.3659 8.54205 62.3659 7.11854C62.3659 5.72776 61.412 4.94238 59.8989 4.94238Z"
        id="Vector_4"
      />
      <path
        d="M73.8475 10.9964H70.1964V14.7269H67.2031V3.27344H73.9133C77.4493 3.27344 79.3078 4.74603 79.3078 7.10218C79.3078 9.54014 77.4164 10.9964 73.8475 10.9964ZM73.7817 4.94238H70.1964V9.32743H73.7488C75.2948 9.32743 76.2487 8.54205 76.2487 7.11854C76.2487 5.72776 75.2948 4.94238 73.7817 4.94238Z"
        id="Vector_5"
      />
      <path
        d="M84.0714 3.27344V13.0253H91.5381V14.7286H81.0781V3.27344H84.0714Z"
        id="Vector_6"
      />
      <path
        d="M96.4073 3.27344H93.4141V14.7286H96.4073V3.27344Z"
        id="Vector_7"
      />
      <path
        d="M100.911 7.10218V14.7286H98.9375V3.27344H101.176L109.333 10.8982V3.27344H111.307V14.7286H109.07L100.911 7.10218Z"
        id="Vector_8"
      />
      <path
        d="M121.079 4.61278C118.233 4.61278 116.375 6.41261 116.375 9.09601C116.375 11.7467 118.151 13.3829 120.914 13.3829H121.111C122.049 13.3829 123.101 13.1865 124.072 12.8757V9.78322H119.237V8.147H126.999V13.5302C125.388 14.3646 122.953 15.0191 120.98 15.0191H120.717C116.177 15.0191 113.25 12.6302 113.25 9.16146C113.25 5.7254 116.26 2.97656 120.881 2.97656H121.144C123.069 2.97656 125.272 3.58196 126.901 4.53097L126.045 5.92175C124.582 5.12 122.838 4.61278 121.276 4.61278H121.079Z"
        id="Vector_9"
      />
    </g>
  </svg>
);

export default RipplingLogo;
