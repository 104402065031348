import {
  Fragment, Suspense, SVGAttributes, useMemo
} from 'react';

import { twJoin, twMerge } from 'tailwind-merge';

import {
  Flag, FlagName, IconName
} from '../../icons';
import { IconKey, IconStyle } from '../../types';
import ICONS from './IconList';

export interface IconProps extends SVGAttributes<SVGElement> {
  name: IconKey;
  containerClassName?: string;
  iconStyle?: IconStyle;
}

const getContainerClassNames = ({ iconStyle }: Pick<IconProps, 'iconStyle'>) => {
  if (iconStyle === IconStyle.Default) {
    return 'bg-surface-l2 group-hover:bg-surface-l2-active rounded-md p-3 w-fit';
  }

  if (iconStyle === IconStyle.App) {
    return 'bg-transparent rounded-md w-full max-w-icon-lg aspect-square bg-plum-800 flex items-center justify-center';
  }
};

const getIconClassNames = ({ iconStyle }: Pick<IconProps, 'iconStyle'>) => {
  const className = 'object-cover overflow-hidden box-content aspect-square fill-text-primary';

  if (iconStyle === IconStyle.Default) {
    return twJoin(className, 'lg:h-6 lg:w-6 h-5 w-5');
  }

  return twJoin(className, 'lg:h-12 lg:w-12 h-10 w-10');
};

const IconFallback = () => {
  return <div className="w-4 h-4 bg-white/5 animate-pulse rounded" />;
};

const RenderIcon = ({
  className, name, ...props
}: IconProps) => {
  const IconComponent = useMemo(() => ICONS[name as IconName] ?? null, [name]);

  if (!IconComponent) {
    return null;
  }

  return (
    <IconComponent
      className={className}
      role="presentation"
      {...props}
    />
  );
};

const Icon = ({
  className, containerClassName, iconStyle, name, ...props
}: IconProps) => {
  const Wrapper = iconStyle ? 'div' : Fragment;
  const wrapperProps = iconStyle ?
    { className: twMerge(getContainerClassNames({ iconStyle }), containerClassName) } : {};

  if (name in FlagName) {
    return (
      <Wrapper {...wrapperProps}>
        <Flag className={className} flagName={name as FlagName} />
      </Wrapper>
    );
  }

  const iconClassNames = twMerge(iconStyle && getIconClassNames({ iconStyle }), className);

  return (
    <Wrapper {...wrapperProps}>
      <Suspense fallback={<IconFallback />}>
        <RenderIcon
          className={iconClassNames}
          name={name}
          {...props}
        />
      </Suspense>
    </Wrapper>
  );
};

export default Icon;
