import {
  FC, lazy, LazyExoticComponent, SVGProps
} from 'react';

import { IconName } from '../../icons';

const UShieldFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UShieldFilledIcon })));
const FilledIcon11 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FilledIcon11 })));
const OutlineIcon11 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OutlineIcon11 })));
const APIFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.APIFilledIcon })));
const APIOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.APIOutlineIcon })));
const AccessibilityFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AccessibilityFilledIcon })));
const AccessibilityOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AccessibilityOutlineIcon })));
const AccountantFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AccountantFilledIcon })));
const AccountantOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AccountantOutlineIcon })));
const AddCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddCircleFilledIcon })));
const AddCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddCircleOutlineIcon })));
const AddCommentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddCommentFilledIcon })));
const AddCommentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddCommentOutlineIcon })));
const AddNotificationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddNotificationFilledIcon })));
const AddNotificationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddNotificationOutlineIcon })));
const AddIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AddIcon })));
const AdditionalInfoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AdditionalInfoFilledIcon })));
const AdditionalInfoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AdditionalInfoOutlineIcon })));
const AirlineStopsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AirlineStopsFilledIcon })));
const AirlineStopsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AirlineStopsOutlineIcon })));
const AirlinesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AirlinesFilledIcon })));
const AirlinesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AirlinesOutlineIcon })));
const AlignCenterIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AlignCenterIcon })));
const AlignJustifyIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AlignJustifyIcon })));
const AlignLeftIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AlignLeftIcon })));
const AlignRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AlignRightIcon })));
const AppHeaderIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AppHeaderIcon })));
const AppManagementFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AppManagementFilledIcon })));
const AppManagementOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AppManagementOutlineIcon })));
const AppShopFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AppShopFilledIcon })));
const AppShopOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AppShopOutlineIcon })));
const ApprovalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ApprovalFilledIcon })));
const ApprovalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ApprovalOutlineIcon })));
const ApproveRejectPolicyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ApproveRejectPolicyFilledIcon })));
const ApproveRejectPolicyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ApproveRejectPolicyOutlineIcon })));
const ArchiveFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArchiveFilledIcon })));
const ArchiveOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArchiveOutlineIcon })));
const ArrowDownRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDownRightIcon })));
const ArrowDownIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDownIcon })));
const ArrowDropdownDownIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDropdownDownIcon })));
const ArrowDropdownLeftIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDropdownLeftIcon })));
const ArrowDropdownRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDropdownRightIcon })));
const ArrowDropdownUpIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowDropdownUpIcon })));
const ArrowLeftIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowLeftIcon })));
const ArrowRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowRightIcon })));
const ArrowUpIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowUpIcon })));
const ArrowupRightBoxIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowupRightBoxIcon })));
const ArrowupRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowupRightIcon })));
const AttachIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AttachIcon })));
const AuditObservationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AuditObservationFilledIcon })));
const AuditObservationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AuditObservationOutlineIcon })));
const AuthenticatorFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AuthenticatorFilledIcon })));
const AuthenticatorOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AuthenticatorOutlineIcon })));
const AutoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AutoFilledIcon })));
const AutoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AutoOutlineIcon })));
const AutomatedComplianceFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AutomatedComplianceFilledIcon })));
const AutomatedComplianceOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.AutomatedComplianceOutlineIcon })));
const BagsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BagsFilledIcon })));
const BagsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BagsOutlineIcon })));
const BandagedfootFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BandagedfootFilledIcon })));
const BandagedfootOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BandagedfootOutlineIcon })));
const BankFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BankFilledIcon })));
const BankOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BankOutlineIcon })));
const BarchartoutlineFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BarchartoutlineFilledIcon })));
const BarchartoutlineOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BarchartoutlineOutlineIcon })));
const BentoBoxIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BentoBoxIcon })));
const BlogsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BlogsFilledIcon })));
const BlogsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BlogsOutlineIcon })));
const BookmarkFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BookmarkFilledIcon })));
const BookmarkOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BookmarkOutlineIcon })));
const BooksFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BooksFilledIcon })));
const BooksOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BooksOutlineIcon })));
const BrazilianRealIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BrazilianRealIcon })));
const BriefcaseFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BriefcaseFilledIcon })));
const BriefcaseOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BriefcaseOutlineIcon })));
const BudgetDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetDollarFilledIcon })));
const BudgetDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetDollarOutlineIcon })));
const BudgetEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetEuroFilledIcon })));
const BudgetEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetEuroOutlineIcon })));
const BudgetPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetPoundFilledIcon })));
const BudgetPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetPoundOutlineIcon })));
const BudgetRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetRupeeFilledIcon })));
const BudgetRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetRupeeOutlineIcon })));
const BudgetYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetYenFilledIcon })));
const BudgetYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BudgetYenOutlineIcon })));
const ButtonIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ButtonIcon })));
const CabinClassUpgradeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CabinClassUpgradeIcon })));
const CabinClassIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CabinClassIcon })));
const CalendarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CalendarFilledIcon })));
const CalendarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CalendarOutlineIcon })));
const CameraFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CameraFilledIcon })));
const CameraOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CameraOutlineIcon })));
const CancelFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CancelFilledIcon })));
const CancelOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CancelOutlineIcon })));
const CaretDownIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CaretDownIcon })));
const CaretLeftIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CaretLeftIcon })));
const CaretRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CaretRightIcon })));
const CaretUpIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CaretUpIcon })));
const CarryOnBagFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CarryOnBagFilledIcon })));
const CarryOnBagOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CarryOnBagOutlineIcon })));
const CertificateFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CertificateFilledIcon })));
const CertificateOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CertificateOutlineIcon })));
const ChangeCommuterBenefitsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeCommuterBenefitsFilledIcon })));
const ChangeCommuterBenefitsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeCommuterBenefitsOutlineIcon })));
const ChangeFsaDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaDollarFilledIcon })));
const ChangeFsaDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaDollarOutlineIcon })));
const ChangeFsaEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaEuroFilledIcon })));
const ChangeFsaEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaEuroOutlineIcon })));
const ChangeFsaPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaPoundFilledIcon })));
const ChangeFsaPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaPoundOutlineIcon })));
const ChangeFsaRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaRupeeFilledIcon })));
const ChangeFsaRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaRupeeOutlineIcon })));
const ChangeFsaYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaYenFilledIcon })));
const ChangeFsaYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFsaYenOutlineIcon })));
const ChangeInsuranceFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeInsuranceFilledIcon })));
const ChangeInsuranceOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeInsuranceOutlineIcon })));
const ChangeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeFilledIcon })));
const ChangeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeOutlineIcon })));
const ChangeTaxWithholdingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeTaxWithholdingFilledIcon })));
const ChangeTaxWithholdingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChangeTaxWithholdingOutlineIcon })));
const CharityFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CharityFilledIcon })));
const CharityOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CharityOutlineIcon })));
const CheckCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckCircleFilledIcon })));
const CheckCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckCircleOutlineIcon })));
const CheckIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckIcon })));
const CheckedbagFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckedbagFilledIcon })));
const CheckedbagOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckedbagOutlineIcon })));
const CheckroomIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CheckroomIcon })));
const ChevronDownIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChevronDownIcon })));
const ChevronLeftIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChevronLeftIcon })));
const ChevronRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChevronRightIcon })));
const ChevronUpIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ChevronUpIcon })));
const ClearFormattingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClearFormattingFilledIcon })));
const ClearFormattingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClearFormattingOutlineIcon })));
const ClearIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClearIcon })));
const ClientCommandFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClientCommandFilledIcon })));
const ClientCommandOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClientCommandOutlineIcon })));
const CloseCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CloseCircleFilledIcon })));
const CloseCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CloseCircleOutlineIcon })));
const CloseIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CloseIcon })));
const ClosedCaptionsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClosedCaptionsFilledIcon })));
const ClosedCaptionsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ClosedCaptionsOutlineIcon })));
const CoIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CoIcon })));
const CodeEmbedIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CodeEmbedIcon })));
const CogglobeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CogglobeFilledIcon })));
const CogglobeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CogglobeOutlineIcon })));
const CollapsePanelFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CollapsePanelFilledIcon })));
const CollapsePanelOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CollapsePanelOutlineIcon })));
const CollapseIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CollapseIcon })));
const ColumnBarChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ColumnBarChartFilledIcon })));
const ColumnBarChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ColumnBarChartOutlineIcon })));
const ColumnBarchartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ColumnBarchartFilledIcon })));
const ColumnBarchartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ColumnBarchartOutlineIcon })));
const ComboChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ComboChartFilledIcon })));
const ComboChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ComboChartOutlineIcon })));
const ComfortIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ComfortIcon })));
const CommentsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CommentsFilledIcon })));
const CommentsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CommentsOutlineIcon })));
const CompanySettingsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompanySettingsFilledIcon })));
const CompanySettingsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompanySettingsOutlineIcon })));
const CompassFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompassFilledIcon })));
const CompassOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompassOutlineIcon })));
const CompetitiveParentalLeaveFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompetitiveParentalLeaveFilledIcon })));
const CompetitiveParentalLeaveOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CompetitiveParentalLeaveOutlineIcon })));
const Compliance360Icon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Compliance360Icon })));
const ComposeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ComposeFilledIcon })));
const ComposeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ComposeOutlineIcon })));
const ConnectCloudFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConnectCloudFilledIcon })));
const ConnectCloudOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConnectCloudOutlineIcon })));
const ConnectDatabaseFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConnectDatabaseFilledIcon })));
const ConnectDatabaseOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConnectDatabaseOutlineIcon })));
const ConsultantFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConsultantFilledIcon })));
const ConsultantOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ConsultantOutlineIcon })));
const ContentLibraryFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ContentLibraryFilledIcon })));
const ContentLibraryOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ContentLibraryOutlineIcon })));
const ControlFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ControlFilledIcon })));
const ControlOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ControlOutlineIcon })));
const CopyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CopyFilledIcon })));
const CopyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CopyOutlineIcon })));
const CostaRicanColnIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CostaRicanColnIcon })));
const CoursesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CoursesFilledIcon })));
const CoursesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CoursesOutlineIcon })));
const CreateGroupShortcutFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreateGroupShortcutFilledIcon })));
const CreateGroupShortcutOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreateGroupShortcutOutlineIcon })));
const CreditCardSpendingLimitFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreditCardSpendingLimitFilledIcon })));
const CreditCardSpendingLimitOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreditCardSpendingLimitOutlineIcon })));
const CreditCardFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreditCardFilledIcon })));
const CreditCardOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CreditCardOutlineIcon })));
const CsvFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CsvFilledIcon })));
const CsvOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CsvOutlineIcon })));
const CupFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CupFilledIcon })));
const CupOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CupOutlineIcon })));
const CurrencyCoversionDollarIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrencyCoversionDollarIcon })));
const CurrencyCoversionEurIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrencyCoversionEurIcon })));
const CurrencyCoversionPoundIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrencyCoversionPoundIcon })));
const CurrencyCoversionRupeeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrencyCoversionRupeeIcon })));
const CurrencyCoversionYenIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrencyCoversionYenIcon })));
const CurrentStepFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrentStepFilledIcon })));
const CurrentStepOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CurrentStepOutlineIcon })));
const CustomFieldsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomFieldsFilledIcon })));
const CustomFieldsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomFieldsOutlineIcon })));
const CustomAppsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomAppsFilledIcon })));
const CustomAppsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomAppsOutlineIcon })));
const CustomerMaturityFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerMaturityFilledIcon })));
const CustomerMaturityOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerMaturityOutlineIcon })));
const CustomerSpotlightFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerSpotlightFilledIcon })));
const CustomerSpotlightOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerSpotlightOutlineIcon })));
const CustomerTestimonialsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerTestimonialsFilledIcon })));
const CustomerTestimonialsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomerTestimonialsOutlineIcon })));
const CustomizePolicyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomizePolicyFilledIcon })));
const CustomizePolicyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CustomizePolicyOutlineIcon })));
const CutIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CutIcon })));
const CzechKorunaIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.CzechKorunaIcon })));
const DStackedBarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DStackedBarFilledIcon })));
const DStackedBarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DStackedBarOutlineIcon })));
const DashboardIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DashboardIcon })));
const DataConnectorFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DataConnectorFilledIcon })));
const DataConnectorOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DataConnectorOutlineIcon })));
const DeliverytruckFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeliverytruckFilledIcon })));
const DeliverytruckOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeliverytruckOutlineIcon })));
const DepartmentsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DepartmentsFilledIcon })));
const DepartmentsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DepartmentsOutlineIcon })));
const DepartureFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DepartureFilledIcon })));
const DepartureOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DepartureOutlineIcon })));
const DeployFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeployFilledIcon })));
const DeployOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeployOutlineIcon })));
const DesktopFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DesktopFilledIcon })));
const DesktopOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DesktopOutlineIcon })));
const DeviceInventoryManagementFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeviceInventoryManagementFilledIcon })));
const DeviceInventoryManagementOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeviceInventoryManagementOutlineIcon })));
const DeviceInventoryFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeviceInventoryFilledIcon })));
const DeviceInventoryOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DeviceInventoryOutlineIcon })));
const DirectReportsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DirectReportsFilledIcon })));
const DirectReportsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DirectReportsOutlineIcon })));
const DissatisfiedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DissatisfiedFilledIcon })));
const DissatisfiedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DissatisfiedOutlineIcon })));
const DocumentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DocumentFilledIcon })));
const DocumentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DocumentOutlineIcon })));
const DogFriendlyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DogFriendlyFilledIcon })));
const DogFriendlyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DogFriendlyOutlineIcon })));
const DollarCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarCircleFilledIcon })));
const DollarCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarCircleOutlineIcon })));
const DollarIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarIcon })));
const DollarcirclearrowsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarcirclearrowsFilledIcon })));
const DollarcirclearrowsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarcirclearrowsOutlineIcon })));
const DollarcirclecogFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarcirclecogFilledIcon })));
const DollarcirclecogOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DollarcirclecogOutlineIcon })));
const DonutChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DonutChartFilledIcon })));
const DonutChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DonutChartOutlineIcon })));
const DownloadIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DownloadIcon })));
const DragIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DragIcon })));
const DropdownSortedDownIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownSortedDownIcon })));
const DropdownSortedUpIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownSortedUpIcon })));
const DropdownFolderFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownFolderFilledIcon })));
const DropdownFolderOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownFolderOutlineIcon })));
const DropdownFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownFilledIcon })));
const DropdownOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DropdownOutlineIcon })));
const DuplicateFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DuplicateFilledIcon })));
const DuplicateOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DuplicateOutlineIcon })));
const EditFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EditFilledIcon })));
const EditOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EditOutlineIcon })));
const EmailFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EmailFilledIcon })));
const EmailOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EmailOutlineIcon })));
const EmploymentInfoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EmploymentInfoFilledIcon })));
const EmploymentInfoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EmploymentInfoOutlineIcon })));
const EngineeringFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EngineeringFilledIcon })));
const EngineeringOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EngineeringOutlineIcon })));
const EnterFullScreenIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EnterFullScreenIcon })));
const EntertainmentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EntertainmentFilledIcon })));
const EntertainmentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EntertainmentOutlineIcon })));
const EqualToIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EqualToIcon })));
const EquityDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityDollarFilledIcon })));
const EquityDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityDollarOutlineIcon })));
const EquityEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityEuroFilledIcon })));
const EquityEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityEuroOutlineIcon })));
const EquityPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityPoundFilledIcon })));
const EquityPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityPoundOutlineIcon })));
const EquityRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityRupeeFilledIcon })));
const EquityRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityRupeeOutlineIcon })));
const EquityYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityYenFilledIcon })));
const EquityYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EquityYenOutlineIcon })));
const EuroCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EuroCircleFilledIcon })));
const EuroCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EuroCircleOutlineIcon })));
const EuroIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EuroIcon })));
const EventsAndWebinarsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EventsAndWebinarsFilledIcon })));
const EventsAndWebinarsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.EventsAndWebinarsOutlineIcon })));
const ExitFullScreenIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExitFullScreenIcon })));
const ExpandPanelFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpandPanelFilledIcon })));
const ExpandPanelOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpandPanelOutlineIcon })));
const ExpandIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpandIcon })));
const ExpensePolicyDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyDollarFilledIcon })));
const ExpensePolicyDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyDollarOutlineIcon })));
const ExpensePolicyEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyEuroFilledIcon })));
const ExpensePolicyEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyEuroOutlineIcon })));
const ExpensePolicyPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyPoundFilledIcon })));
const ExpensePolicyPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyPoundOutlineIcon })));
const ExpensePolicyRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyRupeeFilledIcon })));
const ExpensePolicyRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyRupeeOutlineIcon })));
const ExpensePolicyYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyYenFilledIcon })));
const ExpensePolicyYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ExpensePolicyYenOutlineIcon })));
const FeatherFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FeatherFilledIcon })));
const FeatherOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FeatherOutlineIcon })));
const FeedbackFormFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FeedbackFormFilledIcon })));
const FeedbackFormOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FeedbackFormOutlineIcon })));
const FidoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FidoFilledIcon })));
const FidoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FidoOutlineIcon })));
const FileFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FileFilledIcon })));
const FileOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FileOutlineIcon })));
const FileshieldplusFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FileshieldplusFilledIcon })));
const FileshieldplusOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FileshieldplusOutlineIcon })));
const FilterByTypeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FilterByTypeFilledIcon })));
const FilterByTypeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FilterByTypeOutlineIcon })));
const FilterIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FilterIcon })));
const FinancesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FinancesFilledIcon })));
const FinancesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FinancesOutlineIcon })));
const FingerprintIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FingerprintIcon })));
const FitnessFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FitnessFilledIcon })));
const FitnessOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FitnessOutlineIcon })));
const FlagFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlagFilledIcon })));
const FlagOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlagOutlineIcon })));
const FlexibleWorkScheduleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlexibleWorkScheduleFilledIcon })));
const FlexibleWorkScheduleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlexibleWorkScheduleOutlineIcon })));
const FlightsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlightsFilledIcon })));
const FlightsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FlightsOutlineIcon })));
const FolderFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FolderFilledIcon })));
const FolderOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FolderOutlineIcon })));
const FontColorFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FontColorFilledIcon })));
const FontColorOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FontColorOutlineIcon })));
const FontSizeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FontSizeIcon })));
const FontHighlightFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FontHighlightFilledIcon })));
const FontHighlightOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FontHighlightOutlineIcon })));
const Forward10SecondsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Forward10SecondsIcon })));
const FsaFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FsaFilledIcon })));
const FsaOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FsaOutlineIcon })));
const FunctionIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FunctionIcon })));
const FunnelChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FunnelChartFilledIcon })));
const FunnelChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FunnelChartOutlineIcon })));
const FutureStepFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FutureStepFilledIcon })));
const FutureStepOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FutureStepOutlineIcon })));
const FxFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FxFilledIcon })));
const FxOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.FxOutlineIcon })));
const GPTFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GPTFilledIcon })));
const GPTOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GPTOutlineIcon })));
const GaugeChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GaugeChartFilledIcon })));
const GaugeChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GaugeChartOutlineIcon })));
const GeneralLedgerDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerDollarFilledIcon })));
const GeneralLedgerDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerDollarOutlineIcon })));
const GeneralLedgerEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerEuroFilledIcon })));
const GeneralLedgerEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerEuroOutlineIcon })));
const GeneralLedgerPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerPoundFilledIcon })));
const GeneralLedgerPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerPoundOutlineIcon })));
const GeneralLedgerRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerRupeeFilledIcon })));
const GeneralLedgerRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerRupeeOutlineIcon })));
const GeneralLedgerYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerYenFilledIcon })));
const GeneralLedgerYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GeneralLedgerYenOutlineIcon })));
const GifFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GifFilledIcon })));
const GifOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GifOutlineIcon })));
const GiftFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GiftFilledIcon })));
const GiftOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GiftOutlineIcon })));
const GlobeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GlobeFilledIcon })));
const GlobeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GlobeOutlineIcon })));
const GoalsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GoalsFilledIcon })));
const GoalsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GoalsOutlineIcon })));
const GraphchartIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GraphchartIcon })));
const GreaterOrEqualToIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GreaterOrEqualToIcon })));
const GreaterThanIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GreaterThanIcon })));
const GroceriesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GroceriesFilledIcon })));
const GroceriesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GroceriesOutlineIcon })));
const GuidepostFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GuidepostFilledIcon })));
const GuidepostOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.GuidepostOutlineIcon })));
const HamburgerIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HamburgerIcon })));
const HanddollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HanddollarFilledIcon })));
const HanddollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HanddollarOutlineIcon })));
const HandplusFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HandplusFilledIcon })));
const HandplusOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HandplusOutlineIcon })));
const HandshakeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HandshakeFilledIcon })));
const HandshakeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HandshakeOutlineIcon })));
const HardwareSecurityKeyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HardwareSecurityKeyFilledIcon })));
const HardwareSecurityKeyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HardwareSecurityKeyOutlineIcon })));
const HeadphonesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HeadphonesFilledIcon })));
const HeadphonesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HeadphonesOutlineIcon })));
const HealingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HealingFilledIcon })));
const HealingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HealingOutlineIcon })));
const HealthFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HealthFilledIcon })));
const HealthOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HealthOutlineIcon })));
const HeartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HeartFilledIcon })));
const HeartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HeartOutlineIcon })));
const HelpCenterFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HelpCenterFilledIcon })));
const HelpCenterOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HelpCenterOutlineIcon })));
const HelpFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HelpFilledIcon })));
const HelpOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HelpOutlineIcon })));
const HideFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HideFilledIcon })));
const HideOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HideOutlineIcon })));
const HighPriorityIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HighPriorityIcon })));
const HistoryIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HistoryIcon })));
const HomeExitIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HomeExitIcon })));
const HomeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HomeFilledIcon })));
const HomeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HomeOutlineIcon })));
const HotelFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HotelFilledIcon })));
const HotelOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HotelOutlineIcon })));
const HotelclassFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HotelclassFilledIcon })));
const HotelclassOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HotelclassOutlineIcon })));
const HrFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HrFilledIcon })));
const HrOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HrOutlineIcon })));
const HsaFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HsaFilledIcon })));
const HsaOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.HsaOutlineIcon })));
const I9FilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.I9FilledIcon })));
const I9OutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.I9OutlineIcon })));
const ImageAdjustOutlineBreakTextIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ImageAdjustOutlineBreakTextIcon })));
const ImageAdjustOutlineInlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ImageAdjustOutlineInlineIcon })));
const ImageAdjustOutlineWrapTextIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ImageAdjustOutlineWrapTextIcon })));
const ImageFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ImageFilledIcon })));
const ImageOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ImageOutlineIcon })));
const InPlanFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InPlanFilledIcon })));
const InPlanOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InPlanOutlineIcon })));
const InProgressStepFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InProgressStepFilledIcon })));
const InProgressStepOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InProgressStepOutlineIcon })));
const InPolicyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InPolicyFilledIcon })));
const InPolicyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InPolicyOutlineIcon })));
const IndentDecreaseIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IndentDecreaseIcon })));
const IndentIncreaseIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IndentIncreaseIcon })));
const InfoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InfoFilledIcon })));
const InfoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InfoOutlineIcon })));
const IntegratedAppsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IntegratedAppsFilledIcon })));
const IntegratedAppsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IntegratedAppsOutlineIcon })));
const InteractiveCourseFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InteractiveCourseFilledIcon })));
const InteractiveCourseOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InteractiveCourseOutlineIcon })));
const InvoiceDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceDollarFilledIcon })));
const InvoiceDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceDollarOutlineIcon })));
const InvoiceEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceEuroFilledIcon })));
const InvoiceEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceEuroOutlineIcon })));
const InvoicePoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoicePoundFilledIcon })));
const InvoicePoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoicePoundOutlineIcon })));
const InvoiceRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceRupeeFilledIcon })));
const InvoiceRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceRupeeOutlineIcon })));
const InvoiceYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceYenFilledIcon })));
const InvoiceYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.InvoiceYenOutlineIcon })));
const ItalicIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ItalicIcon })));
const KanbanIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.KanbanIcon })));
const KeyIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.KeyIcon })));
const KeyholeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.KeyholeFilledIcon })));
const KeyholeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.KeyholeOutlineIcon })));
const LabsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LabsIcon })));
const LaptopFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LaptopFilledIcon })));
const LaptopOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LaptopOutlineIcon })));
const LeaveManagementFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LeaveManagementFilledIcon })));
const LeaveManagementOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LeaveManagementOutlineIcon })));
const LegalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LegalFilledIcon })));
const LegalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LegalOutlineIcon })));
const LegroomFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LegroomFilledIcon })));
const LegroomOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LegroomOutlineIcon })));
const LessOrEqualToIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LessOrEqualToIcon })));
const LessThanIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LessThanIcon })));
const LifeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LifeFilledIcon })));
const LifeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LifeOutlineIcon })));
const LineHeightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LineHeightIcon })));
const LinechartIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LinechartIcon })));
const LinkOutletIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LinkOutletIcon })));
const LinkIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LinkIcon })));
const ListFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ListFilledIcon })));
const ListOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ListOutlineIcon })));
const LithuanianLitasIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LithuanianLitasIcon })));
const LmsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LmsFilledIcon })));
const LmsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LmsOutlineIcon })));
const LocationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LocationFilledIcon })));
const LocationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LocationOutlineIcon })));
const LockFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LockFilledIcon })));
const LockOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LockOutlineIcon })));
const LongAnswerIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.LongAnswerIcon })));
const MarkAsStolenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MarkAsStolenFilledIcon })));
const MarkAsStolenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MarkAsStolenOutlineIcon })));
const MarketingAndAdvertisingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MarketingAndAdvertisingFilledIcon })));
const MarketingAndAdvertisingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MarketingAndAdvertisingOutlineIcon })));
const MealsSnacksDrinksFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MealsSnacksDrinksFilledIcon })));
const MealsSnacksDrinksOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MealsSnacksDrinksOutlineIcon })));
const MealsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MealsFilledIcon })));
const MealsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MealsOutlineIcon })));
const MedicalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MedicalFilledIcon })));
const MedicalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MedicalOutlineIcon })));
const MeetingRoomsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MeetingRoomsFilledIcon })));
const MeetingRoomsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MeetingRoomsOutlineIcon })));
const MergeCellsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MergeCellsIcon })));
const MessageFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MessageFilledIcon })));
const MessageOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MessageOutlineIcon })));
const MetricChartIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MetricChartIcon })));
const MinimizeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MinimizeIcon })));
const MonitoringIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MonitoringIcon })));
const MoreHorizontalIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MoreHorizontalIcon })));
const MoreVerticalIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MoreVerticalIcon })));
const MultiSelectFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MultiSelectFilledIcon })));
const MultiSelectOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MultiSelectOutlineIcon })));
const MultiStopFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MultiStopFilledIcon })));
const MultiStopOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MultiStopOutlineIcon })));
const MuteFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MuteFilledIcon })));
const MuteOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MuteOutlineIcon })));
const MyAccountSettingsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MyAccountSettingsFilledIcon })));
const MyAccountSettingsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.MyAccountSettingsOutlineIcon })));
const NeutralFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NeutralFilledIcon })));
const NeutralOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NeutralOutlineIcon })));
const NextSpeakerIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NextSpeakerIcon })));
const NonprofitFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NonprofitFilledIcon })));
const NonprofitOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NonprofitOutlineIcon })));
const NotEqualToIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NotEqualToIcon })));
const NoticeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NoticeIcon })));
const NotificationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NotificationFilledIcon })));
const NotificationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NotificationOutlineIcon })));
const NumberedListIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.NumberedListIcon })));
const ObjectFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ObjectFilledIcon })));
const ObjectOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ObjectOutlineIcon })));
const OffboardFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OffboardFilledIcon })));
const OffboardOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OffboardOutlineIcon })));
const OfficeSuppliesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfficeSuppliesFilledIcon })));
const OfficeSuppliesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfficeSuppliesOutlineIcon })));
const OfficeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfficeFilledIcon })));
const OfficeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfficeOutlineIcon })));
const OfflineFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfflineFilledIcon })));
const OfflineOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OfflineOutlineIcon })));
const OnboardFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OnboardFilledIcon })));
const OnboardOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OnboardOutlineIcon })));
const OnlineFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OnlineFilledIcon })));
const OnlineOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OnlineOutlineIcon })));
const OpenApiIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OpenApiIcon })));
const OptionsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OptionsIcon })));
const OriginFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OriginFilledIcon })));
const OriginOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OriginOutlineIcon })));
const OutOfPlanFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OutOfPlanFilledIcon })));
const OutOfPlanOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OutOfPlanOutlineIcon })));
const OutOfPolicyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OutOfPolicyFilledIcon })));
const OutOfPolicyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OutOfPolicyOutlineIcon })));
const OvernightFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OvernightFilledIcon })));
const OvernightOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OvernightOutlineIcon })));
const OvertimePolicyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OvertimePolicyFilledIcon })));
const OvertimePolicyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.OvertimePolicyOutlineIcon })));
const PaidSeatSelectionFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaidSeatSelectionFilledIcon })));
const PaidSeatSelectionOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaidSeatSelectionOutlineIcon })));
const PasteFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PasteFilledIcon })));
const PasteOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PasteOutlineIcon })));
const PauseCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PauseCircleFilledIcon })));
const PauseCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PauseCircleOutlineIcon })));
const PauseIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PauseIcon })));
const PayScheduleDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleDollarFilledIcon })));
const PayScheduleDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleDollarOutlineIcon })));
const PayScheduleEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleEuroFilledIcon })));
const PayScheduleEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleEuroOutlineIcon })));
const PaySchedulePoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaySchedulePoundFilledIcon })));
const PaySchedulePoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaySchedulePoundOutlineIcon })));
const PayScheduleRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleRupeeFilledIcon })));
const PayScheduleRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleRupeeOutlineIcon })));
const PayScheduleYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleYenFilledIcon })));
const PayScheduleYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayScheduleYenOutlineIcon })));
const PayrollReportDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportDollarFilledIcon })));
const PayrollReportDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportDollarOutlineIcon })));
const PayrollReportEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportEuroFilledIcon })));
const PayrollReportEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportEuroOutlineIcon })));
const PayrollReportPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportPoundFilledIcon })));
const PayrollReportPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportPoundOutlineIcon })));
const PayrollReportRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportRupeeFilledIcon })));
const PayrollReportRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportRupeeOutlineIcon })));
const PayrollReportYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportYenFilledIcon })));
const PayrollReportYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollReportYenOutlineIcon })));
const PayrollTimeDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeDollarFilledIcon })));
const PayrollTimeDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeDollarOutlineIcon })));
const PayrollTimeEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeEuroFilledIcon })));
const PayrollTimeEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeEuroOutlineIcon })));
const PayrollTimePoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimePoundFilledIcon })));
const PayrollTimePoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimePoundOutlineIcon })));
const PayrollTimeRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeRupeeFilledIcon })));
const PayrollTimeRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeRupeeOutlineIcon })));
const PayrollTimeYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeYenFilledIcon })));
const PayrollTimeYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PayrollTimeYenOutlineIcon })));
const PaystubDollarIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaystubDollarIcon })));
const PaystubEuroIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaystubEuroIcon })));
const PaystubPoundIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaystubPoundIcon })));
const PaystubRupeeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaystubRupeeIcon })));
const PaystubYenIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PaystubYenIcon })));
const PdfFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PdfFilledIcon })));
const PdfOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PdfOutlineIcon })));
const PendingCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingCircleFilledIcon })));
const PendingCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingCircleOutlineIcon })));
const PendingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingFilledIcon })));
const PendingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingOutlineIcon })));
const PendingapprovalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingapprovalFilledIcon })));
const PendingapprovalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PendingapprovalOutlineIcon })));
const PeoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PeoFilledIcon })));
const PeoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PeoOutlineIcon })));
const PercentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PercentFilledIcon })));
const PercentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PercentOutlineIcon })));
const PhilippinePesoIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PhilippinePesoIcon })));
const PhoneFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PhoneFilledIcon })));
const PhoneOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PhoneOutlineIcon })));
const PhotoGalleryFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PhotoGalleryFilledIcon })));
const PhotoGalleryOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PhotoGalleryOutlineIcon })));
const PlayCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PlayCircleFilledIcon })));
const PlayCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PlayCircleOutlineIcon })));
const PlayButtonFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PlayButtonFilledIcon })));
const PlayButtonOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PlayButtonOutlineIcon })));
const PointInTimeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PointInTimeFilledIcon })));
const PointInTimeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PointInTimeOutlineIcon })));
const PointerTargetIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PointerTargetIcon })));
const PolishZlotyIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PolishZlotyIcon })));
const PoundCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PoundCircleFilledIcon })));
const PoundCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PoundCircleOutlineIcon })));
const PoundIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PoundIcon })));
const PowerButtonIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PowerButtonIcon })));
const PowerFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PowerFilledIcon })));
const PowerOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PowerOutlineIcon })));
const PriceCapIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PriceCapIcon })));
const ProvisionUsersFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProvisionUsersFilledIcon })));
const ProvisionUsersOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProvisionUsersOutlineIcon })));
const PublicTransportationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PublicTransportationFilledIcon })));
const PublicTransportationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PublicTransportationOutlineIcon })));
const PushFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PushFilledIcon })));
const PushOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.PushOutlineIcon })));
const QuestionCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.QuestionCircleFilledIcon })));
const QuestionCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.QuestionCircleOutlineIcon })));
const QuoteFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.QuoteFilledIcon })));
const QuoteOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.QuoteOutlineIcon })));
const ReadinessFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReadinessFilledIcon })));
const ReadinessOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReadinessOutlineIcon })));
const ReassignComputerFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReassignComputerFilledIcon })));
const ReassignComputerOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReassignComputerOutlineIcon })));
const ReceiptFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReceiptFilledIcon })));
const ReceiptOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReceiptOutlineIcon })));
const RecipesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RecipesFilledIcon })));
const RecipesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RecipesOutlineIcon })));
const ReclineExtraFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReclineExtraFilledIcon })));
const ReclineExtraOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReclineExtraOutlineIcon })));
const ReclineNormalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReclineNormalFilledIcon })));
const ReclineNormalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReclineNormalOutlineIcon })));
const RecruitingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RecruitingFilledIcon })));
const RecruitingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RecruitingOutlineIcon })));
const RedoCrossedIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RedoCrossedIcon })));
const RedoIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RedoIcon })));
const RefreshCrossedIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RefreshCrossedIcon })));
const RefreshIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RefreshIcon })));
const RemediationFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RemediationFilledIcon })));
const RemediationOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RemediationOutlineIcon })));
const RemoveFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RemoveFilledIcon })));
const RemoveOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RemoveOutlineIcon })));
const ReportMonitoringFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReportMonitoringFilledIcon })));
const ReportMonitoringOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReportMonitoringOutlineIcon })));
const ReportIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReportIcon })));
const ReportsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReportsFilledIcon })));
const ReportsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReportsOutlineIcon })));
const RetailDollarFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailDollarFilledIcon })));
const RetailDollarOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailDollarOutlineIcon })));
const RetailEuroFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailEuroFilledIcon })));
const RetailEuroOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailEuroOutlineIcon })));
const RetailPoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailPoundFilledIcon })));
const RetailPoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailPoundOutlineIcon })));
const RetailRupeeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailRupeeFilledIcon })));
const RetailRupeeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailRupeeOutlineIcon })));
const RetailYenFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailYenFilledIcon })));
const RetailYenOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RetailYenOutlineIcon })));
const ReturnIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReturnIcon })));
const ReviewCyclesIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReviewCyclesIcon })));
const Rewind10SecondsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Rewind10SecondsIcon })));
const RichtextIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RichtextIcon })));
const RideshareFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RideshareFilledIcon })));
const RideshareOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RideshareOutlineIcon })));
const RiskFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RiskFilledIcon })));
const RiskOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RiskOutlineIcon })));
const RoiFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RoiFilledIcon })));
const RoiOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RoiOutlineIcon })));
const RpassFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RpassFilledIcon })));
const RpassOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RpassOutlineIcon })));
const RupeeCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RupeeCircleFilledIcon })));
const RupeeCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RupeeCircleOutlineIcon })));
const RupeeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.RupeeIcon })));
const SalesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SalesFilledIcon })));
const SalesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SalesOutlineIcon })));
const SandboxFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SandboxFilledIcon })));
const SandboxOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SandboxOutlineIcon })));
const SatisfiedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SatisfiedFilledIcon })));
const SatisfiedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SatisfiedOutlineIcon })));
const SaveAndExitFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SaveAndExitFilledIcon })));
const SaveAndExitOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SaveAndExitOutlineIcon })));
const ScaleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ScaleFilledIcon })));
const ScaleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ScaleOutlineIcon })));
const ScatterChartIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ScatterChartIcon })));
const SearchIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SearchIcon })));
const ServerFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ServerFilledIcon })));
const ServerOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ServerOutlineIcon })));
const SettingsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SettingsFilledIcon })));
const SettingsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SettingsOutlineIcon })));
const ShareFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShareFilledIcon })));
const ShareOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShareOutlineIcon })));
const SharedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SharedFilledIcon })));
const SharedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SharedOutlineIcon })));
const ShieldDisabledFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShieldDisabledFilledIcon })));
const ShieldDisabledOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShieldDisabledOutlineIcon })));
const ShieldFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShieldFilledIcon })));
const ShieldOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShieldOutlineIcon })));
const ShortAnswerIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShortAnswerIcon })));
const ShowFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShowFilledIcon })));
const ShowOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ShowOutlineIcon })));
const SignatureFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SignatureFilledIcon })));
const SignatureOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SignatureOutlineIcon })));
const SkipLogicFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SkipLogicFilledIcon })));
const SkipLogicOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SkipLogicOutlineIcon })));
const SkipIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SkipIcon })));
const SortAscendingIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SortAscendingIcon })));
const SortDescendingIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SortDescendingIcon })));
const SortIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SortIcon })));
const SoundFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SoundFilledIcon })));
const SoundOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SoundOutlineIcon })));
const SpellCheckIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SpellCheckIcon })));
const StackedVChartFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StackedVChartFilledIcon })));
const StackedVChartOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StackedVChartOutlineIcon })));
const StackedcoinsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StackedcoinsFilledIcon })));
const StackedcoinsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StackedcoinsOutlineIcon })));
const StarCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StarCircleFilledIcon })));
const StarCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StarCircleOutlineIcon })));
const StarEmptyIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StarEmptyIcon })));
const StarFillIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StarFillIcon })));
const StarHalfIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StarHalfIcon })));
const StartupsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StartupsFilledIcon })));
const StartupsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StartupsOutlineIcon })));
const Stretch1Icon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Stretch1Icon })));
const Stretch2Icon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Stretch2Icon })));
const StrikethroughIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StrikethroughIcon })));
const StrongNoIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StrongNoIcon })));
const StrongYesIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StrongYesIcon })));
const SumFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SumFilledIcon })));
const SumOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SumOutlineIcon })));
const SunFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SunFilledIcon })));
const SunOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SunOutlineIcon })));
const SwapIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SwapIcon })));
const SwissFrancIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.SwissFrancIcon })));
const TableColumnFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TableColumnFilledIcon })));
const TableColumnOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TableColumnOutlineIcon })));
const TableIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TableIcon })));
const TalentSignalFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TalentSignalFilledIcon })));
const TalentSignalOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TalentSignalOutlineIcon })));
const TalentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TalentFilledIcon })));
const TalentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TalentOutlineIcon })));
const TaskFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaskFilledIcon })));
const TaskOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaskOutlineIcon })));
const TasksFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TasksFilledIcon })));
const TasksOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TasksOutlineIcon })));
const TaxWithholdingFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaxWithholdingFilledIcon })));
const TaxWithholdingOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaxWithholdingOutlineIcon })));
const TaxesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaxesFilledIcon })));
const TaxesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TaxesOutlineIcon })));
const TechnologyFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TechnologyFilledIcon })));
const TechnologyOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TechnologyOutlineIcon })));
const ThaiBahtIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThaiBahtIcon })));
const ThirdPartyDataIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThirdPartyDataIcon })));
const ThumbsDownFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbsDownFilledIcon })));
const ThumbsDownOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbsDownOutlineIcon })));
const ThumbsUpFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbsUpFilledIcon })));
const ThumbsUpOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbsUpOutlineIcon })));
const ThumbtackFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbtackFilledIcon })));
const ThumbtackOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThumbtackOutlineIcon })));
const ThunderboltFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThunderboltFilledIcon })));
const ThunderboltOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ThunderboltOutlineIcon })));
const TimeFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TimeFilledIcon })));
const TimeOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TimeOutlineIcon })));
const ToiletFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ToiletFilledIcon })));
const ToiletOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ToiletOutlineIcon })));
const ToolsFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ToolsFilledIcon })));
const ToolsOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ToolsOutlineIcon })));
const TranslateIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TranslateIcon })));
const TrashFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TrashFilledIcon })));
const TrashOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TrashOutlineIcon })));
const TravelFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TravelFilledIcon })));
const TravelOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TravelOutlineIcon })));
const TrendReportFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TrendReportFilledIcon })));
const TrendReportOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TrendReportOutlineIcon })));
const TripIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TripIcon })));
const TwoFactorDevicesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TwoFactorDevicesFilledIcon })));
const TwoFactorDevicesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TwoFactorDevicesOutlineIcon })));
const UShieldOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UShieldOutlineIcon })));
const UnarchiveFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnarchiveFilledIcon })));
const UnarchiveOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnarchiveOutlineIcon })));
const UnassignComputerFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnassignComputerFilledIcon })));
const UnassignComputerOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnassignComputerOutlineIcon })));
const UnderlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnderlineIcon })));
const UndoIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UndoIcon })));
const UnifiedSystemFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnifiedSystemFilledIcon })));
const UnifiedSystemOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnifiedSystemOutlineIcon })));
const UninstallFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UninstallFilledIcon })));
const UninstallOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UninstallOutlineIcon })));
const UniqueIdFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UniqueIdFilledIcon })));
const UniqueIdOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UniqueIdOutlineIcon })));
const UnlimitedPtoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnlimitedPtoFilledIcon })));
const UnlimitedPtoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnlimitedPtoOutlineIcon })));
const UnlockFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnlockFilledIcon })));
const UnlockOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UnlockOutlineIcon })));
const UpdateAddressIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UpdateAddressIcon })));
const UploadIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UploadIcon })));
const UserCogFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserCogFilledIcon })));
const UserCogOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserCogOutlineIcon })));
const UserDepartmentFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserDepartmentFilledIcon })));
const UserDepartmentOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserDepartmentOutlineIcon })));
const UserFileFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserFileFilledIcon })));
const UserFileOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserFileOutlineIcon })));
const UserLaptopFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserLaptopFilledIcon })));
const UserLaptopOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserLaptopOutlineIcon })));
const UserFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserFilledIcon })));
const UserOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserOutlineIcon })));
const UsercircleplusFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UsercircleplusFilledIcon })));
const UsercircleplusOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UsercircleplusOutlineIcon })));
const UsersFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UsersFilledIcon })));
const UsersOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UsersOutlineIcon })));
const UserticketFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserticketFilledIcon })));
const UserticketOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.UserticketOutlineIcon })));
const VariableIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VariableIcon })));
const VaultFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VaultFilledIcon })));
const VaultOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VaultOutlineIcon })));
const VendorFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VendorFilledIcon })));
const VendorOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VendorOutlineIcon })));
const VennDiagramAndFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VennDiagramAndFilledIcon })));
const VennDiagramAndOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VennDiagramAndOutlineIcon })));
const VennDiagramOrFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VennDiagramOrFilledIcon })));
const VennDiagramOrOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VennDiagramOrOutlineIcon })));
const VerifiedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VerifiedFilledIcon })));
const VerifiedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VerifiedOutlineIcon })));
const VeryDissatisfiedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VeryDissatisfiedFilledIcon })));
const VeryDissatisfiedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VeryDissatisfiedOutlineIcon })));
const VerySatisfiedFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VerySatisfiedFilledIcon })));
const VerySatisfiedOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VerySatisfiedOutlineIcon })));
const VideoFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VideoFilledIcon })));
const VideoOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VideoOutlineIcon })));
const VpnFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VpnFilledIcon })));
const VpnOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.VpnOutlineIcon })));
const WarningCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WarningCircleFilledIcon })));
const WarningCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WarningCircleOutlineIcon })));
const WarningTriangleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WarningTriangleFilledIcon })));
const WarningTriangleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WarningTriangleOutlineIcon })));
const WellnessAndMeditationClassesFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WellnessAndMeditationClassesFilledIcon })));
const WellnessAndMeditationClassesOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WellnessAndMeditationClassesOutlineIcon })));
const WifiCrossedIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WifiCrossedIcon })));
const WifiIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WifiIcon })));
const WineClubFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WineClubFilledIcon })));
const WineClubOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WineClubOutlineIcon })));
const WithdrawFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WithdrawFilledIcon })));
const WithdrawOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WithdrawOutlineIcon })));
const WorkFromHomeStipendFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WorkFromHomeStipendFilledIcon })));
const WorkFromHomeStipendOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WorkFromHomeStipendOutlineIcon })));
const WrenchFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WrenchFilledIcon })));
const WrenchOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WrenchOutlineIcon })));
const XmlFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.XmlFilledIcon })));
const XmlOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.XmlOutlineIcon })));
const YenCircleFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.YenCircleFilledIcon })));
const YenCircleOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.YenCircleOutlineIcon })));
const YenIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.YenIcon })));
const ZipFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZipFilledIcon })));
const ZipOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZipOutlineIcon })));
const ZoomInFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZoomInFilledIcon })));
const ZoomInOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZoomInOutlineIcon })));
const ZoomOutFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZoomOutFilledIcon })));
const ZoomOutOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ZoomOutOutlineIcon })));
const ArrowsdownLeftRightIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ArrowsdownLeftRightIcon })));
const BoldIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.BoldIcon })));
const DepartdateIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.DepartdateIcon })));
const IPhoneFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IPhoneFilledIcon })));
const IPhoneOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IPhoneOutlineIcon })));
const Illustration401kRetirementDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Illustration401kRetirementDark })));
const Illustration401kRetirementLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Illustration401kRetirementLight })));
const IllustrationAIDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAIDark })));
const IllustrationAIDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAIDark1 })));
const IllustrationAIDark2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAIDark2 })));
const IllustrationAILight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAILight })));
const IllustrationAddDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAddDark })));
const IllustrationAddLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAddLight })));
const IllustrationAutomateDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAutomateDark })));
const IllustrationAutomateLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationAutomateLight })));
const IllustrationBarChartDecreaseDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBarChartDecreaseDark })));
const IllustrationBarChartDecreaseLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBarChartDecreaseLight })));
const IllustrationBarChartIncreasedark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBarChartIncreasedark })));
const IllustrationBarChartIncreaselight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBarChartIncreaselight })));
const IllustrationBenefitsAdministrationDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBenefitsAdministrationDark })));
const IllustrationBenefitsAdministrationLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBenefitsAdministrationLight })));
const IllustrationBrainDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBrainDark })));
const IllustrationBrainLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationBrainLight })));
const IllustrationCalendarDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCalendarDark })));
const IllustrationCalendarLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCalendarLight })));
const IllustrationClockDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationClockDark })));
const IllustrationClockLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationClockLight })));
const IllustrationComplianceDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationComplianceDark })));
const IllustrationComplianceLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationComplianceLight })));
const IllustrationCoreDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCoreDark })));
const IllustrationCoreLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCoreLight })));
const IllustrationCreditCardDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCreditCardDark })));
const IllustrationCreditCardDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCreditCardDark1 })));
const IllustrationCreditCardDark2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCreditCardDark2 })));
const IllustrationCreditCardLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCreditCardLight })));
const IllustrationDeveloperDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDeveloperDark })));
const IllustrationDeveloperLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDeveloperLight })));
const IllustrationDeviceDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDeviceDark })));
const IllustrationDeviceLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDeviceLight })));
const IllustrationDocumentsDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDocumentsDark })));
const IllustrationDocumentsLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDocumentsLight })));
const IllustrationEnterpriseDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationEnterpriseDark })));
const IllustrationEnterpriseLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationEnterpriseLight })));
const IllustrationErrorDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationErrorDark })));
const IllustrationErrorLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationErrorLight })));
const IllustrationFolderDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationFolderDark })));
const IllustrationFolderLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationFolderLight })));
const IllustrationGearDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGearDark })));
const IllustrationGearLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGearLight })));
const IllustrationGlobeDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGlobeDark })));
const IllustrationGlobeLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGlobeLight })));
const IllustrationGlobeLight1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGlobeLight1 })));
const IllustrationGlobeLight2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationGlobeLight2 })));
const IllustrationHandshakeDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHandshakeDark })));
const IllustrationHandshakeDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHandshakeDark1 })));
const IllustrationHandshakeLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHandshakeLight })));
const IllustrationHeadcountPlanningDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHeadcountPlanningDark })));
const IllustrationHeadcountPlanningLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHeadcountPlanningLight })));
const IllustrationHealthInsuranceDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHealthInsuranceDark })));
const IllustrationHealthInsuranceDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHealthInsuranceDark1 })));
const IllustrationHealthInsuranceDark2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHealthInsuranceDark2 })));
const IllustrationHealthInsuranceLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationHealthInsuranceLight })));
const IllustrationIntegrationDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationIntegrationDark })));
const IllustrationIntegrationLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationIntegrationLight })));
const IllustrationInventoryManagementDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationInventoryManagementDark })));
const IllustrationInventoryManagementLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationInventoryManagementLight })));
const IllustrationLearningDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLearningDark })));
const IllustrationLearningLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLearningLight })));
const IllustrationLightningBoltDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLightningBoltDark })));
const IllustrationLightningBoltLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLightningBoltLight })));
const IllustrationLocalCurrencyDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLocalCurrencyDark })));
const IllustrationLocalCurrencyLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLocalCurrencyLight })));
const IllustrationLockDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLockDark })));
const IllustrationLockLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLockLight })));
const IllustrationMagnifyingGlasQuestionMarkDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMagnifyingGlasQuestionMarkDark })));
const IllustrationMagnifyingGlasQuestionMarkDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMagnifyingGlasQuestionMarkDark1 })));
const IllustrationMagnifyingGlasQuestionMarkLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMagnifyingGlasQuestionMarkLight })));
const IllustrationMagnifyingGlassDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMagnifyingGlassDark })));
const IllustrationMagnifyingGlassLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMagnifyingGlassLight })));
const IllustrationOffboardingEmployeeDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationOffboardingEmployeeDark })));
const IllustrationOffboardingEmployeeLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationOffboardingEmployeeLight })));
const IllustrationOnboardingEmployeeDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationOnboardingEmployeeDark })));
const IllustrationOnboardingEmployeeLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationOnboardingEmployeeLight })));
const IllustrationPayrollEuroDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollEuroDark })));
const IllustrationPayrollEuroLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollEuroLight })));
const IllustrationPayrollPoundDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollPoundDark })));
const IllustrationPayrollPoundLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollPoundLight })));
const IllustrationPayrollRupeeDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollRupeeDark })));
const IllustrationPayrollRupeeLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollRupeeLight })));
const IllustrationPayrollUSDDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollUSDDark })));
const IllustrationPayrollUSDLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollUSDLight })));
const IllustrationPayrollYenDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollYenDark })));
const IllustrationPayrollYenDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollYenDark1 })));
const IllustrationPayrollYenDark2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollYenDark2 })));
const IllustrationPayrollYenLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPayrollYenLight })));
const IllustrationProDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationProDark })));
const IllustrationProLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationProLight })));
const IllustrationQuestionMarkDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationQuestionMarkDark })));
const IllustrationQuestionMarkLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationQuestionMarkLight })));
const IllustrationReceiptDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationReceiptDark })));
const IllustrationReceiptLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationReceiptLight })));
const IllustrationRemoveDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationRemoveDark })));
const IllustrationRemoveLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationRemoveLight })));
const IllustrationRemoveLight1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationRemoveLight1 })));
const IllustrationRemoveLight2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationRemoveLight2 })));
const IllustrationSupportDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSupportDark })));
const IllustrationSupportLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSupportLight })));
const IllustrationSyncDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSyncDark })));
const IllustrationSyncLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSyncLight })));
const IllustrationThirdPartyAppsDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationThirdPartyAppsDark })));
const IllustrationThirdPartyAppsLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationThirdPartyAppsLight })));
const IllustrationUnifiedSystemDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnifiedSystemDark })));
const IllustrationUnifiedSystemLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnifiedSystemLight })));
const IllustrationUnlimitedDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnlimitedDark })));
const IllustrationUnlimitedLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnlimitedLight })));
const IllustrationUnlockDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnlockDark })));
const IllustrationUnlockLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationUnlockLight })));
const IllustrationApprovalDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationApprovalDark })));
const IllustrationApprovalLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationApprovalLight })));
const IllustrationApprovalLight1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationApprovalLight1 })));
const IllustrationApprovalLight2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationApprovalLight2 })));
const IllustrationCelebrationDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCelebrationDark })));
const IllustrationCelebrationLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCelebrationLight })));
const IllustrationCelebrationLight1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCelebrationLight1 })));
const IllustrationCelebrationLight2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCelebrationLight2 })));
const IllustrationCheckmarkDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCheckmarkDark })));
const IllustrationCheckmarkLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCheckmarkLight })));
const IllustrationCommunityDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCommunityDark })));
const IllustrationCommunityLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCommunityLight })));
const IllustrationCommuterBenefitsDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCommuterBenefitsDark })));
const IllustrationCommuterBenefitsLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationCommuterBenefitsLight })));
const IllustrationDentalDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDentalDark })));
const IllustrationDentalLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationDentalLight })));
const IllustrationEnterpriseBusinessDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationEnterpriseBusinessDark })));
const IllustrationEnterpriseBusinessLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationEnterpriseBusinessLight })));
const IllustrationExpenseManagementDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationExpenseManagementDark })));
const IllustrationExpenseManagementLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationExpenseManagementLight })));
const IllustrationIndividualDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationIndividualDark })));
const IllustrationIndividualLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationIndividualLight })));
const IllustrationLeaderDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLeaderDark })));
const IllustrationLeaderLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationLeaderLight })));
const IllustrationMegaphoneDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMegaphoneDark })));
const IllustrationMegaphoneLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMegaphoneLight })));
const IllustrationMegaphoneLight1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMegaphoneLight1 })));
const IllustrationMegaphoneLight2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMegaphoneLight2 })));
const IllustrationMidSizeBusinessDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMidSizeBusinessDark })));
const IllustrationMidSizeBusinessLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationMidSizeBusinessLight })));
const IllustrationPerformanceManagementDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPerformanceManagementDark })));
const IllustrationPerformanceManagementLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPerformanceManagementLight })));
const IllustrationPieChartDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPieChartDark })));
const IllustrationPieChartLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPieChartLight })));
const IllustrationPulseSurveyDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPulseSurveyDark })));
const IllustrationPulseSurveyDark1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPulseSurveyDark1 })));
const IllustrationPulseSurveyDark2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPulseSurveyDark2 })));
const IllustrationPulseSurveyLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationPulseSurveyLight })));
const IllustrationSmallBusinessDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSmallBusinessDark })));
const IllustrationSmallBusinessLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSmallBusinessLight })));
const IllustrationSmartPhoneDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSmartPhoneDark })));
const IllustrationSmartPhoneLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationSmartPhoneLight })));
const IllustrationTaxesDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationTaxesDark })));
const IllustrationTaxesLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationTaxesLight })));
const IllustrationThumbsUpdark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationThumbsUpdark })));
const IllustrationThumbsUplight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationThumbsUplight })));
const IllustrationTravelDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationTravelDark })));
const IllustrationTravelLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationTravelLight })));
const IllustrationVisionDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationVisionDark })));
const IllustrationVisionLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationVisionLight })));
const IllustrationWorkforcePlanningDark = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationWorkforcePlanningDark })));
const IllustrationWorkforcePlanningLight = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.IllustrationWorkforcePlanningLight })));
const Product11s = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Product11s })));
const Product401k = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.Product401k })));
const ProductADD = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductADD })));
const ProductAPI = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAPI })));
const ProductASO = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductASO })));
const ProductEAP = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEAP })));
const ProductEOR = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEOR })));
const ProductFluxInbox = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFluxInbox })));
const ProductRipplingBrowserExtension = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductRipplingBrowserExtension })));
const ProductAca = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAca })));
const ProductAccidentInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccidentInsurance })));
const ProductAccountAndSettings = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountAndSettings })));
const ProductAccountant = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountant })));
const ProductAccountingIntegrationsEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountingIntegrationsEuro })));
const ProductAccountingIntegrationsPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountingIntegrationsPound })));
const ProductAccountingIntegrationsRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountingIntegrationsRupee })));
const ProductAccountingIntegrationsUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountingIntegrationsUsd })));
const ProductAccountingIntegrationsYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAccountingIntegrationsYen })));
const ProductAddApp = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAddApp })));
const ProductAddictionSupport = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAddictionSupport })));
const ProductAdoptionServices = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAdoptionServices })));
const ProductAnniversaries = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAnniversaries })));
const ProductAntivirus = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAntivirus })));
const ProductAppManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAppManagement })));
const ProductAppShop = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAppShop })));
const ProductApprovals = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductApprovals })));
const ProductAuditLog = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAuditLog })));
const ProductAutism = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAutism })));
const ProductAutomatedCompliance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductAutomatedCompliance })));
const ProductBenefitsAdmin = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBenefitsAdmin })));
const ProductBillPayEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillPayEuro })));
const ProductBillPayPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillPayPound })));
const ProductBillPayRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillPayRupee })));
const ProductBillPayUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillPayUsd })));
const ProductBillPayYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillPayYen })));
const ProductBillingEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillingEuro })));
const ProductBillingPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillingPound })));
const ProductBillingRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillingRupee })));
const ProductBillingUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillingUsd })));
const ProductBillingYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductBillingYen })));
const ProductCancer = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCancer })));
const ProductCareManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCareManagement })));
const ProductCareNavigation = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCareNavigation })));
const ProductCelebrations = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCelebrations })));
const ProductClientCommandCenter = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductClientCommandCenter })));
const ProductCobra = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCobra })));
const ProductCommuterBenefits = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCommuterBenefits })));
const ProductCompanyAdmins = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCompanyAdmins })));
const ProductCompanyDetails = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCompanyDetails })));
const ProductCompanySettings = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCompanySettings })));
const ProductCompensationBands = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCompensationBands })));
const ProductCompliance360 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCompliance360 })));
const ProductContractors = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductContractors })));
const ProductCovid19 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCovid19 })));
const ProductCriticalIllnessInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCriticalIllnessInsurance })));
const ProductCustomAlerts = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomAlerts })));
const ProductCustomApps = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomApps })));
const ProductCustomFields = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomFields })));
const ProductCustomFluxCustomFluxOption1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomFluxCustomFluxOption1 })));
const ProductCustomFluxCustomFluxOption2 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomFluxCustomFluxOption2 })));
const ProductCustomFluxCustomFluxOption3 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomFluxCustomFluxOption3 })));
const ProductCustomIntegrations = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductCustomIntegrations })));
const ProductDataManager1 = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductDataManager1 })));
const ProductDataManager = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductDataManager })));
const ProductDental = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductDental })));
const ProductDeviceManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductDeviceManagement })));
const ProductDocuments = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductDocuments })));
const ProductEeoReporting = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEeoReporting })));
const ProductEmployeeHome = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEmployeeHome })));
const ProductEmploymentAuthorization = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEmploymentAuthorization })));
const ProductEmploymentVerification = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEmploymentVerification })));
const ProductEstatePlanning = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductEstatePlanning })));
const ProductExpenseManagementEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductExpenseManagementEuro })));
const ProductExpenseManagementPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductExpenseManagementPound })));
const ProductExpenseManagementRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductExpenseManagementRupee })));
const ProductExpenseManagementUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductExpenseManagementUsd })));
const ProductExpenseManagementYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductExpenseManagementYen })));
const ProductFamilyCareServices = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFamilyCareServices })));
const ProductFeedback = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFeedback })));
const ProductFertility = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFertility })));
const ProductFinancialWellness = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFinancialWellness })));
const ProductFlexibleSpendingAccount = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductFlexibleSpendingAccount })));
const ProductForms = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductForms })));
const ProductGettingStarted = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductGettingStarted })));
const ProductGlobalPayroll = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductGlobalPayroll })));
const ProductGlobalSpend = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductGlobalSpend })));
const ProductGoals = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductGoals })));
const ProductGymStipend = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductGymStipend })));
const ProductHeadcountPlanning = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHeadcountPlanning })));
const ProductHealthSavingsAccount = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHealthSavingsAccount })));
const ProductHelp = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHelp })));
const ProductHome = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHome })));
const ProductHospitalIndemnity = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHospitalIndemnity })));
const ProductHrHelpDesk = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductHrHelpDesk })));
const ProductI9Verification = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductI9Verification })));
const ProductIdentityTheft = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIdentityTheft })));
const ProductIncomeVerificationEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIncomeVerificationEuro })));
const ProductIncomeVerificationPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIncomeVerificationPound })));
const ProductIncomeVerificationRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIncomeVerificationRupee })));
const ProductIncomeVerificationUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIncomeVerificationUsd })));
const ProductIncomeVerificationYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIncomeVerificationYen })));
const ProductInstantSso = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductInstantSso })));
const ProductInsuranceBroker = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductInsuranceBroker })));
const ProductInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductInsurance })));
const ProductIntegrations = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductIntegrations })));
const ProductInventoryManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductInventoryManagement })));
const ProductItHub = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductItHub })));
const ProductJobCodes = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductJobCodes })));
const ProductLearningManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLearningManagement })));
const ProductLeaveManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLeaveManagement })));
const ProductLegalAid = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLegalAid })));
const ProductLifeInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLifeInsurance })));
const ProductLogInAs = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLogInAs })));
const ProductLongTermCareInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLongTermCareInsurance })));
const ProductLongTermDisability = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductLongTermDisability })));
const ProductMaternity = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMaternity })));
const ProductMealVoucher = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMealVoucher })));
const ProductMedical = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMedical })));
const ProductMeditationMindfulness = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMeditationMindfulness })));
const ProductMentalHealth = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMentalHealth })));
const ProductMore = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMore })));
const ProductMyBenefits = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyBenefits })));
const ProductMyPayEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyPayEuro })));
const ProductMyPayPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyPayPound })));
const ProductMyPayRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyPayRupee })));
const ProductMyPayUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyPayUsd })));
const ProductMyPayYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductMyPayYen })));
const ProductNeedToTalk = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductNeedToTalk })));
const ProductNewsfeed = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductNewsfeed })));
const ProductNotifications = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductNotifications })));
const ProductNutritionCounseling = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductNutritionCounseling })));
const ProductOnboardAndOffboard = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductOnboardAndOffboard })));
const ProductOrgChart = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductOrgChart })));
const ProductOtherBenefits = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductOtherBenefits })));
const ProductPayrollEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPayrollEuro })));
const ProductPayrollPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPayrollPound })));
const ProductPayrollRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPayrollRupee })));
const ProductPayrollUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPayrollUsd })));
const ProductPayrollYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPayrollYen })));
const ProductPensionManagementEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPensionManagementEuro })));
const ProductPensionManagementPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPensionManagementPound })));
const ProductPensionManagementRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPensionManagementRupee })));
const ProductPensionManagementUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPensionManagementUsd })));
const ProductPensionManagementYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPensionManagementYen })));
const ProductPeoWorkersComp = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPeoWorkersComp })));
const ProductPeo = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPeo })));
const ProductPeopleDirectory = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPeopleDirectory })));
const ProductPermissions = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPermissions })));
const ProductPetInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPetInsurance })));
const ProductPrimaryCare = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPrimaryCare })));
const ProductPrivate = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPrivate })));
const ProductProcurement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductProcurement })));
const ProductProductWebinars = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductProductWebinars })));
const ProductPulse = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductPulse })));
const ProductRecipes = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductRecipes })));
const ProductRecruiting = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductRecruiting })));
const ProductReports = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductReports })));
const ProductReviewCycles = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductReviewCycles })));
const ProductRipplingLogo = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductRipplingLogo })));
const ProductRpass = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductRpass })));
const ProductSandbox = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSandbox })));
const ProductScheduling = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductScheduling })));
const ProductSecondaryLifeInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSecondaryLifeInsurance })));
const ProductSecurityTool = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSecurityTool })));
const ProductShortTermDisability = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductShortTermDisability })));
const ProductSpendManagement = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSpendManagement })));
const ProductSpoof = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSpoof })));
const ProductSshKeyManager = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSshKeyManager })));
const ProductStateTaxAccounts = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductStateTaxAccounts })));
const ProductStudentLoanRepayment = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductStudentLoanRepayment })));
const ProductSupergroupManager = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSupergroupManager })));
const ProductSwagStore = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductSwagStore })));
const ProductTalentSignal = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalentSignal })));
const ProductTalkToSalesEuro = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSalesEuro })));
const ProductTalkToSalesPound = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSalesPound })));
const ProductTalkToSalesRupee = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSalesRupee })));
const ProductTalkToSalesUsd = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSalesUsd })));
const ProductTalkToSalesYen = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSalesYen })));
const ProductTalkToSomeone = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSomeone })));
const ProductTalkToSupport = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTalkToSupport })));
const ProductTasks = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTasks })));
const ProductTelemedicine = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTelemedicine })));
const ProductThinkHr = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductThinkHr })));
const ProductThirdPartyBenefitsAdmin = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductThirdPartyBenefitsAdmin })));
const ProductThirdPartyFlex = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductThirdPartyFlex })));
const ProductTimeAndAttendanceKiosk = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTimeAndAttendanceKiosk })));
const ProductTimeAndAttendance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTimeAndAttendance })));
const ProductTimeOff = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTimeOff })));
const ProductTransformations = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTransformations })));
const ProductTravelInsurance = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTravelInsurance })));
const ProductTravel = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductTravel })));
const ProductUniversalSearch = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductUniversalSearch })));
const ProductVariableCompensation = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductVariableCompensation })));
const ProductVirtualLdap = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductVirtualLdap })));
const ProductVision = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductVision })));
const ProductWallet = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWallet })));
const ProductWorkersComp = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWorkersComp })));
const ProductWorkflowAutomator = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWorkflowAutomator })));
const ProductWorkplaceIssue = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWorkplaceIssue })));
const ProductWorkplacePosters = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWorkplacePosters })));
const ProductWorkshops = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ProductWorkshops })));
const QrcodeIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.QrcodeIcon })));
const ReturndateIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.ReturndateIcon })));
const StairsIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StairsIcon })));
const StopatairportFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StopatairportFilledIcon })));
const StopatairportOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.StopatairportOutlineIcon })));
const WindowpopoutoutlineFilledIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WindowpopoutoutlineFilledIcon })));
const WindowpopoutoutlineOutlineIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.WindowpopoutoutlineOutlineIcon })));
const TrophyIcon = lazy(() => import('../../icons/figmaSvg').then((module) => ({ default: module.TrophyIcon })));

const ICONS: Record<IconName, LazyExoticComponent<FC<SVGProps<SVGSVGElement>>>> = {
  AccessibilityFilledIcon: AccessibilityFilledIcon,
  AccessibilityOutlineIcon: AccessibilityOutlineIcon,
  AccountantFilledIcon: AccountantFilledIcon,
  AccountantOutlineIcon: AccountantOutlineIcon,
  AddCircleFilledIcon: AddCircleFilledIcon,
  AddCircleOutlineIcon: AddCircleOutlineIcon,
  AddCommentFilledIcon: AddCommentFilledIcon,
  AddCommentOutlineIcon: AddCommentOutlineIcon,
  AddIcon: AddIcon,
  AdditionalInfoFilledIcon: AdditionalInfoFilledIcon,
  AdditionalInfoOutlineIcon: AdditionalInfoOutlineIcon,
  AddNotificationFilledIcon: AddNotificationFilledIcon,
  AddNotificationOutlineIcon: AddNotificationOutlineIcon,
  AirlinesFilledIcon: AirlinesFilledIcon,
  AirlinesOutlineIcon: AirlinesOutlineIcon,
  AirlineStopsFilledIcon: AirlineStopsFilledIcon,
  AirlineStopsOutlineIcon: AirlineStopsOutlineIcon,
  AlignCenterIcon: AlignCenterIcon,
  AlignJustifyIcon: AlignJustifyIcon,
  AlignLeftIcon: AlignLeftIcon,
  AlignRightIcon: AlignRightIcon,
  APIFilledIcon: APIFilledIcon,
  APIOutlineIcon: APIOutlineIcon,
  AppHeaderIcon: AppHeaderIcon,
  AppManagementFilledIcon: AppManagementFilledIcon,
  AppManagementOutlineIcon: AppManagementOutlineIcon,
  ApprovalFilledIcon: ApprovalFilledIcon,
  ApprovalOutlineIcon: ApprovalOutlineIcon,
  ApproveRejectPolicyFilledIcon: ApproveRejectPolicyFilledIcon,
  ApproveRejectPolicyOutlineIcon: ApproveRejectPolicyOutlineIcon,
  AppShopFilledIcon: AppShopFilledIcon,
  AppShopOutlineIcon: AppShopOutlineIcon,
  ArchiveFilledIcon: ArchiveFilledIcon,
  ArchiveOutlineIcon: ArchiveOutlineIcon,
  ArrowDownIcon: ArrowDownIcon,
  ArrowDownRightIcon: ArrowDownRightIcon,
  ArrowDropdownDownIcon: ArrowDropdownDownIcon,
  ArrowDropdownLeftIcon: ArrowDropdownLeftIcon,
  ArrowDropdownRightIcon: ArrowDropdownRightIcon,
  ArrowDropdownUpIcon: ArrowDropdownUpIcon,
  ArrowLeftIcon: ArrowLeftIcon,
  ArrowRightIcon: ArrowRightIcon,
  ArrowsdownLeftRightIcon: ArrowsdownLeftRightIcon,
  ArrowUpIcon: ArrowUpIcon,
  ArrowupRightBoxIcon: ArrowupRightBoxIcon,
  ArrowupRightIcon: ArrowupRightIcon,
  AttachIcon: AttachIcon,
  AuditObservationFilledIcon: AuditObservationFilledIcon,
  AuditObservationOutlineIcon: AuditObservationOutlineIcon,
  AuthenticatorFilledIcon: AuthenticatorFilledIcon,
  AuthenticatorOutlineIcon: AuthenticatorOutlineIcon,
  AutoFilledIcon: AutoFilledIcon,
  AutomatedComplianceFilledIcon: AutomatedComplianceFilledIcon,
  AutomatedComplianceOutlineIcon: AutomatedComplianceOutlineIcon,
  AutoOutlineIcon: AutoOutlineIcon,
  BagsFilledIcon: BagsFilledIcon,
  BagsOutlineIcon: BagsOutlineIcon,
  BandagedfootFilledIcon: BandagedfootFilledIcon,
  BandagedfootOutlineIcon: BandagedfootOutlineIcon,
  BankFilledIcon: BankFilledIcon,
  BankOutlineIcon: BankOutlineIcon,
  BarchartoutlineFilledIcon: BarchartoutlineFilledIcon,
  BarchartoutlineOutlineIcon: BarchartoutlineOutlineIcon,
  BentoBoxIcon: BentoBoxIcon,
  BlogsFilledIcon: BlogsFilledIcon,
  BlogsOutlineIcon: BlogsOutlineIcon,
  BoldIcon: BoldIcon,
  BookmarkFilledIcon: BookmarkFilledIcon,
  BookmarkOutlineIcon: BookmarkOutlineIcon,
  BooksFilledIcon: BooksFilledIcon,
  BooksOutlineIcon: BooksOutlineIcon,
  BrazilianRealIcon: BrazilianRealIcon,
  BriefcaseFilledIcon: BriefcaseFilledIcon,
  BriefcaseOutlineIcon: BriefcaseOutlineIcon,
  BudgetDollarFilledIcon: BudgetDollarFilledIcon,
  BudgetDollarOutlineIcon: BudgetDollarOutlineIcon,
  BudgetEuroFilledIcon: BudgetEuroFilledIcon,
  BudgetEuroOutlineIcon: BudgetEuroOutlineIcon,
  BudgetPoundFilledIcon: BudgetPoundFilledIcon,
  BudgetPoundOutlineIcon: BudgetPoundOutlineIcon,
  BudgetRupeeFilledIcon: BudgetRupeeFilledIcon,
  BudgetRupeeOutlineIcon: BudgetRupeeOutlineIcon,
  BudgetYenFilledIcon: BudgetYenFilledIcon,
  BudgetYenOutlineIcon: BudgetYenOutlineIcon,
  ButtonIcon: ButtonIcon,
  CabinClassIcon: CabinClassIcon,
  CabinClassUpgradeIcon: CabinClassUpgradeIcon,
  CalendarFilledIcon: CalendarFilledIcon,
  CalendarOutlineIcon: CalendarOutlineIcon,
  CameraFilledIcon: CameraFilledIcon,
  CameraOutlineIcon: CameraOutlineIcon,
  CancelFilledIcon: CancelFilledIcon,
  CancelOutlineIcon: CancelOutlineIcon,
  CaretDownIcon: CaretDownIcon,
  CaretLeftIcon: CaretLeftIcon,
  CaretRightIcon: CaretRightIcon,
  CaretUpIcon: CaretUpIcon,
  CarryOnBagFilledIcon: CarryOnBagFilledIcon,
  CarryOnBagOutlineIcon: CarryOnBagOutlineIcon,
  CertificateFilledIcon: CertificateFilledIcon,
  CertificateOutlineIcon: CertificateOutlineIcon,
  ChangeCommuterBenefitsFilledIcon: ChangeCommuterBenefitsFilledIcon,
  ChangeCommuterBenefitsOutlineIcon: ChangeCommuterBenefitsOutlineIcon,
  ChangeFilledIcon: ChangeFilledIcon,
  ChangeFsaDollarFilledIcon: ChangeFsaDollarFilledIcon,
  ChangeFsaDollarOutlineIcon: ChangeFsaDollarOutlineIcon,
  ChangeFsaEuroFilledIcon: ChangeFsaEuroFilledIcon,
  ChangeFsaEuroOutlineIcon: ChangeFsaEuroOutlineIcon,
  ChangeFsaPoundFilledIcon: ChangeFsaPoundFilledIcon,
  ChangeFsaPoundOutlineIcon: ChangeFsaPoundOutlineIcon,
  ChangeFsaRupeeFilledIcon: ChangeFsaRupeeFilledIcon,
  ChangeFsaRupeeOutlineIcon: ChangeFsaRupeeOutlineIcon,
  ChangeFsaYenFilledIcon: ChangeFsaYenFilledIcon,
  ChangeFsaYenOutlineIcon: ChangeFsaYenOutlineIcon,
  ChangeInsuranceFilledIcon: ChangeInsuranceFilledIcon,
  ChangeInsuranceOutlineIcon: ChangeInsuranceOutlineIcon,
  ChangeOutlineIcon: ChangeOutlineIcon,
  ChangeTaxWithholdingFilledIcon: ChangeTaxWithholdingFilledIcon,
  ChangeTaxWithholdingOutlineIcon: ChangeTaxWithholdingOutlineIcon,
  CharityFilledIcon: CharityFilledIcon,
  CharityOutlineIcon: CharityOutlineIcon,
  CheckCircleFilledIcon: CheckCircleFilledIcon,
  CheckCircleOutlineIcon: CheckCircleOutlineIcon,
  CheckedbagFilledIcon: CheckedbagFilledIcon,
  CheckedbagOutlineIcon: CheckedbagOutlineIcon,
  CheckIcon: CheckIcon,
  CheckroomIcon: CheckroomIcon,
  ChevronDownIcon: ChevronDownIcon,
  ChevronLeftIcon: ChevronLeftIcon,
  ChevronRightIcon: ChevronRightIcon,
  ChevronUpIcon: ChevronUpIcon,
  ClearFormattingFilledIcon: ClearFormattingFilledIcon,
  ClearFormattingOutlineIcon: ClearFormattingOutlineIcon,
  ClearIcon: ClearIcon,
  ClientCommandFilledIcon: ClientCommandFilledIcon,
  ClientCommandOutlineIcon: ClientCommandOutlineIcon,
  CloseCircleFilledIcon: CloseCircleFilledIcon,
  CloseCircleOutlineIcon: CloseCircleOutlineIcon,
  ClosedCaptionsFilledIcon: ClosedCaptionsFilledIcon,
  ClosedCaptionsOutlineIcon: ClosedCaptionsOutlineIcon,
  CloseIcon: CloseIcon,
  CodeEmbedIcon: CodeEmbedIcon,
  CogglobeFilledIcon: CogglobeFilledIcon,
  CogglobeOutlineIcon: CogglobeOutlineIcon,
  CoIcon: CoIcon,
  CollapseIcon: CollapseIcon,
  CollapsePanelFilledIcon: CollapsePanelFilledIcon,
  CollapsePanelOutlineIcon: CollapsePanelOutlineIcon,
  ColumnBarChartFilledIcon: ColumnBarChartFilledIcon,
  ColumnBarchartFilledIcon: ColumnBarchartFilledIcon,
  ColumnBarChartOutlineIcon: ColumnBarChartOutlineIcon,
  ColumnBarchartOutlineIcon: ColumnBarchartOutlineIcon,
  ComboChartFilledIcon: ComboChartFilledIcon,
  ComboChartOutlineIcon: ComboChartOutlineIcon,
  ComfortIcon: ComfortIcon,
  CommentsFilledIcon: CommentsFilledIcon,
  CommentsOutlineIcon: CommentsOutlineIcon,
  CompanySettingsFilledIcon: CompanySettingsFilledIcon,
  CompanySettingsOutlineIcon: CompanySettingsOutlineIcon,
  CompassFilledIcon: CompassFilledIcon,
  CompassOutlineIcon: CompassOutlineIcon,
  CompetitiveParentalLeaveFilledIcon: CompetitiveParentalLeaveFilledIcon,
  CompetitiveParentalLeaveOutlineIcon: CompetitiveParentalLeaveOutlineIcon,
  Compliance360Icon: Compliance360Icon,
  ComposeFilledIcon: ComposeFilledIcon,
  ComposeOutlineIcon: ComposeOutlineIcon,
  ConnectCloudFilledIcon: ConnectCloudFilledIcon,
  ConnectCloudOutlineIcon: ConnectCloudOutlineIcon,
  ConnectDatabaseFilledIcon: ConnectDatabaseFilledIcon,
  ConnectDatabaseOutlineIcon: ConnectDatabaseOutlineIcon,
  ConsultantFilledIcon: ConsultantFilledIcon,
  ConsultantOutlineIcon: ConsultantOutlineIcon,
  ContentLibraryFilledIcon: ContentLibraryFilledIcon,
  ContentLibraryOutlineIcon: ContentLibraryOutlineIcon,
  ControlFilledIcon: ControlFilledIcon,
  ControlOutlineIcon: ControlOutlineIcon,
  CopyFilledIcon: CopyFilledIcon,
  CopyOutlineIcon: CopyOutlineIcon,
  CostaRicanColnIcon: CostaRicanColnIcon,
  CoursesFilledIcon: CoursesFilledIcon,
  CoursesOutlineIcon: CoursesOutlineIcon,
  CreateGroupShortcutFilledIcon: CreateGroupShortcutFilledIcon,
  CreateGroupShortcutOutlineIcon: CreateGroupShortcutOutlineIcon,
  CreditCardFilledIcon: CreditCardFilledIcon,
  CreditCardOutlineIcon: CreditCardOutlineIcon,
  CreditCardSpendingLimitFilledIcon: CreditCardSpendingLimitFilledIcon,
  CreditCardSpendingLimitOutlineIcon: CreditCardSpendingLimitOutlineIcon,
  CsvFilledIcon: CsvFilledIcon,
  CsvOutlineIcon: CsvOutlineIcon,
  CupFilledIcon: CupFilledIcon,
  CupOutlineIcon: CupOutlineIcon,
  CurrencyCoversionDollarIcon: CurrencyCoversionDollarIcon,
  CurrencyCoversionEurIcon: CurrencyCoversionEurIcon,
  CurrencyCoversionPoundIcon: CurrencyCoversionPoundIcon,
  CurrencyCoversionRupeeIcon: CurrencyCoversionRupeeIcon,
  CurrencyCoversionYenIcon: CurrencyCoversionYenIcon,
  CurrentStepFilledIcon: CurrentStepFilledIcon,
  CurrentStepOutlineIcon: CurrentStepOutlineIcon,
  CustomAppsFilledIcon: CustomAppsFilledIcon,
  CustomAppsOutlineIcon: CustomAppsOutlineIcon,
  CustomerMaturityFilledIcon: CustomerMaturityFilledIcon,
  CustomerMaturityOutlineIcon: CustomerMaturityOutlineIcon,
  CustomerSpotlightFilledIcon: CustomerSpotlightFilledIcon,
  CustomerSpotlightOutlineIcon: CustomerSpotlightOutlineIcon,
  CustomerTestimonialsFilledIcon: CustomerTestimonialsFilledIcon,
  CustomerTestimonialsOutlineIcon: CustomerTestimonialsOutlineIcon,
  CustomFieldsFilledIcon: CustomFieldsFilledIcon,
  CustomFieldsOutlineIcon: CustomFieldsOutlineIcon,
  CustomizePolicyFilledIcon: CustomizePolicyFilledIcon,
  CustomizePolicyOutlineIcon: CustomizePolicyOutlineIcon,
  CutIcon: CutIcon,
  CzechKorunaIcon: CzechKorunaIcon,
  DashboardIcon: DashboardIcon,
  DataConnectorFilledIcon: DataConnectorFilledIcon,
  DataConnectorOutlineIcon: DataConnectorOutlineIcon,
  DeliverytruckFilledIcon: DeliverytruckFilledIcon,
  DeliverytruckOutlineIcon: DeliverytruckOutlineIcon,
  DepartdateIcon: DepartdateIcon,
  DepartmentsFilledIcon: DepartmentsFilledIcon,
  DepartmentsOutlineIcon: DepartmentsOutlineIcon,
  DepartureFilledIcon: DepartureFilledIcon,
  DepartureOutlineIcon: DepartureOutlineIcon,
  DeployFilledIcon: DeployFilledIcon,
  DeployOutlineIcon: DeployOutlineIcon,
  DesktopFilledIcon: DesktopFilledIcon,
  DesktopOutlineIcon: DesktopOutlineIcon,
  DeviceInventoryFilledIcon: DeviceInventoryFilledIcon,
  DeviceInventoryManagementFilledIcon: DeviceInventoryManagementFilledIcon,
  DeviceInventoryManagementOutlineIcon: DeviceInventoryManagementOutlineIcon,
  DeviceInventoryOutlineIcon: DeviceInventoryOutlineIcon,
  DirectReportsFilledIcon: DirectReportsFilledIcon,
  DirectReportsOutlineIcon: DirectReportsOutlineIcon,
  DissatisfiedFilledIcon: DissatisfiedFilledIcon,
  DissatisfiedOutlineIcon: DissatisfiedOutlineIcon,
  DocumentFilledIcon: DocumentFilledIcon,
  DocumentOutlineIcon: DocumentOutlineIcon,
  DogFriendlyFilledIcon: DogFriendlyFilledIcon,
  DogFriendlyOutlineIcon: DogFriendlyOutlineIcon,
  DollarcirclearrowsFilledIcon: DollarcirclearrowsFilledIcon,
  DollarcirclearrowsOutlineIcon: DollarcirclearrowsOutlineIcon,
  DollarcirclecogFilledIcon: DollarcirclecogFilledIcon,
  DollarcirclecogOutlineIcon: DollarcirclecogOutlineIcon,
  DollarCircleFilledIcon: DollarCircleFilledIcon,
  DollarCircleOutlineIcon: DollarCircleOutlineIcon,
  DollarIcon: DollarIcon,
  DonutChartFilledIcon: DonutChartFilledIcon,
  DonutChartOutlineIcon: DonutChartOutlineIcon,
  DownloadIcon: DownloadIcon,
  DragIcon: DragIcon,
  DropdownFilledIcon: DropdownFilledIcon,
  DropdownFolderFilledIcon: DropdownFolderFilledIcon,
  DropdownFolderOutlineIcon: DropdownFolderOutlineIcon,
  DropdownOutlineIcon: DropdownOutlineIcon,
  DropdownSortedDownIcon: DropdownSortedDownIcon,
  DropdownSortedUpIcon: DropdownSortedUpIcon,
  DStackedBarFilledIcon: DStackedBarFilledIcon,
  DStackedBarOutlineIcon: DStackedBarOutlineIcon,
  DuplicateFilledIcon: DuplicateFilledIcon,
  DuplicateOutlineIcon: DuplicateOutlineIcon,
  EditFilledIcon: EditFilledIcon,
  EditOutlineIcon: EditOutlineIcon,
  EmailFilledIcon: EmailFilledIcon,
  EmailOutlineIcon: EmailOutlineIcon,
  EmploymentInfoFilledIcon: EmploymentInfoFilledIcon,
  EmploymentInfoOutlineIcon: EmploymentInfoOutlineIcon,
  EngineeringFilledIcon: EngineeringFilledIcon,
  EngineeringOutlineIcon: EngineeringOutlineIcon,
  EnterFullScreenIcon: EnterFullScreenIcon,
  EntertainmentFilledIcon: EntertainmentFilledIcon,
  EntertainmentOutlineIcon: EntertainmentOutlineIcon,
  EqualToIcon: EqualToIcon,
  EquityDollarFilledIcon: EquityDollarFilledIcon,
  EquityDollarOutlineIcon: EquityDollarOutlineIcon,
  EquityEuroFilledIcon: EquityEuroFilledIcon,
  EquityEuroOutlineIcon: EquityEuroOutlineIcon,
  EquityPoundFilledIcon: EquityPoundFilledIcon,
  EquityPoundOutlineIcon: EquityPoundOutlineIcon,
  EquityRupeeFilledIcon: EquityRupeeFilledIcon,
  EquityRupeeOutlineIcon: EquityRupeeOutlineIcon,
  EquityYenFilledIcon: EquityYenFilledIcon,
  EquityYenOutlineIcon: EquityYenOutlineIcon,
  EuroCircleFilledIcon: EuroCircleFilledIcon,
  EuroCircleOutlineIcon: EuroCircleOutlineIcon,
  EuroIcon: EuroIcon,
  EventsAndWebinarsFilledIcon: EventsAndWebinarsFilledIcon,
  EventsAndWebinarsOutlineIcon: EventsAndWebinarsOutlineIcon,
  ExitFullScreenIcon: ExitFullScreenIcon,
  ExpandIcon: ExpandIcon,
  ExpandPanelFilledIcon: ExpandPanelFilledIcon,
  ExpandPanelOutlineIcon: ExpandPanelOutlineIcon,
  ExpensePolicyDollarFilledIcon: ExpensePolicyDollarFilledIcon,
  ExpensePolicyDollarOutlineIcon: ExpensePolicyDollarOutlineIcon,
  ExpensePolicyEuroFilledIcon: ExpensePolicyEuroFilledIcon,
  ExpensePolicyEuroOutlineIcon: ExpensePolicyEuroOutlineIcon,
  ExpensePolicyPoundFilledIcon: ExpensePolicyPoundFilledIcon,
  ExpensePolicyPoundOutlineIcon: ExpensePolicyPoundOutlineIcon,
  ExpensePolicyRupeeFilledIcon: ExpensePolicyRupeeFilledIcon,
  ExpensePolicyRupeeOutlineIcon: ExpensePolicyRupeeOutlineIcon,
  ExpensePolicyYenFilledIcon: ExpensePolicyYenFilledIcon,
  ExpensePolicyYenOutlineIcon: ExpensePolicyYenOutlineIcon,
  FeatherFilledIcon: FeatherFilledIcon,
  FeatherOutlineIcon: FeatherOutlineIcon,
  FeedbackFormFilledIcon: FeedbackFormFilledIcon,
  FeedbackFormOutlineIcon: FeedbackFormOutlineIcon,
  FidoFilledIcon: FidoFilledIcon,
  FidoOutlineIcon: FidoOutlineIcon,
  FileFilledIcon: FileFilledIcon,
  FileOutlineIcon: FileOutlineIcon,
  FileshieldplusFilledIcon: FileshieldplusFilledIcon,
  FileshieldplusOutlineIcon: FileshieldplusOutlineIcon,
  FilledIcon11: FilledIcon11,
  FilterByTypeFilledIcon: FilterByTypeFilledIcon,
  FilterByTypeOutlineIcon: FilterByTypeOutlineIcon,
  FilterIcon: FilterIcon,
  FinancesFilledIcon: FinancesFilledIcon,
  FinancesOutlineIcon: FinancesOutlineIcon,
  FingerprintIcon: FingerprintIcon,
  FitnessFilledIcon: FitnessFilledIcon,
  FitnessOutlineIcon: FitnessOutlineIcon,
  FlagFilledIcon: FlagFilledIcon,
  FlagOutlineIcon: FlagOutlineIcon,
  FlexibleWorkScheduleFilledIcon: FlexibleWorkScheduleFilledIcon,
  FlexibleWorkScheduleOutlineIcon: FlexibleWorkScheduleOutlineIcon,
  FlightsFilledIcon: FlightsFilledIcon,
  FlightsOutlineIcon: FlightsOutlineIcon,
  FolderFilledIcon: FolderFilledIcon,
  FolderOutlineIcon: FolderOutlineIcon,
  FontColorFilledIcon: FontColorFilledIcon,
  FontColorOutlineIcon: FontColorOutlineIcon,
  FontHighlightFilledIcon: FontHighlightFilledIcon,
  FontHighlightOutlineIcon: FontHighlightOutlineIcon,
  FontSizeIcon: FontSizeIcon,
  Forward10SecondsIcon: Forward10SecondsIcon,
  FsaFilledIcon: FsaFilledIcon,
  FsaOutlineIcon: FsaOutlineIcon,
  FunctionIcon: FunctionIcon,
  FunnelChartFilledIcon: FunnelChartFilledIcon,
  FunnelChartOutlineIcon: FunnelChartOutlineIcon,
  FutureStepFilledIcon: FutureStepFilledIcon,
  FutureStepOutlineIcon: FutureStepOutlineIcon,
  FxFilledIcon: FxFilledIcon,
  FxOutlineIcon: FxOutlineIcon,
  GaugeChartFilledIcon: GaugeChartFilledIcon,
  GaugeChartOutlineIcon: GaugeChartOutlineIcon,
  GeneralLedgerDollarFilledIcon: GeneralLedgerDollarFilledIcon,
  GeneralLedgerDollarOutlineIcon: GeneralLedgerDollarOutlineIcon,
  GeneralLedgerEuroFilledIcon: GeneralLedgerEuroFilledIcon,
  GeneralLedgerEuroOutlineIcon: GeneralLedgerEuroOutlineIcon,
  GeneralLedgerPoundFilledIcon: GeneralLedgerPoundFilledIcon,
  GeneralLedgerPoundOutlineIcon: GeneralLedgerPoundOutlineIcon,
  GeneralLedgerRupeeFilledIcon: GeneralLedgerRupeeFilledIcon,
  GeneralLedgerRupeeOutlineIcon: GeneralLedgerRupeeOutlineIcon,
  GeneralLedgerYenFilledIcon: GeneralLedgerYenFilledIcon,
  GeneralLedgerYenOutlineIcon: GeneralLedgerYenOutlineIcon,
  GifFilledIcon: GifFilledIcon,
  GifOutlineIcon: GifOutlineIcon,
  GiftFilledIcon: GiftFilledIcon,
  GiftOutlineIcon: GiftOutlineIcon,
  GlobeFilledIcon: GlobeFilledIcon,
  GlobeOutlineIcon: GlobeOutlineIcon,
  GoalsFilledIcon: GoalsFilledIcon,
  GoalsOutlineIcon: GoalsOutlineIcon,
  GPTFilledIcon: GPTFilledIcon,
  GPTOutlineIcon: GPTOutlineIcon,
  GraphchartIcon: GraphchartIcon,
  GreaterOrEqualToIcon: GreaterOrEqualToIcon,
  GreaterThanIcon: GreaterThanIcon,
  GroceriesFilledIcon: GroceriesFilledIcon,
  GroceriesOutlineIcon: GroceriesOutlineIcon,
  GuidepostFilledIcon: GuidepostFilledIcon,
  GuidepostOutlineIcon: GuidepostOutlineIcon,
  HamburgerIcon: HamburgerIcon,
  HanddollarFilledIcon: HanddollarFilledIcon,
  HanddollarOutlineIcon: HanddollarOutlineIcon,
  HandplusFilledIcon: HandplusFilledIcon,
  HandplusOutlineIcon: HandplusOutlineIcon,
  HandshakeFilledIcon: HandshakeFilledIcon,
  HandshakeOutlineIcon: HandshakeOutlineIcon,
  HardwareSecurityKeyFilledIcon: HardwareSecurityKeyFilledIcon,
  HardwareSecurityKeyOutlineIcon: HardwareSecurityKeyOutlineIcon,
  HeadphonesFilledIcon: HeadphonesFilledIcon,
  HeadphonesOutlineIcon: HeadphonesOutlineIcon,
  HealingFilledIcon: HealingFilledIcon,
  HealingOutlineIcon: HealingOutlineIcon,
  HealthFilledIcon: HealthFilledIcon,
  HealthOutlineIcon: HealthOutlineIcon,
  HeartFilledIcon: HeartFilledIcon,
  HeartOutlineIcon: HeartOutlineIcon,
  HelpCenterFilledIcon: HelpCenterFilledIcon,
  HelpCenterOutlineIcon: HelpCenterOutlineIcon,
  HelpFilledIcon: HelpFilledIcon,
  HelpOutlineIcon: HelpOutlineIcon,
  HideFilledIcon: HideFilledIcon,
  HideOutlineIcon: HideOutlineIcon,
  HighPriorityIcon: HighPriorityIcon,
  HistoryIcon: HistoryIcon,
  HomeExitIcon: HomeExitIcon,
  HomeFilledIcon: HomeFilledIcon,
  HomeOutlineIcon: HomeOutlineIcon,
  HotelclassFilledIcon: HotelclassFilledIcon,
  HotelclassOutlineIcon: HotelclassOutlineIcon,
  HotelFilledIcon: HotelFilledIcon,
  HotelOutlineIcon: HotelOutlineIcon,
  HrFilledIcon: HrFilledIcon,
  HrOutlineIcon: HrOutlineIcon,
  HsaFilledIcon: HsaFilledIcon,
  HsaOutlineIcon: HsaOutlineIcon,
  I9FilledIcon: I9FilledIcon,
  I9OutlineIcon: I9OutlineIcon,
  Illustration401kRetirementDark: Illustration401kRetirementDark,
  Illustration401kRetirementLight: Illustration401kRetirementLight,
  IllustrationAddDark: IllustrationAddDark,
  IllustrationAddLight: IllustrationAddLight,
  IllustrationAIDark: IllustrationAIDark,
  IllustrationAIDark1: IllustrationAIDark1,
  IllustrationAIDark2: IllustrationAIDark2,
  IllustrationAILight: IllustrationAILight,
  IllustrationApprovalDark: IllustrationApprovalDark,
  IllustrationApprovalLight: IllustrationApprovalLight,
  IllustrationApprovalLight1: IllustrationApprovalLight1,
  IllustrationApprovalLight2: IllustrationApprovalLight2,
  IllustrationAutomateDark: IllustrationAutomateDark,
  IllustrationAutomateLight: IllustrationAutomateLight,
  IllustrationBarChartDecreaseDark: IllustrationBarChartDecreaseDark,
  IllustrationBarChartDecreaseLight: IllustrationBarChartDecreaseLight,
  IllustrationBarChartIncreasedark: IllustrationBarChartIncreasedark,
  IllustrationBarChartIncreaselight: IllustrationBarChartIncreaselight,
  IllustrationBenefitsAdministrationDark: IllustrationBenefitsAdministrationDark,
  IllustrationBenefitsAdministrationLight: IllustrationBenefitsAdministrationLight,
  IllustrationBrainDark: IllustrationBrainDark,
  IllustrationBrainLight: IllustrationBrainLight,
  IllustrationCalendarDark: IllustrationCalendarDark,
  IllustrationCalendarLight: IllustrationCalendarLight,
  IllustrationCelebrationDark: IllustrationCelebrationDark,
  IllustrationCelebrationLight: IllustrationCelebrationLight,
  IllustrationCelebrationLight1: IllustrationCelebrationLight1,
  IllustrationCelebrationLight2: IllustrationCelebrationLight2,
  IllustrationCheckmarkDark: IllustrationCheckmarkDark,
  IllustrationCheckmarkLight: IllustrationCheckmarkLight,
  IllustrationClockDark: IllustrationClockDark,
  IllustrationClockLight: IllustrationClockLight,
  IllustrationCommunityDark: IllustrationCommunityDark,
  IllustrationCommunityLight: IllustrationCommunityLight,
  IllustrationCommuterBenefitsDark: IllustrationCommuterBenefitsDark,
  IllustrationCommuterBenefitsLight: IllustrationCommuterBenefitsLight,
  IllustrationComplianceDark: IllustrationComplianceDark,
  IllustrationComplianceLight: IllustrationComplianceLight,
  IllustrationCoreDark: IllustrationCoreDark,
  IllustrationCoreLight: IllustrationCoreLight,
  IllustrationCreditCardDark: IllustrationCreditCardDark,
  IllustrationCreditCardDark1: IllustrationCreditCardDark1,
  IllustrationCreditCardDark2: IllustrationCreditCardDark2,
  IllustrationCreditCardLight: IllustrationCreditCardLight,
  IllustrationDentalDark: IllustrationDentalDark,
  IllustrationDentalLight: IllustrationDentalLight,
  IllustrationDeveloperDark: IllustrationDeveloperDark,
  IllustrationDeveloperLight: IllustrationDeveloperLight,
  IllustrationDeviceDark: IllustrationDeviceDark,
  IllustrationDeviceLight: IllustrationDeviceLight,
  IllustrationDocumentsDark: IllustrationDocumentsDark,
  IllustrationDocumentsLight: IllustrationDocumentsLight,
  IllustrationEnterpriseBusinessDark: IllustrationEnterpriseBusinessDark,
  IllustrationEnterpriseBusinessLight: IllustrationEnterpriseBusinessLight,
  IllustrationEnterpriseDark: IllustrationEnterpriseDark,
  IllustrationEnterpriseLight: IllustrationEnterpriseLight,
  IllustrationErrorDark: IllustrationErrorDark,
  IllustrationErrorLight: IllustrationErrorLight,
  IllustrationExpenseManagementDark: IllustrationExpenseManagementDark,
  IllustrationExpenseManagementLight: IllustrationExpenseManagementLight,
  IllustrationFolderDark: IllustrationFolderDark,
  IllustrationFolderLight: IllustrationFolderLight,
  IllustrationGearDark: IllustrationGearDark,
  IllustrationGearLight: IllustrationGearLight,
  IllustrationGlobeDark: IllustrationGlobeDark,
  IllustrationGlobeLight: IllustrationGlobeLight,
  IllustrationGlobeLight1: IllustrationGlobeLight1,
  IllustrationGlobeLight2: IllustrationGlobeLight2,
  IllustrationHandshakeDark: IllustrationHandshakeDark,
  IllustrationHandshakeDark1: IllustrationHandshakeDark1,
  IllustrationHandshakeLight: IllustrationHandshakeLight,
  IllustrationHeadcountPlanningDark: IllustrationHeadcountPlanningDark,
  IllustrationHeadcountPlanningLight: IllustrationHeadcountPlanningLight,
  IllustrationHealthInsuranceDark: IllustrationHealthInsuranceDark,
  IllustrationHealthInsuranceDark1: IllustrationHealthInsuranceDark1,
  IllustrationHealthInsuranceDark2: IllustrationHealthInsuranceDark2,
  IllustrationHealthInsuranceLight: IllustrationHealthInsuranceLight,
  IllustrationIndividualDark: IllustrationIndividualDark,
  IllustrationIndividualLight: IllustrationIndividualLight,
  IllustrationIntegrationDark: IllustrationIntegrationDark,
  IllustrationIntegrationLight: IllustrationIntegrationLight,
  IllustrationInventoryManagementDark: IllustrationInventoryManagementDark,
  IllustrationInventoryManagementLight: IllustrationInventoryManagementLight,
  IllustrationLeaderDark: IllustrationLeaderDark,
  IllustrationLeaderLight: IllustrationLeaderLight,
  IllustrationLearningDark: IllustrationLearningDark,
  IllustrationLearningLight: IllustrationLearningLight,
  IllustrationLightningBoltDark: IllustrationLightningBoltDark,
  IllustrationLightningBoltLight: IllustrationLightningBoltLight,
  IllustrationLocalCurrencyDark: IllustrationLocalCurrencyDark,
  IllustrationLocalCurrencyLight: IllustrationLocalCurrencyLight,
  IllustrationLockDark: IllustrationLockDark,
  IllustrationLockLight: IllustrationLockLight,
  IllustrationMagnifyingGlasQuestionMarkDark: IllustrationMagnifyingGlasQuestionMarkDark,
  IllustrationMagnifyingGlasQuestionMarkDark1: IllustrationMagnifyingGlasQuestionMarkDark1,
  IllustrationMagnifyingGlasQuestionMarkLight: IllustrationMagnifyingGlasQuestionMarkLight,
  IllustrationMagnifyingGlassDark: IllustrationMagnifyingGlassDark,
  IllustrationMagnifyingGlassLight: IllustrationMagnifyingGlassLight,
  IllustrationMegaphoneDark: IllustrationMegaphoneDark,
  IllustrationMegaphoneLight: IllustrationMegaphoneLight,
  IllustrationMegaphoneLight1: IllustrationMegaphoneLight1,
  IllustrationMegaphoneLight2: IllustrationMegaphoneLight2,
  IllustrationMidSizeBusinessDark: IllustrationMidSizeBusinessDark,
  IllustrationMidSizeBusinessLight: IllustrationMidSizeBusinessLight,
  IllustrationOffboardingEmployeeDark: IllustrationOffboardingEmployeeDark,
  IllustrationOffboardingEmployeeLight: IllustrationOffboardingEmployeeLight,
  IllustrationOnboardingEmployeeDark: IllustrationOnboardingEmployeeDark,
  IllustrationOnboardingEmployeeLight: IllustrationOnboardingEmployeeLight,
  IllustrationPayrollEuroDark: IllustrationPayrollEuroDark,
  IllustrationPayrollEuroLight: IllustrationPayrollEuroLight,
  IllustrationPayrollPoundDark: IllustrationPayrollPoundDark,
  IllustrationPayrollPoundLight: IllustrationPayrollPoundLight,
  IllustrationPayrollRupeeDark: IllustrationPayrollRupeeDark,
  IllustrationPayrollRupeeLight: IllustrationPayrollRupeeLight,
  IllustrationPayrollUSDDark: IllustrationPayrollUSDDark,
  IllustrationPayrollUSDLight: IllustrationPayrollUSDLight,
  IllustrationPayrollYenDark: IllustrationPayrollYenDark,
  IllustrationPayrollYenDark1: IllustrationPayrollYenDark1,
  IllustrationPayrollYenDark2: IllustrationPayrollYenDark2,
  IllustrationPayrollYenLight: IllustrationPayrollYenLight,
  IllustrationPerformanceManagementDark: IllustrationPerformanceManagementDark,
  IllustrationPerformanceManagementLight: IllustrationPerformanceManagementLight,
  IllustrationPieChartDark: IllustrationPieChartDark,
  IllustrationPieChartLight: IllustrationPieChartLight,
  IllustrationProDark: IllustrationProDark,
  IllustrationProLight: IllustrationProLight,
  IllustrationPulseSurveyDark: IllustrationPulseSurveyDark,
  IllustrationPulseSurveyDark1: IllustrationPulseSurveyDark1,
  IllustrationPulseSurveyDark2: IllustrationPulseSurveyDark2,
  IllustrationPulseSurveyLight: IllustrationPulseSurveyLight,
  IllustrationQuestionMarkDark: IllustrationQuestionMarkDark,
  IllustrationQuestionMarkLight: IllustrationQuestionMarkLight,
  IllustrationReceiptDark: IllustrationReceiptDark,
  IllustrationReceiptLight: IllustrationReceiptLight,
  IllustrationRemoveDark: IllustrationRemoveDark,
  IllustrationRemoveLight: IllustrationRemoveLight,
  IllustrationRemoveLight1: IllustrationRemoveLight1,
  IllustrationRemoveLight2: IllustrationRemoveLight2,
  IllustrationSmallBusinessDark: IllustrationSmallBusinessDark,
  IllustrationSmallBusinessLight: IllustrationSmallBusinessLight,
  IllustrationSmartPhoneDark: IllustrationSmartPhoneDark,
  IllustrationSmartPhoneLight: IllustrationSmartPhoneLight,
  IllustrationSupportDark: IllustrationSupportDark,
  IllustrationSupportLight: IllustrationSupportLight,
  IllustrationSyncDark: IllustrationSyncDark,
  IllustrationSyncLight: IllustrationSyncLight,
  IllustrationTaxesDark: IllustrationTaxesDark,
  IllustrationTaxesLight: IllustrationTaxesLight,
  IllustrationThirdPartyAppsDark: IllustrationThirdPartyAppsDark,
  IllustrationThirdPartyAppsLight: IllustrationThirdPartyAppsLight,
  IllustrationThumbsUpdark: IllustrationThumbsUpdark,
  IllustrationThumbsUplight: IllustrationThumbsUplight,
  IllustrationTravelDark: IllustrationTravelDark,
  IllustrationTravelLight: IllustrationTravelLight,
  IllustrationUnifiedSystemDark: IllustrationUnifiedSystemDark,
  IllustrationUnifiedSystemLight: IllustrationUnifiedSystemLight,
  IllustrationUnlimitedDark: IllustrationUnlimitedDark,
  IllustrationUnlimitedLight: IllustrationUnlimitedLight,
  IllustrationUnlockDark: IllustrationUnlockDark,
  IllustrationUnlockLight: IllustrationUnlockLight,
  IllustrationVisionDark: IllustrationVisionDark,
  IllustrationVisionLight: IllustrationVisionLight,
  IllustrationWorkforcePlanningDark: IllustrationWorkforcePlanningDark,
  IllustrationWorkforcePlanningLight: IllustrationWorkforcePlanningLight,
  ImageAdjustOutlineBreakTextIcon: ImageAdjustOutlineBreakTextIcon,
  ImageAdjustOutlineInlineIcon: ImageAdjustOutlineInlineIcon,
  ImageAdjustOutlineWrapTextIcon: ImageAdjustOutlineWrapTextIcon,
  ImageFilledIcon: ImageFilledIcon,
  ImageOutlineIcon: ImageOutlineIcon,
  IndentDecreaseIcon: IndentDecreaseIcon,
  IndentIncreaseIcon: IndentIncreaseIcon,
  InfoFilledIcon: InfoFilledIcon,
  InfoOutlineIcon: InfoOutlineIcon,
  InPlanFilledIcon: InPlanFilledIcon,
  InPlanOutlineIcon: InPlanOutlineIcon,
  InPolicyFilledIcon: InPolicyFilledIcon,
  InPolicyOutlineIcon: InPolicyOutlineIcon,
  InProgressStepFilledIcon: InProgressStepFilledIcon,
  InProgressStepOutlineIcon: InProgressStepOutlineIcon,
  IntegratedAppsFilledIcon: IntegratedAppsFilledIcon,
  IntegratedAppsOutlineIcon: IntegratedAppsOutlineIcon,
  InteractiveCourseFilledIcon: InteractiveCourseFilledIcon,
  InteractiveCourseOutlineIcon: InteractiveCourseOutlineIcon,
  InvoiceDollarFilledIcon: InvoiceDollarFilledIcon,
  InvoiceDollarOutlineIcon: InvoiceDollarOutlineIcon,
  InvoiceEuroFilledIcon: InvoiceEuroFilledIcon,
  InvoiceEuroOutlineIcon: InvoiceEuroOutlineIcon,
  InvoicePoundFilledIcon: InvoicePoundFilledIcon,
  InvoicePoundOutlineIcon: InvoicePoundOutlineIcon,
  InvoiceRupeeFilledIcon: InvoiceRupeeFilledIcon,
  InvoiceRupeeOutlineIcon: InvoiceRupeeOutlineIcon,
  InvoiceYenFilledIcon: InvoiceYenFilledIcon,
  InvoiceYenOutlineIcon: InvoiceYenOutlineIcon,
  IPhoneFilledIcon: IPhoneFilledIcon,
  IPhoneOutlineIcon: IPhoneOutlineIcon,
  ItalicIcon: ItalicIcon,
  KanbanIcon: KanbanIcon,
  KeyholeFilledIcon: KeyholeFilledIcon,
  KeyholeOutlineIcon: KeyholeOutlineIcon,
  KeyIcon: KeyIcon,
  LabsIcon: LabsIcon,
  LaptopFilledIcon: LaptopFilledIcon,
  LaptopOutlineIcon: LaptopOutlineIcon,
  LeaveManagementFilledIcon: LeaveManagementFilledIcon,
  LeaveManagementOutlineIcon: LeaveManagementOutlineIcon,
  LegalFilledIcon: LegalFilledIcon,
  LegalOutlineIcon: LegalOutlineIcon,
  LegroomFilledIcon: LegroomFilledIcon,
  LegroomOutlineIcon: LegroomOutlineIcon,
  LessOrEqualToIcon: LessOrEqualToIcon,
  LessThanIcon: LessThanIcon,
  LifeFilledIcon: LifeFilledIcon,
  LifeOutlineIcon: LifeOutlineIcon,
  LinechartIcon: LinechartIcon,
  LineHeightIcon: LineHeightIcon,
  LinkIcon: LinkIcon,
  LinkOutletIcon: LinkOutletIcon,
  ListFilledIcon: ListFilledIcon,
  ListOutlineIcon: ListOutlineIcon,
  LithuanianLitasIcon: LithuanianLitasIcon,
  LmsFilledIcon: LmsFilledIcon,
  LmsOutlineIcon: LmsOutlineIcon,
  LocationFilledIcon: LocationFilledIcon,
  LocationOutlineIcon: LocationOutlineIcon,
  LockFilledIcon: LockFilledIcon,
  LockOutlineIcon: LockOutlineIcon,
  LongAnswerIcon: LongAnswerIcon,
  MarkAsStolenFilledIcon: MarkAsStolenFilledIcon,
  MarkAsStolenOutlineIcon: MarkAsStolenOutlineIcon,
  MarketingAndAdvertisingFilledIcon: MarketingAndAdvertisingFilledIcon,
  MarketingAndAdvertisingOutlineIcon: MarketingAndAdvertisingOutlineIcon,
  MealsFilledIcon: MealsFilledIcon,
  MealsOutlineIcon: MealsOutlineIcon,
  MealsSnacksDrinksFilledIcon: MealsSnacksDrinksFilledIcon,
  MealsSnacksDrinksOutlineIcon: MealsSnacksDrinksOutlineIcon,
  MedicalFilledIcon: MedicalFilledIcon,
  MedicalOutlineIcon: MedicalOutlineIcon,
  MeetingRoomsFilledIcon: MeetingRoomsFilledIcon,
  MeetingRoomsOutlineIcon: MeetingRoomsOutlineIcon,
  MergeCellsIcon: MergeCellsIcon,
  MessageFilledIcon: MessageFilledIcon,
  MessageOutlineIcon: MessageOutlineIcon,
  MetricChartIcon: MetricChartIcon,
  MinimizeIcon: MinimizeIcon,
  MonitoringIcon: MonitoringIcon,
  MoreHorizontalIcon: MoreHorizontalIcon,
  MoreVerticalIcon: MoreVerticalIcon,
  MultiSelectFilledIcon: MultiSelectFilledIcon,
  MultiSelectOutlineIcon: MultiSelectOutlineIcon,
  MultiStopFilledIcon: MultiStopFilledIcon,
  MultiStopOutlineIcon: MultiStopOutlineIcon,
  MuteFilledIcon: MuteFilledIcon,
  MuteOutlineIcon: MuteOutlineIcon,
  MyAccountSettingsFilledIcon: MyAccountSettingsFilledIcon,
  MyAccountSettingsOutlineIcon: MyAccountSettingsOutlineIcon,
  NeutralFilledIcon: NeutralFilledIcon,
  NeutralOutlineIcon: NeutralOutlineIcon,
  NextSpeakerIcon: NextSpeakerIcon,
  NonprofitFilledIcon: NonprofitFilledIcon,
  NonprofitOutlineIcon: NonprofitOutlineIcon,
  NotEqualToIcon: NotEqualToIcon,
  NoticeIcon: NoticeIcon,
  NotificationFilledIcon: NotificationFilledIcon,
  NotificationOutlineIcon: NotificationOutlineIcon,
  NumberedListIcon: NumberedListIcon,
  ObjectFilledIcon: ObjectFilledIcon,
  ObjectOutlineIcon: ObjectOutlineIcon,
  OffboardFilledIcon: OffboardFilledIcon,
  OffboardOutlineIcon: OffboardOutlineIcon,
  OfficeFilledIcon: OfficeFilledIcon,
  OfficeOutlineIcon: OfficeOutlineIcon,
  OfficeSuppliesFilledIcon: OfficeSuppliesFilledIcon,
  OfficeSuppliesOutlineIcon: OfficeSuppliesOutlineIcon,
  OfflineFilledIcon: OfflineFilledIcon,
  OfflineOutlineIcon: OfflineOutlineIcon,
  OnboardFilledIcon: OnboardFilledIcon,
  OnboardOutlineIcon: OnboardOutlineIcon,
  OnlineFilledIcon: OnlineFilledIcon,
  OnlineOutlineIcon: OnlineOutlineIcon,
  OpenApiIcon: OpenApiIcon,
  OptionsIcon: OptionsIcon,
  OriginFilledIcon: OriginFilledIcon,
  OriginOutlineIcon: OriginOutlineIcon,
  OutlineIcon11: OutlineIcon11,
  OutOfPlanFilledIcon: OutOfPlanFilledIcon,
  OutOfPlanOutlineIcon: OutOfPlanOutlineIcon,
  OutOfPolicyFilledIcon: OutOfPolicyFilledIcon,
  OutOfPolicyOutlineIcon: OutOfPolicyOutlineIcon,
  OvernightFilledIcon: OvernightFilledIcon,
  OvernightOutlineIcon: OvernightOutlineIcon,
  OvertimePolicyFilledIcon: OvertimePolicyFilledIcon,
  OvertimePolicyOutlineIcon: OvertimePolicyOutlineIcon,
  PaidSeatSelectionFilledIcon: PaidSeatSelectionFilledIcon,
  PaidSeatSelectionOutlineIcon: PaidSeatSelectionOutlineIcon,
  PasteFilledIcon: PasteFilledIcon,
  PasteOutlineIcon: PasteOutlineIcon,
  PauseCircleFilledIcon: PauseCircleFilledIcon,
  PauseCircleOutlineIcon: PauseCircleOutlineIcon,
  PauseIcon: PauseIcon,
  PayrollReportDollarFilledIcon: PayrollReportDollarFilledIcon,
  PayrollReportDollarOutlineIcon: PayrollReportDollarOutlineIcon,
  PayrollReportEuroFilledIcon: PayrollReportEuroFilledIcon,
  PayrollReportEuroOutlineIcon: PayrollReportEuroOutlineIcon,
  PayrollReportPoundFilledIcon: PayrollReportPoundFilledIcon,
  PayrollReportPoundOutlineIcon: PayrollReportPoundOutlineIcon,
  PayrollReportRupeeFilledIcon: PayrollReportRupeeFilledIcon,
  PayrollReportRupeeOutlineIcon: PayrollReportRupeeOutlineIcon,
  PayrollReportYenFilledIcon: PayrollReportYenFilledIcon,
  PayrollReportYenOutlineIcon: PayrollReportYenOutlineIcon,
  PayrollTimeDollarFilledIcon: PayrollTimeDollarFilledIcon,
  PayrollTimeDollarOutlineIcon: PayrollTimeDollarOutlineIcon,
  PayrollTimeEuroFilledIcon: PayrollTimeEuroFilledIcon,
  PayrollTimeEuroOutlineIcon: PayrollTimeEuroOutlineIcon,
  PayrollTimePoundFilledIcon: PayrollTimePoundFilledIcon,
  PayrollTimePoundOutlineIcon: PayrollTimePoundOutlineIcon,
  PayrollTimeRupeeFilledIcon: PayrollTimeRupeeFilledIcon,
  PayrollTimeRupeeOutlineIcon: PayrollTimeRupeeOutlineIcon,
  PayrollTimeYenFilledIcon: PayrollTimeYenFilledIcon,
  PayrollTimeYenOutlineIcon: PayrollTimeYenOutlineIcon,
  PayScheduleDollarFilledIcon: PayScheduleDollarFilledIcon,
  PayScheduleDollarOutlineIcon: PayScheduleDollarOutlineIcon,
  PayScheduleEuroFilledIcon: PayScheduleEuroFilledIcon,
  PayScheduleEuroOutlineIcon: PayScheduleEuroOutlineIcon,
  PaySchedulePoundFilledIcon: PaySchedulePoundFilledIcon,
  PaySchedulePoundOutlineIcon: PaySchedulePoundOutlineIcon,
  PayScheduleRupeeFilledIcon: PayScheduleRupeeFilledIcon,
  PayScheduleRupeeOutlineIcon: PayScheduleRupeeOutlineIcon,
  PayScheduleYenFilledIcon: PayScheduleYenFilledIcon,
  PayScheduleYenOutlineIcon: PayScheduleYenOutlineIcon,
  PaystubDollarIcon: PaystubDollarIcon,
  PaystubEuroIcon: PaystubEuroIcon,
  PaystubPoundIcon: PaystubPoundIcon,
  PaystubRupeeIcon: PaystubRupeeIcon,
  PaystubYenIcon: PaystubYenIcon,
  PdfFilledIcon: PdfFilledIcon,
  PdfOutlineIcon: PdfOutlineIcon,
  PendingapprovalFilledIcon: PendingapprovalFilledIcon,
  PendingapprovalOutlineIcon: PendingapprovalOutlineIcon,
  PendingCircleFilledIcon: PendingCircleFilledIcon,
  PendingCircleOutlineIcon: PendingCircleOutlineIcon,
  PendingFilledIcon: PendingFilledIcon,
  PendingOutlineIcon: PendingOutlineIcon,
  PeoFilledIcon: PeoFilledIcon,
  PeoOutlineIcon: PeoOutlineIcon,
  PercentFilledIcon: PercentFilledIcon,
  PercentOutlineIcon: PercentOutlineIcon,
  PhilippinePesoIcon: PhilippinePesoIcon,
  PhoneFilledIcon: PhoneFilledIcon,
  PhoneOutlineIcon: PhoneOutlineIcon,
  PhotoGalleryFilledIcon: PhotoGalleryFilledIcon,
  PhotoGalleryOutlineIcon: PhotoGalleryOutlineIcon,
  PlayButtonFilledIcon: PlayButtonFilledIcon,
  PlayButtonOutlineIcon: PlayButtonOutlineIcon,
  PlayCircleFilledIcon: PlayCircleFilledIcon,
  PlayCircleOutlineIcon: PlayCircleOutlineIcon,
  PointerTargetIcon: PointerTargetIcon,
  PointInTimeFilledIcon: PointInTimeFilledIcon,
  PointInTimeOutlineIcon: PointInTimeOutlineIcon,
  PolishZlotyIcon: PolishZlotyIcon,
  PoundCircleFilledIcon: PoundCircleFilledIcon,
  PoundCircleOutlineIcon: PoundCircleOutlineIcon,
  PoundIcon: PoundIcon,
  PowerButtonIcon: PowerButtonIcon,
  PowerFilledIcon: PowerFilledIcon,
  PowerOutlineIcon: PowerOutlineIcon,
  PriceCapIcon: PriceCapIcon,
  Product11s: Product11s,
  Product401k: Product401k,
  ProductAca: ProductAca,
  ProductAccidentInsurance: ProductAccidentInsurance,
  ProductAccountAndSettings: ProductAccountAndSettings,
  ProductAccountant: ProductAccountant,
  ProductAccountingIntegrationsEuro: ProductAccountingIntegrationsEuro,
  ProductAccountingIntegrationsPound: ProductAccountingIntegrationsPound,
  ProductAccountingIntegrationsRupee: ProductAccountingIntegrationsRupee,
  ProductAccountingIntegrationsUsd: ProductAccountingIntegrationsUsd,
  ProductAccountingIntegrationsYen: ProductAccountingIntegrationsYen,
  ProductADD: ProductADD,
  ProductAddApp: ProductAddApp,
  ProductAddictionSupport: ProductAddictionSupport,
  ProductAdoptionServices: ProductAdoptionServices,
  ProductAnniversaries: ProductAnniversaries,
  ProductAntivirus: ProductAntivirus,
  ProductAPI: ProductAPI,
  ProductAppManagement: ProductAppManagement,
  ProductApprovals: ProductApprovals,
  ProductAppShop: ProductAppShop,
  ProductASO: ProductASO,
  ProductAuditLog: ProductAuditLog,
  ProductAutism: ProductAutism,
  ProductAutomatedCompliance: ProductAutomatedCompliance,
  ProductBenefitsAdmin: ProductBenefitsAdmin,
  ProductBillingEuro: ProductBillingEuro,
  ProductBillingPound: ProductBillingPound,
  ProductBillingRupee: ProductBillingRupee,
  ProductBillingUsd: ProductBillingUsd,
  ProductBillingYen: ProductBillingYen,
  ProductBillPayEuro: ProductBillPayEuro,
  ProductBillPayPound: ProductBillPayPound,
  ProductBillPayRupee: ProductBillPayRupee,
  ProductBillPayUsd: ProductBillPayUsd,
  ProductBillPayYen: ProductBillPayYen,
  ProductCancer: ProductCancer,
  ProductCareManagement: ProductCareManagement,
  ProductCareNavigation: ProductCareNavigation,
  ProductCelebrations: ProductCelebrations,
  ProductClientCommandCenter: ProductClientCommandCenter,
  ProductCobra: ProductCobra,
  ProductCommuterBenefits: ProductCommuterBenefits,
  ProductCompanyAdmins: ProductCompanyAdmins,
  ProductCompanyDetails: ProductCompanyDetails,
  ProductCompanySettings: ProductCompanySettings,
  ProductCompensationBands: ProductCompensationBands,
  ProductCompliance360: ProductCompliance360,
  ProductContractors: ProductContractors,
  ProductCovid19: ProductCovid19,
  ProductCriticalIllnessInsurance: ProductCriticalIllnessInsurance,
  ProductCustomAlerts: ProductCustomAlerts,
  ProductCustomApps: ProductCustomApps,
  ProductCustomFields: ProductCustomFields,
  ProductCustomFluxCustomFluxOption1: ProductCustomFluxCustomFluxOption1,
  ProductCustomFluxCustomFluxOption2: ProductCustomFluxCustomFluxOption2,
  ProductCustomFluxCustomFluxOption3: ProductCustomFluxCustomFluxOption3,
  ProductCustomIntegrations: ProductCustomIntegrations,
  ProductDataManager: ProductDataManager,
  ProductDataManager1: ProductDataManager1,
  ProductDental: ProductDental,
  ProductDeviceManagement: ProductDeviceManagement,
  ProductDocuments: ProductDocuments,
  ProductEAP: ProductEAP,
  ProductEeoReporting: ProductEeoReporting,
  ProductEmployeeHome: ProductEmployeeHome,
  ProductEmploymentAuthorization: ProductEmploymentAuthorization,
  ProductEmploymentVerification: ProductEmploymentVerification,
  ProductEOR: ProductEOR,
  ProductEstatePlanning: ProductEstatePlanning,
  ProductExpenseManagementEuro: ProductExpenseManagementEuro,
  ProductExpenseManagementPound: ProductExpenseManagementPound,
  ProductExpenseManagementRupee: ProductExpenseManagementRupee,
  ProductExpenseManagementUsd: ProductExpenseManagementUsd,
  ProductExpenseManagementYen: ProductExpenseManagementYen,
  ProductFamilyCareServices: ProductFamilyCareServices,
  ProductFeedback: ProductFeedback,
  ProductFertility: ProductFertility,
  ProductFinancialWellness: ProductFinancialWellness,
  ProductFlexibleSpendingAccount: ProductFlexibleSpendingAccount,
  ProductFluxInbox: ProductFluxInbox,
  ProductForms: ProductForms,
  ProductGettingStarted: ProductGettingStarted,
  ProductGlobalPayroll: ProductGlobalPayroll,
  ProductGlobalSpend: ProductGlobalSpend,
  ProductGoals: ProductGoals,
  ProductGymStipend: ProductGymStipend,
  ProductHeadcountPlanning: ProductHeadcountPlanning,
  ProductHealthSavingsAccount: ProductHealthSavingsAccount,
  ProductHelp: ProductHelp,
  ProductHome: ProductHome,
  ProductHospitalIndemnity: ProductHospitalIndemnity,
  ProductHrHelpDesk: ProductHrHelpDesk,
  ProductI9Verification: ProductI9Verification,
  ProductIdentityTheft: ProductIdentityTheft,
  ProductIncomeVerificationEuro: ProductIncomeVerificationEuro,
  ProductIncomeVerificationPound: ProductIncomeVerificationPound,
  ProductIncomeVerificationRupee: ProductIncomeVerificationRupee,
  ProductIncomeVerificationUsd: ProductIncomeVerificationUsd,
  ProductIncomeVerificationYen: ProductIncomeVerificationYen,
  ProductInstantSso: ProductInstantSso,
  ProductInsurance: ProductInsurance,
  ProductInsuranceBroker: ProductInsuranceBroker,
  ProductIntegrations: ProductIntegrations,
  ProductInventoryManagement: ProductInventoryManagement,
  ProductItHub: ProductItHub,
  ProductJobCodes: ProductJobCodes,
  ProductLearningManagement: ProductLearningManagement,
  ProductLeaveManagement: ProductLeaveManagement,
  ProductLegalAid: ProductLegalAid,
  ProductLifeInsurance: ProductLifeInsurance,
  ProductLogInAs: ProductLogInAs,
  ProductLongTermCareInsurance: ProductLongTermCareInsurance,
  ProductLongTermDisability: ProductLongTermDisability,
  ProductMaternity: ProductMaternity,
  ProductMealVoucher: ProductMealVoucher,
  ProductMedical: ProductMedical,
  ProductMeditationMindfulness: ProductMeditationMindfulness,
  ProductMentalHealth: ProductMentalHealth,
  ProductMore: ProductMore,
  ProductMyBenefits: ProductMyBenefits,
  ProductMyPayEuro: ProductMyPayEuro,
  ProductMyPayPound: ProductMyPayPound,
  ProductMyPayRupee: ProductMyPayRupee,
  ProductMyPayUsd: ProductMyPayUsd,
  ProductMyPayYen: ProductMyPayYen,
  ProductNeedToTalk: ProductNeedToTalk,
  ProductNewsfeed: ProductNewsfeed,
  ProductNotifications: ProductNotifications,
  ProductNutritionCounseling: ProductNutritionCounseling,
  ProductOnboardAndOffboard: ProductOnboardAndOffboard,
  ProductOrgChart: ProductOrgChart,
  ProductOtherBenefits: ProductOtherBenefits,
  ProductPayrollEuro: ProductPayrollEuro,
  ProductPayrollPound: ProductPayrollPound,
  ProductPayrollRupee: ProductPayrollRupee,
  ProductPayrollUsd: ProductPayrollUsd,
  ProductPayrollYen: ProductPayrollYen,
  ProductPensionManagementEuro: ProductPensionManagementEuro,
  ProductPensionManagementPound: ProductPensionManagementPound,
  ProductPensionManagementRupee: ProductPensionManagementRupee,
  ProductPensionManagementUsd: ProductPensionManagementUsd,
  ProductPensionManagementYen: ProductPensionManagementYen,
  ProductPeo: ProductPeo,
  ProductPeopleDirectory: ProductPeopleDirectory,
  ProductPeoWorkersComp: ProductPeoWorkersComp,
  ProductPermissions: ProductPermissions,
  ProductPetInsurance: ProductPetInsurance,
  ProductPrimaryCare: ProductPrimaryCare,
  ProductPrivate: ProductPrivate,
  ProductProcurement: ProductProcurement,
  ProductProductWebinars: ProductProductWebinars,
  ProductPulse: ProductPulse,
  ProductRecipes: ProductRecipes,
  ProductRecruiting: ProductRecruiting,
  ProductReports: ProductReports,
  ProductReviewCycles: ProductReviewCycles,
  ProductRipplingBrowserExtension: ProductRipplingBrowserExtension,
  ProductRipplingLogo: ProductRipplingLogo,
  ProductRpass: ProductRpass,
  ProductSandbox: ProductSandbox,
  ProductScheduling: ProductScheduling,
  ProductSecondaryLifeInsurance: ProductSecondaryLifeInsurance,
  ProductSecurityTool: ProductSecurityTool,
  ProductShortTermDisability: ProductShortTermDisability,
  ProductSpendManagement: ProductSpendManagement,
  ProductSpoof: ProductSpoof,
  ProductSshKeyManager: ProductSshKeyManager,
  ProductStateTaxAccounts: ProductStateTaxAccounts,
  ProductStudentLoanRepayment: ProductStudentLoanRepayment,
  ProductSupergroupManager: ProductSupergroupManager,
  ProductSwagStore: ProductSwagStore,
  ProductTalentSignal: ProductTalentSignal,
  ProductTalkToSalesEuro: ProductTalkToSalesEuro,
  ProductTalkToSalesPound: ProductTalkToSalesPound,
  ProductTalkToSalesRupee: ProductTalkToSalesRupee,
  ProductTalkToSalesUsd: ProductTalkToSalesUsd,
  ProductTalkToSalesYen: ProductTalkToSalesYen,
  ProductTalkToSomeone: ProductTalkToSomeone,
  ProductTalkToSupport: ProductTalkToSupport,
  ProductTasks: ProductTasks,
  ProductTelemedicine: ProductTelemedicine,
  ProductThinkHr: ProductThinkHr,
  ProductThirdPartyBenefitsAdmin: ProductThirdPartyBenefitsAdmin,
  ProductThirdPartyFlex: ProductThirdPartyFlex,
  ProductTimeAndAttendance: ProductTimeAndAttendance,
  ProductTimeAndAttendanceKiosk: ProductTimeAndAttendanceKiosk,
  ProductTimeOff: ProductTimeOff,
  ProductTransformations: ProductTransformations,
  ProductTravel: ProductTravel,
  ProductTravelInsurance: ProductTravelInsurance,
  ProductUniversalSearch: ProductUniversalSearch,
  ProductVariableCompensation: ProductVariableCompensation,
  ProductVirtualLdap: ProductVirtualLdap,
  ProductVision: ProductVision,
  ProductWallet: ProductWallet,
  ProductWorkersComp: ProductWorkersComp,
  ProductWorkflowAutomator: ProductWorkflowAutomator,
  ProductWorkplaceIssue: ProductWorkplaceIssue,
  ProductWorkplacePosters: ProductWorkplacePosters,
  ProductWorkshops: ProductWorkshops,
  ProvisionUsersFilledIcon: ProvisionUsersFilledIcon,
  ProvisionUsersOutlineIcon: ProvisionUsersOutlineIcon,
  PublicTransportationFilledIcon: PublicTransportationFilledIcon,
  PublicTransportationOutlineIcon: PublicTransportationOutlineIcon,
  PushFilledIcon: PushFilledIcon,
  PushOutlineIcon: PushOutlineIcon,
  QrcodeIcon: QrcodeIcon,
  QuestionCircleFilledIcon: QuestionCircleFilledIcon,
  QuestionCircleOutlineIcon: QuestionCircleOutlineIcon,
  QuoteFilledIcon: QuoteFilledIcon,
  QuoteOutlineIcon: QuoteOutlineIcon,
  ReadinessFilledIcon: ReadinessFilledIcon,
  ReadinessOutlineIcon: ReadinessOutlineIcon,
  ReassignComputerFilledIcon: ReassignComputerFilledIcon,
  ReassignComputerOutlineIcon: ReassignComputerOutlineIcon,
  ReceiptFilledIcon: ReceiptFilledIcon,
  ReceiptOutlineIcon: ReceiptOutlineIcon,
  RecipesFilledIcon: RecipesFilledIcon,
  RecipesOutlineIcon: RecipesOutlineIcon,
  ReclineExtraFilledIcon: ReclineExtraFilledIcon,
  ReclineExtraOutlineIcon: ReclineExtraOutlineIcon,
  ReclineNormalFilledIcon: ReclineNormalFilledIcon,
  ReclineNormalOutlineIcon: ReclineNormalOutlineIcon,
  RecruitingFilledIcon: RecruitingFilledIcon,
  RecruitingOutlineIcon: RecruitingOutlineIcon,
  RedoCrossedIcon: RedoCrossedIcon,
  RedoIcon: RedoIcon,
  RefreshCrossedIcon: RefreshCrossedIcon,
  RefreshIcon: RefreshIcon,
  RemediationFilledIcon: RemediationFilledIcon,
  RemediationOutlineIcon: RemediationOutlineIcon,
  RemoveFilledIcon: RemoveFilledIcon,
  RemoveOutlineIcon: RemoveOutlineIcon,
  ReportIcon: ReportIcon,
  ReportMonitoringFilledIcon: ReportMonitoringFilledIcon,
  ReportMonitoringOutlineIcon: ReportMonitoringOutlineIcon,
  ReportsFilledIcon: ReportsFilledIcon,
  ReportsOutlineIcon: ReportsOutlineIcon,
  RetailDollarFilledIcon: RetailDollarFilledIcon,
  RetailDollarOutlineIcon: RetailDollarOutlineIcon,
  RetailEuroFilledIcon: RetailEuroFilledIcon,
  RetailEuroOutlineIcon: RetailEuroOutlineIcon,
  RetailPoundFilledIcon: RetailPoundFilledIcon,
  RetailPoundOutlineIcon: RetailPoundOutlineIcon,
  RetailRupeeFilledIcon: RetailRupeeFilledIcon,
  RetailRupeeOutlineIcon: RetailRupeeOutlineIcon,
  RetailYenFilledIcon: RetailYenFilledIcon,
  RetailYenOutlineIcon: RetailYenOutlineIcon,
  ReturndateIcon: ReturndateIcon,
  ReturnIcon: ReturnIcon,
  ReviewCyclesIcon: ReviewCyclesIcon,
  Rewind10SecondsIcon: Rewind10SecondsIcon,
  RichtextIcon: RichtextIcon,
  RideshareFilledIcon: RideshareFilledIcon,
  RideshareOutlineIcon: RideshareOutlineIcon,
  RiskFilledIcon: RiskFilledIcon,
  RiskOutlineIcon: RiskOutlineIcon,
  RoiFilledIcon: RoiFilledIcon,
  RoiOutlineIcon: RoiOutlineIcon,
  RpassFilledIcon: RpassFilledIcon,
  RpassOutlineIcon: RpassOutlineIcon,
  RupeeCircleFilledIcon: RupeeCircleFilledIcon,
  RupeeCircleOutlineIcon: RupeeCircleOutlineIcon,
  RupeeIcon: RupeeIcon,
  SalesFilledIcon: SalesFilledIcon,
  SalesOutlineIcon: SalesOutlineIcon,
  SandboxFilledIcon: SandboxFilledIcon,
  SandboxOutlineIcon: SandboxOutlineIcon,
  SatisfiedFilledIcon: SatisfiedFilledIcon,
  SatisfiedOutlineIcon: SatisfiedOutlineIcon,
  SaveAndExitFilledIcon: SaveAndExitFilledIcon,
  SaveAndExitOutlineIcon: SaveAndExitOutlineIcon,
  ScaleFilledIcon: ScaleFilledIcon,
  ScaleOutlineIcon: ScaleOutlineIcon,
  ScatterChartIcon: ScatterChartIcon,
  SearchIcon: SearchIcon,
  ServerFilledIcon: ServerFilledIcon,
  ServerOutlineIcon: ServerOutlineIcon,
  SettingsFilledIcon: SettingsFilledIcon,
  SettingsOutlineIcon: SettingsOutlineIcon,
  SharedFilledIcon: SharedFilledIcon,
  SharedOutlineIcon: SharedOutlineIcon,
  ShareFilledIcon: ShareFilledIcon,
  ShareOutlineIcon: ShareOutlineIcon,
  ShieldDisabledFilledIcon: ShieldDisabledFilledIcon,
  ShieldDisabledOutlineIcon: ShieldDisabledOutlineIcon,
  ShieldFilledIcon: ShieldFilledIcon,
  ShieldOutlineIcon: ShieldOutlineIcon,
  ShortAnswerIcon: ShortAnswerIcon,
  ShowFilledIcon: ShowFilledIcon,
  ShowOutlineIcon: ShowOutlineIcon,
  SignatureFilledIcon: SignatureFilledIcon,
  SignatureOutlineIcon: SignatureOutlineIcon,
  SkipIcon: SkipIcon,
  SkipLogicFilledIcon: SkipLogicFilledIcon,
  SkipLogicOutlineIcon: SkipLogicOutlineIcon,
  SortAscendingIcon: SortAscendingIcon,
  SortDescendingIcon: SortDescendingIcon,
  SortIcon: SortIcon,
  SoundFilledIcon: SoundFilledIcon,
  SoundOutlineIcon: SoundOutlineIcon,
  SpellCheckIcon: SpellCheckIcon,
  StackedcoinsFilledIcon: StackedcoinsFilledIcon,
  StackedcoinsOutlineIcon: StackedcoinsOutlineIcon,
  StackedVChartFilledIcon: StackedVChartFilledIcon,
  StackedVChartOutlineIcon: StackedVChartOutlineIcon,
  StairsIcon: StairsIcon,
  StarCircleFilledIcon: StarCircleFilledIcon,
  StarCircleOutlineIcon: StarCircleOutlineIcon,
  StarEmptyIcon: StarEmptyIcon,
  StarFillIcon: StarFillIcon,
  StarHalfIcon: StarHalfIcon,
  StartupsFilledIcon: StartupsFilledIcon,
  StartupsOutlineIcon: StartupsOutlineIcon,
  StopatairportFilledIcon: StopatairportFilledIcon,
  StopatairportOutlineIcon: StopatairportOutlineIcon,
  Stretch1Icon: Stretch1Icon,
  Stretch2Icon: Stretch2Icon,
  StrikethroughIcon: StrikethroughIcon,
  StrongNoIcon: StrongNoIcon,
  StrongYesIcon: StrongYesIcon,
  SumFilledIcon: SumFilledIcon,
  SumOutlineIcon: SumOutlineIcon,
  SunFilledIcon: SunFilledIcon,
  SunOutlineIcon: SunOutlineIcon,
  SwapIcon: SwapIcon,
  SwissFrancIcon: SwissFrancIcon,
  TableColumnFilledIcon: TableColumnFilledIcon,
  TableColumnOutlineIcon: TableColumnOutlineIcon,
  TableIcon: TableIcon,
  TalentFilledIcon: TalentFilledIcon,
  TalentOutlineIcon: TalentOutlineIcon,
  TalentSignalFilledIcon: TalentSignalFilledIcon,
  TalentSignalOutlineIcon: TalentSignalOutlineIcon,
  TaskFilledIcon: TaskFilledIcon,
  TaskOutlineIcon: TaskOutlineIcon,
  TasksFilledIcon: TasksFilledIcon,
  TasksOutlineIcon: TasksOutlineIcon,
  TaxesFilledIcon: TaxesFilledIcon,
  TaxesOutlineIcon: TaxesOutlineIcon,
  TaxWithholdingFilledIcon: TaxWithholdingFilledIcon,
  TaxWithholdingOutlineIcon: TaxWithholdingOutlineIcon,
  TechnologyFilledIcon: TechnologyFilledIcon,
  TechnologyOutlineIcon: TechnologyOutlineIcon,
  ThaiBahtIcon: ThaiBahtIcon,
  ThirdPartyDataIcon: ThirdPartyDataIcon,
  ThumbsDownFilledIcon: ThumbsDownFilledIcon,
  ThumbsDownOutlineIcon: ThumbsDownOutlineIcon,
  ThumbsUpFilledIcon: ThumbsUpFilledIcon,
  ThumbsUpOutlineIcon: ThumbsUpOutlineIcon,
  ThumbtackFilledIcon: ThumbtackFilledIcon,
  ThumbtackOutlineIcon: ThumbtackOutlineIcon,
  ThunderboltFilledIcon: ThunderboltFilledIcon,
  ThunderboltOutlineIcon: ThunderboltOutlineIcon,
  TimeFilledIcon: TimeFilledIcon,
  TimeOutlineIcon: TimeOutlineIcon,
  ToiletFilledIcon: ToiletFilledIcon,
  ToiletOutlineIcon: ToiletOutlineIcon,
  ToolsFilledIcon: ToolsFilledIcon,
  ToolsOutlineIcon: ToolsOutlineIcon,
  TranslateIcon: TranslateIcon,
  TrashFilledIcon: TrashFilledIcon,
  TrashOutlineIcon: TrashOutlineIcon,
  TravelFilledIcon: TravelFilledIcon,
  TravelOutlineIcon: TravelOutlineIcon,
  TrendReportFilledIcon: TrendReportFilledIcon,
  TrendReportOutlineIcon: TrendReportOutlineIcon,
  TripIcon: TripIcon,
  TrophyIcon: TrophyIcon,
  TwoFactorDevicesFilledIcon: TwoFactorDevicesFilledIcon,
  TwoFactorDevicesOutlineIcon: TwoFactorDevicesOutlineIcon,
  UnarchiveFilledIcon: UnarchiveFilledIcon,
  UnarchiveOutlineIcon: UnarchiveOutlineIcon,
  UnassignComputerFilledIcon: UnassignComputerFilledIcon,
  UnassignComputerOutlineIcon: UnassignComputerOutlineIcon,
  UnderlineIcon: UnderlineIcon,
  UndoIcon: UndoIcon,
  UnifiedSystemFilledIcon: UnifiedSystemFilledIcon,
  UnifiedSystemOutlineIcon: UnifiedSystemOutlineIcon,
  UninstallFilledIcon: UninstallFilledIcon,
  UninstallOutlineIcon: UninstallOutlineIcon,
  UniqueIdFilledIcon: UniqueIdFilledIcon,
  UniqueIdOutlineIcon: UniqueIdOutlineIcon,
  UnlimitedPtoFilledIcon: UnlimitedPtoFilledIcon,
  UnlimitedPtoOutlineIcon: UnlimitedPtoOutlineIcon,
  UnlockFilledIcon: UnlockFilledIcon,
  UnlockOutlineIcon: UnlockOutlineIcon,
  UpdateAddressIcon: UpdateAddressIcon,
  UploadIcon: UploadIcon,
  UsercircleplusFilledIcon: UsercircleplusFilledIcon,
  UsercircleplusOutlineIcon: UsercircleplusOutlineIcon,
  UserCogFilledIcon: UserCogFilledIcon,
  UserCogOutlineIcon: UserCogOutlineIcon,
  UserDepartmentFilledIcon: UserDepartmentFilledIcon,
  UserDepartmentOutlineIcon: UserDepartmentOutlineIcon,
  UserFileFilledIcon: UserFileFilledIcon,
  UserFileOutlineIcon: UserFileOutlineIcon,
  UserFilledIcon: UserFilledIcon,
  UserLaptopFilledIcon: UserLaptopFilledIcon,
  UserLaptopOutlineIcon: UserLaptopOutlineIcon,
  UserOutlineIcon: UserOutlineIcon,
  UsersFilledIcon: UsersFilledIcon,
  UsersOutlineIcon: UsersOutlineIcon,
  UserticketFilledIcon: UserticketFilledIcon,
  UserticketOutlineIcon: UserticketOutlineIcon,
  UShieldFilledIcon: UShieldFilledIcon,
  UShieldOutlineIcon: UShieldOutlineIcon,
  VariableIcon: VariableIcon,
  VaultFilledIcon: VaultFilledIcon,
  VaultOutlineIcon: VaultOutlineIcon,
  VendorFilledIcon: VendorFilledIcon,
  VendorOutlineIcon: VendorOutlineIcon,
  VennDiagramAndFilledIcon: VennDiagramAndFilledIcon,
  VennDiagramAndOutlineIcon: VennDiagramAndOutlineIcon,
  VennDiagramOrFilledIcon: VennDiagramOrFilledIcon,
  VennDiagramOrOutlineIcon: VennDiagramOrOutlineIcon,
  VerifiedFilledIcon: VerifiedFilledIcon,
  VerifiedOutlineIcon: VerifiedOutlineIcon,
  VeryDissatisfiedFilledIcon: VeryDissatisfiedFilledIcon,
  VeryDissatisfiedOutlineIcon: VeryDissatisfiedOutlineIcon,
  VerySatisfiedFilledIcon: VerySatisfiedFilledIcon,
  VerySatisfiedOutlineIcon: VerySatisfiedOutlineIcon,
  VideoFilledIcon: VideoFilledIcon,
  VideoOutlineIcon: VideoOutlineIcon,
  VpnFilledIcon: VpnFilledIcon,
  VpnOutlineIcon: VpnOutlineIcon,
  WarningCircleFilledIcon: WarningCircleFilledIcon,
  WarningCircleOutlineIcon: WarningCircleOutlineIcon,
  WarningTriangleFilledIcon: WarningTriangleFilledIcon,
  WarningTriangleOutlineIcon: WarningTriangleOutlineIcon,
  WellnessAndMeditationClassesFilledIcon: WellnessAndMeditationClassesFilledIcon,
  WellnessAndMeditationClassesOutlineIcon: WellnessAndMeditationClassesOutlineIcon,
  WifiCrossedIcon: WifiCrossedIcon,
  WifiIcon: WifiIcon,
  WindowpopoutoutlineFilledIcon: WindowpopoutoutlineFilledIcon,
  WindowpopoutoutlineOutlineIcon: WindowpopoutoutlineOutlineIcon,
  WineClubFilledIcon: WineClubFilledIcon,
  WineClubOutlineIcon: WineClubOutlineIcon,
  WithdrawFilledIcon: WithdrawFilledIcon,
  WithdrawOutlineIcon: WithdrawOutlineIcon,
  WorkFromHomeStipendFilledIcon: WorkFromHomeStipendFilledIcon,
  WorkFromHomeStipendOutlineIcon: WorkFromHomeStipendOutlineIcon,
  WrenchFilledIcon: WrenchFilledIcon,
  WrenchOutlineIcon: WrenchOutlineIcon,
  XmlFilledIcon: XmlFilledIcon,
  XmlOutlineIcon: XmlOutlineIcon,
  YenCircleFilledIcon: YenCircleFilledIcon,
  YenCircleOutlineIcon: YenCircleOutlineIcon,
  YenIcon: YenIcon,
  ZipFilledIcon: ZipFilledIcon,
  ZipOutlineIcon: ZipOutlineIcon,
  ZoomInFilledIcon: ZoomInFilledIcon,
  ZoomInOutlineIcon: ZoomInOutlineIcon,
  ZoomOutFilledIcon: ZoomOutFilledIcon,
  ZoomOutOutlineIcon: ZoomOutOutlineIcon,
};

export default ICONS;
