import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { useCookieInit } from ':hooks/useCookieInit';
import useQueryParam from ':hooks/useQueryParam';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import UnavailableBanner from '@rippling/sava-ui/src/components/atoms/UnavailableBanner';
import FooterCondensed from '@rippling/sava-ui/src/components/molecules/FooterCondensed';
import SavaProvider from '@rippling/sava-ui/src/providers/SavaProvider';
import {
  BaseCfComponent, CfLocale, CfPageMetadata, FooterStyle, HeaderStyle
} from '@rippling/sava-ui/src/types';
import { isNotNull } from '@rippling/utils';
import UserContext from 'context/UserContext';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { VERCEL_ENV } from ':constants/env';
import { submitForm } from ':helpers/form-helpers';
import { type BasePageProps } from ':types/page';

import AdminTool from './AdminTool';
import Head, { type LocalePath } from './Head';
import Footer from './partials/Footer';
import Header, { getProductSuiteLogo } from './partials/Header';

export interface LandingPageProps extends BasePageProps {
  data: {
    fields: {
      content: (BaseCfComponent & Record<string, unknown>)[]
      pageMetadata: CfPageMetadata;
    }
    urlPath: null | string;
  } & BaseCfComponent,
  draftMode: boolean;
  enableLivePreview: boolean;
  fromContentful: boolean;
  localePaths: LocalePath[];
}

// Definition of all async components
const asyncComponents = {
  uiAccoladesSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/AccoladesSection')),
  uiCardsSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/Cards')),
  uiCenteredHighlightSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/CenteredHighlight')),
  uiCtaSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/CtaSection')),
  uiGridListSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/GridListSection')),
  uiHeroSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/Hero')),
  uiListSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/ListSection')),
  uiOverviewSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/Overview')),
  uiPlatformSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/PlatformSection')),
  uiProductOverviewSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/ProductOverview')),
  uiRiverFlowSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/RiverFlow')),
  uiStatsSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/Stats')),
  uiTable: dynamic(() => import('@rippling/sava-ui/src/components/organisms/TableSection')),
  uiTabletCarousel: dynamic(() => import('@rippling/sava-ui/src/components/organisms/TabletCarousel')),
  uiTabs: dynamic(() => import('@rippling/sava-ui/src/components/organisms/TabsSection')),
  uiTestimonialSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/TestimonialsSection')),
  uiTextSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/TextSection')),
  uiTimelineSection: dynamic(() => import('@rippling/sava-ui/src/components/organisms/Timeline')),
};

const LocaleBanner = ({ locale, localeBanner }: {
  locale: string,
  localeBanner: CfLocale[]
}) => {
  const localeData = localeBanner.find((banner) => banner.fields.languageCode === locale);

  if (!localeData) {
    return null;
  }

  return <UnavailableBanner locale={localeData} />;
};

const LandingPage = ({
  data,
  draftMode,
  enableLivePreview,
  globals,
  locale,
  localePaths,
  prodCssFiles,
}: LandingPageProps) => {
  const [debug] = useQueryParam('debug');
  const [debugMode, setDebugMode] = useState(false);
  const router = useRouter();
  const { googleClientId, rtlid } = useContext(UserContext) ?? {};

  const {
    fields: { content, pageMetadata }, sys, urlPath,
  } = data;

  const pathWithoutLocale = (urlPath ?? '').replace(`/${locale}`, '');

  const {
    footerOptions,
    headerOptions,
    hideLocaleSelector,
    isIndexed,
    localeBanner = [],
    publishedLocales,
    seoDescription = '',
    seoTitle = '',
  } = useMemo(() => {
    if (!pageMetadata) {
      return { publishedLocales: [] };
    }

    const {
      footerStyle = FooterStyle.Default,
      headerStyle = HeaderStyle.Default,
      hideDemoButton = false,
      hideLocaleSelector = false,
      hideLoginButton = false,
      isIndexed = false,
      localeBanner = [],
      publishedLocales = [],
      seoDescription,
      seoTitle,
    } = pageMetadata.fields;

    const getHeaderOptions = () => {
      if (headerStyle === HeaderStyle.Hidden) {
        return { header: false };
      }

      const buttonOptions = [!hideDemoButton && 'demo', !hideLoginButton && 'login'].filter(Boolean);

      return {
        button: buttonOptions,
        header: true,
        hideLocaleSelector,
        nav: headerStyle === HeaderStyle.Default,
        productSuiteLogo: getProductSuiteLogo(headerStyle),
      };
    };

    const getFooterOptions = () => {
      if (footerStyle === FooterStyle.Hidden) {
        return 'No Footer';
      }

      if (footerStyle === FooterStyle.Condensed) {
        return 'Condensed';
      }

      return 'Default';
    };

    return {
      footerOptions: getFooterOptions(),
      headerOptions: getHeaderOptions(),
      hideLocaleSelector,
      isIndexed: !!isIndexed,
      localeBanner,
      publishedLocales: publishedLocales.map(({ fields: { languageCode } }) => {
        return localePaths.find(({ locale }) => locale === languageCode);
      }).filter(isNotNull),
      seoDescription: seoDescription,
      seoTitle: seoTitle,
    };
  }, [pageMetadata, localePaths]);

  const renderableContent = useMemo(() => {
    return content.filter((section) => {
      return asyncComponents[section.sys.contentType.sys.id];
    });
  }, [content]);

  const updatedContent = enableLivePreview && draftMode
    ? useContentfulLiveUpdates(renderableContent)
    : renderableContent;

  const redirect = useCallback(
    (url: string) => router.push(url),
    [router]
  );

  useCookieInit();

  // To prevent hydration error (server vs client, we use state)
  useEffect(() => {
    setDebugMode(debug === 'true');
  }, [debugMode]);

  const isFooterCondensed = footerOptions === FooterStyle.Condensed;
  const {
    footer, footerCondensed, header, strings,
  } = globals;

  return (
    <SavaProvider
      cfSpaceId={process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}
      debugMode={debugMode}
      env={VERCEL_ENV}
      googleClientId={googleClientId}
      linkComponent={Link}
      redirect={redirect}
      rtlid={rtlid}
      strings={strings}
      submitForm={submitForm}
    >
      <Head
        description={seoDescription}
        isIndexed={!!isIndexed}
        locale={locale}
        path={pathWithoutLocale}
        prodCssFile={prodCssFiles.savaPath}
        publishedLocalePaths={publishedLocales}
        title={seoTitle}
      />
      <LocaleBanner locale={locale} localeBanner={localeBanner} />
      <Header
        {...header}
        fromContentful
        hideLocaleSelector={hideLocaleSelector}
        isMiniItSite={false}
        isMiniSite={false}
        locale={locale}
        localePaths={localePaths}
        options={headerOptions}
      />
      <AdminTool
        draftMode={draftMode}
        locale={locale}
        localePaths={localePaths}
        sys={sys}
      >
        {updatedContent.map((props) => {
          const { contentType: { sys: { id: contentType } }, id } = props.sys;
          const Component = asyncComponents[contentType];

          return <Component {...props} key={id} />;
        })}
      </AdminTool>

      {isFooterCondensed
        ? <FooterCondensed data={footerCondensed.fields.data} />
        : <Footer {...footer} options={footerOptions} />}
    </SavaProvider>
  );
};

export default memo(LandingPage);
