import { getCleanedUrl } from '@rippling/utils/urlUtils';
import { twMerge } from 'tailwind-merge';
import { useToggle } from 'usehooks-ts';

import useStringText from '../../hooks/useStringText';
import { IconName } from '../../icons';
import { CfLocale } from '../../types';
import Icon from '../Icon/Icon';
import Link from './Link';

export type UnavailableBannerProps = {
  className?: string;
  locale: CfLocale;
};

const UnavailableBanner = ({ className, locale }: UnavailableBannerProps) => {
  const [isHidden, toggleIsHidden] = useToggle(false);

  const countryName = locale.fields.addPrefixThe ? `the ${locale.fields.country}` : locale.fields.country;

  const localeBannerText = useStringText('unavailable_banner_announcement').replace('%s', countryName);
  const ctaText = useStringText('Unavailable_banner_cta').replace('%s', locale.fields.languageCode?.split('-')[1]);

  return (
    <div
      className={twMerge(
        'px-6 py-4 flex justify-center items-center gap-sm bg-surface-l1 sticky top-0 z-[7000]',
        isHidden && 'hidden',
        className
      )}
    >
      <div className="flex gap-2xs items-center py-[7px]">
        <Icon className="w-6 h-6 justify-self-center fill-white" name={IconName.GlobeOutlineIcon} />
        <span className="text-text-primary text-p2">{localeBannerText}</span>
      </div>

      <Link
        className="after:bg-surface-l1"
        href={getCleanedUrl(`/${locale?.fields?.languageCode || ''}`)}
      >
        {ctaText}
      </Link>
      <button
        className="w-5 h-5 absolute right-11"
        onClick={toggleIsHidden}
      >
        <Icon className="fill-icon-inactive" name={IconName.CloseIcon} />
      </button>
    </div>
  );
};

export default UnavailableBanner;
