import clsx from 'clsx';

import { IconName } from '../../icons';
import Icon from '../Icon/Icon';

export interface AlertProps {
  message: string;
  className?: string;
  variant?: 'error' | 'info' | 'success' | 'warning';
}

enum IconStatus {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}

const iconNameMap = {
  [IconStatus.ERROR]: IconName.CloseCircleFilledIcon,
  [IconStatus.INFO]: IconName.InfoFilledIcon,
  [IconStatus.SUCCESS]: IconName.CheckCircleFilledIcon,
  [IconStatus.WARNING]: IconName.WarningCircleFilledIcon,
};

export const Alert = ({
  className,
  message,
  variant,
}: AlertProps) => {
  const iconName = iconNameMap[variant ?? IconStatus.SUCCESS];

  return (
    <div
      className={clsx(['flex items-center justify-center gap-2 p-5 rounded-md bg-white-100 border border-grey-200', className])}
    >
      <Icon
        className={clsx('w-5 h5', {
          'fill-icon-error': variant === 'error',
          'fill-icon-success': variant === 'success',
          'fill-surface-l2': variant === 'info',
          'fill-yellow': variant === 'warning',
        })}
        name={iconName}
      />
      <p className="font-normal text-plum-800 text-p2">{message}</p>
    </div>
  );
};
