import { useContext, useMemo } from 'react';

import { isNotNull } from '@rippling/utils';

import { SavaContext } from '../providers/SavaProvider';

const useStringText = (key: string, defaultValue: null | string = null) => {
  const contextValue = useContext(SavaContext);

  const useDefaultValue = useMemo(() => {
    if (isNotNull(defaultValue)) {
      return defaultValue;
    }

    const words = key.split('_').filter(Boolean).join(' ');

    return words.charAt(0).toUpperCase() + words.slice(1);
  }, [defaultValue, key]);

  if (!contextValue?.strings) {
    return useDefaultValue;
  }

  return contextValue.strings[key] || useDefaultValue;
};

export default useStringText;
