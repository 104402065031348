import {
  ComponentType, HTMLAttributes, MouseEvent, ReactNode, useContext,
  useMemo
} from 'react';

import { GAEventName, sendGAEvent } from '@rippling/utils/gaUtils';
import { getCleanedUrl } from '@rippling/utils/urlUtils';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { SavaContext } from '../../providers/SavaProvider';
import { CfLink } from '../../types';

export interface LinkProps extends HTMLAttributes<HTMLAnchorElement> {
  cfLinkData?: CfLink;
  children?: ReactNode;
  className?: string;

  // Disable the default style (yellow underline)
  disableDefaultStyle?: boolean;

  href?: string;
  newTab?: boolean;
}

const isInternalLink = (href: string) => {
  return href?.startsWith('/') || href?.startsWith('#');
};

const getDefaultClassName = ({ disableDefaultStyle }: Pick<LinkProps, 'disableDefaultStyle'>) => {
  if (disableDefaultStyle) {
    return null;
  }

  return clsx('text-p2 font-medium text-text-primary relative sava-line-draw');
};

const Link = ({
  cfLinkData,
  children,
  className: originalClassName,
  disableDefaultStyle = false,
  href: propHref,
  newTab: propNewTab,
  onClick,
  ...props
}: LinkProps) => {
  const { href, newTab } = useMemo(() => {
    const hrefToUse = cfLinkData?.fields.internalUrl ?? cfLinkData?.fields.url ?? propHref;

    return {
      href: getCleanedUrl(hrefToUse ?? ''),
      newTab: cfLinkData?.fields.newTab ?? propNewTab,
    };
  }, [cfLinkData, propHref]);

  const contextValue = useContext(SavaContext);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let Component: ComponentType<any> | string = 'a';

  if (contextValue?.linkComponent) {
    Component = contextValue.linkComponent;
  }

  // If newTab === undefined, will default to true if it's an external link
  const openInNewTab = href && newTab === undefined ? !isInternalLink(href) : !!newTab;

  const onClickWithEvent = (event: MouseEvent<HTMLAnchorElement>) => {
    sendGAEvent({
      click_url: href,
      event: GAEventName.ButtonClick,
    });
    onClick?.(event);
  };

  return (
    <Component
      {...props}
      className={twMerge(
        getDefaultClassName({ disableDefaultStyle }),
        originalClassName
      )}
      href={href}
      onClick={onClickWithEvent}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
      target={openInNewTab ? '_blank' : undefined}
    >
      {children ?? cfLinkData?.fields.text}
    </Component>
  );
};

export default Link;
