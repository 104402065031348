import {
  ChangeEvent,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  useContext,
  useState
} from 'react';

import { Alert } from '@rippling/sava-ui/src/components/atoms/Alert';
import Button from '@rippling/sava-ui/src/components/atoms/Button';
import Link from '@rippling/sava-ui/src/components/atoms/Link';
import { ProductRipplingLogo } from '@rippling/sava-ui/src/icons';
import { SavaContext } from '@rippling/sava-ui/src/providers/SavaProvider';
import { CfSys } from '@rippling/sava-ui/src/types';
import { getEntryUrl } from '@rippling/sava-ui/src/utils/contentful';
import useKeyboard from '@rippling/utils/hooks/useKeyboard';
import { Formik } from 'formik';
import ky from 'ky';
import { useToggle } from 'usehooks-ts';

import { LocalePath } from './Head';

import css from './AdminTool.module.scss';

interface Props {
  children: ReactNode;
  draftMode: boolean;
  locale: string;
  localePaths: LocalePath[];
  sys: CfSys;
}

export interface CfAdminToolFormValues {
  pageLocale: string;
  revalidateAllLocales: boolean,
  revalidationSecret: boolean;
  secret?: string;
}

interface ModalProps {
  locale: string;
  localePaths: LocalePath[];
  onBlur: (e: FocusEvent<HTMLInputElement>) => void;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onHide: () => void;
  sys: CfSys;
  values: CfAdminToolFormValues;
}

const revalidatePath = async ({
  allLocales,
  locale,
  localePaths,
  secret,
}: {
  allLocales: boolean,
  locale: string;
  localePaths: LocalePath[],
  secret?: string
}) => {
  const paths = localePaths.filter((localePath) => {
    return allLocales || locale === localePath.locale;
  }).map(({ path }) => path);

  await ky.post('/api/www-vercel-revalidate', {
    json: {
      adminResource: 'production',
      paths,
      secret,
      version: 'new',
    },
  });
};

const Input = ({
  id,
  label,
  name,
  ...props
}: {
  label: string;
  name: string;
} & InputHTMLAttributes<HTMLInputElement> & Pick<ModalProps, 'onBlur' | 'onChange'>) => {
  const useId = id ?? name;

  return (
    <div className={css.checkbox}>
      <input
        id={useId}
        name={name}
        {...props}
      />
      <label htmlFor={useId}>
        <span>{label}</span>
      </label>
    </div>
  );
};

const Modal = ({
  locale,
  localePaths,
  onHide,
  sys,
  values,
  ...props
}: ModalProps) => {
  const { cfSpaceId: spaceId } = useContext(SavaContext) ?? {};

  const [isProcessing, setIsProcessing] = useState(false);
  const [alert, setAlert] = useState<{
    message: string,
    variant: 'error' | 'success'
  } | null>(null);
  const {
    revalidateAllLocales,
    revalidationSecret,
    secret,
  } = values;

  const revalidate = async () => {
    setIsProcessing(true);
    setAlert(null);

    try {
      await revalidatePath({
        allLocales: revalidateAllLocales,
        locale,
        localePaths,
        secret: revalidationSecret ? secret : undefined,
      });

      setAlert({
        message: 'The page has been revalidated',
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      setAlert({
        message: 'Failed to revalidate the page',
        variant: 'error',
      });
    }

    setIsProcessing(false);
  };

  useKeyboard({
    enabled: true,
    key: 'Escape',
    onPress: onHide,
  });

  return (
    <div className={css.modal}>
      <div className={css.overlay} onClick={onHide} />
      <div className={css.modalContent}>
        {!!alert && <Alert className={css.alert} {...alert} />}
        <div className={css.revalidationSection}>
          <h3>Revalidation</h3>
          <Input
            {...props}
            checked={revalidateAllLocales}
            label="All locales"
            name="revalidateAllLocales"
            type="checkbox"
          />
          <Input
            {...props}
            checked={revalidationSecret}
            label="Revalidation Secret (optional)"
            name="revalidationSecret"
            type="checkbox"
          />
          {revalidationSecret && (
            <input
              {...props}
              autoFocus
              name="secret"
              placeholder="Revalidation Secret"
              type="password"
              value={secret}
            />
          )}
        </div>
        <Button disabled={isProcessing} onClick={revalidate}>Revalidate Page</Button>
        {spaceId && (
          <Link
            className={css.link}
            disableDefaultStyle
            href={getEntryUrl({
              entryId: sys.id,
              spaceId,
            })}
          >
            Go to Contentful
          </Link>
        )}
      </div>
    </div>
  );
};

const AdminTool = ({
  children,
  draftMode,
  locale,
  localePaths,
  sys,
}: Props) => {
  const {
    cfSpaceId, debugMode, isProd,
  } = useContext(SavaContext) ?? {};

  if (!cfSpaceId || isProd || !draftMode && !debugMode) {
    return children;
  }

  const [isModalVisible, setModalVisible] = useToggle(false);

  return (
    <Formik
      initialValues={{
        pageLocale: locale,
        revalidateAllLocales: true,
        revalidationSecret: false,
      }}
      onSubmit={() => {
        // Do nothing here
      }}
    >
      {({
        handleBlur, handleChange, values,
      }) => {
        return (
          <>
            {children}
            <div className={css.logo} onClick={setModalVisible}>
              <ProductRipplingLogo />
            </div>
            {isModalVisible && (
              <Modal
                locale={locale}
                localePaths={localePaths}
                onBlur={handleBlur}
                onChange={handleChange}
                onHide={setModalVisible}
                sys={sys}
                values={values}
              />
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AdminTool;
