export enum IconName {
  APIFilledIcon = 'APIFilledIcon',
  APIOutlineIcon = 'APIOutlineIcon',
  AccessibilityFilledIcon = 'AccessibilityFilledIcon',
  AccessibilityOutlineIcon = 'AccessibilityOutlineIcon',
  AccountantFilledIcon = 'AccountantFilledIcon',
  AccountantOutlineIcon = 'AccountantOutlineIcon',
  AddCircleFilledIcon = 'AddCircleFilledIcon',
  AddCircleOutlineIcon = 'AddCircleOutlineIcon',
  AddCommentFilledIcon = 'AddCommentFilledIcon',
  AddCommentOutlineIcon = 'AddCommentOutlineIcon',
  AddNotificationFilledIcon = 'AddNotificationFilledIcon',
  AddNotificationOutlineIcon = 'AddNotificationOutlineIcon',
  AddIcon = 'AddIcon',
  AdditionalInfoFilledIcon = 'AdditionalInfoFilledIcon',
  AdditionalInfoOutlineIcon = 'AdditionalInfoOutlineIcon',
  AirlineStopsFilledIcon = 'AirlineStopsFilledIcon',
  AirlineStopsOutlineIcon = 'AirlineStopsOutlineIcon',
  AirlinesFilledIcon = 'AirlinesFilledIcon',
  AirlinesOutlineIcon = 'AirlinesOutlineIcon',
  AlignCenterIcon = 'AlignCenterIcon',
  AlignJustifyIcon = 'AlignJustifyIcon',
  AlignLeftIcon = 'AlignLeftIcon',
  AlignRightIcon = 'AlignRightIcon',
  AppHeaderIcon = 'AppHeaderIcon',
  AppManagementFilledIcon = 'AppManagementFilledIcon',
  AppManagementOutlineIcon = 'AppManagementOutlineIcon',
  AppShopFilledIcon = 'AppShopFilledIcon',
  AppShopOutlineIcon = 'AppShopOutlineIcon',
  ApprovalFilledIcon = 'ApprovalFilledIcon',
  ApprovalOutlineIcon = 'ApprovalOutlineIcon',
  ApproveRejectPolicyFilledIcon = 'ApproveRejectPolicyFilledIcon',
  ApproveRejectPolicyOutlineIcon = 'ApproveRejectPolicyOutlineIcon',
  ArchiveFilledIcon = 'ArchiveFilledIcon',
  ArchiveOutlineIcon = 'ArchiveOutlineIcon',
  ArrowDownRightIcon = 'ArrowDownRightIcon',
  ArrowDownIcon = 'ArrowDownIcon',
  ArrowDropdownDownIcon = 'ArrowDropdownDownIcon',
  ArrowDropdownLeftIcon = 'ArrowDropdownLeftIcon',
  ArrowDropdownRightIcon = 'ArrowDropdownRightIcon',
  ArrowDropdownUpIcon = 'ArrowDropdownUpIcon',
  ArrowLeftIcon = 'ArrowLeftIcon',
  ArrowRightIcon = 'ArrowRightIcon',
  ArrowUpIcon = 'ArrowUpIcon',
  ArrowupRightBoxIcon = 'ArrowupRightBoxIcon',
  ArrowupRightIcon = 'ArrowupRightIcon',
  AttachIcon = 'AttachIcon',
  AuditObservationFilledIcon = 'AuditObservationFilledIcon',
  AuditObservationOutlineIcon = 'AuditObservationOutlineIcon',
  AuthenticatorFilledIcon = 'AuthenticatorFilledIcon',
  AuthenticatorOutlineIcon = 'AuthenticatorOutlineIcon',
  AutoFilledIcon = 'AutoFilledIcon',
  AutoOutlineIcon = 'AutoOutlineIcon',
  AutomatedComplianceFilledIcon = 'AutomatedComplianceFilledIcon',
  AutomatedComplianceOutlineIcon = 'AutomatedComplianceOutlineIcon',
  BagsFilledIcon = 'BagsFilledIcon',
  BagsOutlineIcon = 'BagsOutlineIcon',
  BandagedfootFilledIcon = 'BandagedfootFilledIcon',
  BandagedfootOutlineIcon = 'BandagedfootOutlineIcon',
  BankFilledIcon = 'BankFilledIcon',
  BankOutlineIcon = 'BankOutlineIcon',
  BarchartoutlineFilledIcon = 'BarchartoutlineFilledIcon',
  BarchartoutlineOutlineIcon = 'BarchartoutlineOutlineIcon',
  BentoBoxIcon = 'BentoBoxIcon',
  BlogsFilledIcon = 'BlogsFilledIcon',
  BlogsOutlineIcon = 'BlogsOutlineIcon',
  BookmarkFilledIcon = 'BookmarkFilledIcon',
  BookmarkOutlineIcon = 'BookmarkOutlineIcon',
  BooksFilledIcon = 'BooksFilledIcon',
  BooksOutlineIcon = 'BooksOutlineIcon',
  BrazilianRealIcon = 'BrazilianRealIcon',
  BriefcaseFilledIcon = 'BriefcaseFilledIcon',
  BriefcaseOutlineIcon = 'BriefcaseOutlineIcon',
  BudgetDollarFilledIcon = 'BudgetDollarFilledIcon',
  BudgetDollarOutlineIcon = 'BudgetDollarOutlineIcon',
  BudgetEuroFilledIcon = 'BudgetEuroFilledIcon',
  BudgetEuroOutlineIcon = 'BudgetEuroOutlineIcon',
  BudgetPoundFilledIcon = 'BudgetPoundFilledIcon',
  BudgetPoundOutlineIcon = 'BudgetPoundOutlineIcon',
  BudgetRupeeFilledIcon = 'BudgetRupeeFilledIcon',
  BudgetRupeeOutlineIcon = 'BudgetRupeeOutlineIcon',
  BudgetYenFilledIcon = 'BudgetYenFilledIcon',
  BudgetYenOutlineIcon = 'BudgetYenOutlineIcon',
  ButtonIcon = 'ButtonIcon',
  CabinClassUpgradeIcon = 'CabinClassUpgradeIcon',
  CabinClassIcon = 'CabinClassIcon',
  CalendarFilledIcon = 'CalendarFilledIcon',
  CalendarOutlineIcon = 'CalendarOutlineIcon',
  CameraFilledIcon = 'CameraFilledIcon',
  CameraOutlineIcon = 'CameraOutlineIcon',
  CancelFilledIcon = 'CancelFilledIcon',
  CancelOutlineIcon = 'CancelOutlineIcon',
  CaretDownIcon = 'CaretDownIcon',
  CaretLeftIcon = 'CaretLeftIcon',
  CaretRightIcon = 'CaretRightIcon',
  CaretUpIcon = 'CaretUpIcon',
  CarryOnBagFilledIcon = 'CarryOnBagFilledIcon',
  CarryOnBagOutlineIcon = 'CarryOnBagOutlineIcon',
  CertificateFilledIcon = 'CertificateFilledIcon',
  CertificateOutlineIcon = 'CertificateOutlineIcon',
  ChangeCommuterBenefitsFilledIcon = 'ChangeCommuterBenefitsFilledIcon',
  ChangeCommuterBenefitsOutlineIcon = 'ChangeCommuterBenefitsOutlineIcon',
  ChangeFsaDollarFilledIcon = 'ChangeFsaDollarFilledIcon',
  ChangeFsaDollarOutlineIcon = 'ChangeFsaDollarOutlineIcon',
  ChangeFsaEuroFilledIcon = 'ChangeFsaEuroFilledIcon',
  ChangeFsaEuroOutlineIcon = 'ChangeFsaEuroOutlineIcon',
  ChangeFsaPoundFilledIcon = 'ChangeFsaPoundFilledIcon',
  ChangeFsaPoundOutlineIcon = 'ChangeFsaPoundOutlineIcon',
  ChangeFsaRupeeFilledIcon = 'ChangeFsaRupeeFilledIcon',
  ChangeFsaRupeeOutlineIcon = 'ChangeFsaRupeeOutlineIcon',
  ChangeFsaYenFilledIcon = 'ChangeFsaYenFilledIcon',
  ChangeFsaYenOutlineIcon = 'ChangeFsaYenOutlineIcon',
  ChangeInsuranceFilledIcon = 'ChangeInsuranceFilledIcon',
  ChangeInsuranceOutlineIcon = 'ChangeInsuranceOutlineIcon',
  ChangeFilledIcon = 'ChangeFilledIcon',
  ChangeOutlineIcon = 'ChangeOutlineIcon',
  ChangeTaxWithholdingFilledIcon = 'ChangeTaxWithholdingFilledIcon',
  ChangeTaxWithholdingOutlineIcon = 'ChangeTaxWithholdingOutlineIcon',
  CharityFilledIcon = 'CharityFilledIcon',
  CharityOutlineIcon = 'CharityOutlineIcon',
  CheckCircleFilledIcon = 'CheckCircleFilledIcon',
  CheckCircleOutlineIcon = 'CheckCircleOutlineIcon',
  CheckIcon = 'CheckIcon',
  CheckedbagFilledIcon = 'CheckedbagFilledIcon',
  CheckedbagOutlineIcon = 'CheckedbagOutlineIcon',
  CheckroomIcon = 'CheckroomIcon',
  ChevronDownIcon = 'ChevronDownIcon',
  ChevronLeftIcon = 'ChevronLeftIcon',
  ChevronRightIcon = 'ChevronRightIcon',
  ChevronUpIcon = 'ChevronUpIcon',
  ClearFormattingFilledIcon = 'ClearFormattingFilledIcon',
  ClearFormattingOutlineIcon = 'ClearFormattingOutlineIcon',
  ClearIcon = 'ClearIcon',
  ClientCommandFilledIcon = 'ClientCommandFilledIcon',
  ClientCommandOutlineIcon = 'ClientCommandOutlineIcon',
  CloseCircleFilledIcon = 'CloseCircleFilledIcon',
  CloseCircleOutlineIcon = 'CloseCircleOutlineIcon',
  CloseIcon = 'CloseIcon',
  ClosedCaptionsFilledIcon = 'ClosedCaptionsFilledIcon',
  ClosedCaptionsOutlineIcon = 'ClosedCaptionsOutlineIcon',
  CoIcon = 'CoIcon',
  CodeEmbedIcon = 'CodeEmbedIcon',
  CogglobeFilledIcon = 'CogglobeFilledIcon',
  CogglobeOutlineIcon = 'CogglobeOutlineIcon',
  CollapsePanelFilledIcon = 'CollapsePanelFilledIcon',
  CollapsePanelOutlineIcon = 'CollapsePanelOutlineIcon',
  CollapseIcon = 'CollapseIcon',
  ColumnBarChartFilledIcon = 'ColumnBarChartFilledIcon',
  ColumnBarChartOutlineIcon = 'ColumnBarChartOutlineIcon',
  ColumnBarchartFilledIcon = 'ColumnBarchartFilledIcon',
  ColumnBarchartOutlineIcon = 'ColumnBarchartOutlineIcon',
  ComboChartFilledIcon = 'ComboChartFilledIcon',
  ComboChartOutlineIcon = 'ComboChartOutlineIcon',
  ComfortIcon = 'ComfortIcon',
  CommentsFilledIcon = 'CommentsFilledIcon',
  CommentsOutlineIcon = 'CommentsOutlineIcon',
  CompanySettingsFilledIcon = 'CompanySettingsFilledIcon',
  CompanySettingsOutlineIcon = 'CompanySettingsOutlineIcon',
  CompassFilledIcon = 'CompassFilledIcon',
  CompassOutlineIcon = 'CompassOutlineIcon',
  CompetitiveParentalLeaveFilledIcon = 'CompetitiveParentalLeaveFilledIcon',
  CompetitiveParentalLeaveOutlineIcon = 'CompetitiveParentalLeaveOutlineIcon',
  Compliance360Icon = 'Compliance360Icon',
  ComposeFilledIcon = 'ComposeFilledIcon',
  ComposeOutlineIcon = 'ComposeOutlineIcon',
  ConnectCloudFilledIcon = 'ConnectCloudFilledIcon',
  ConnectCloudOutlineIcon = 'ConnectCloudOutlineIcon',
  ConnectDatabaseFilledIcon = 'ConnectDatabaseFilledIcon',
  ConnectDatabaseOutlineIcon = 'ConnectDatabaseOutlineIcon',
  ConsultantFilledIcon = 'ConsultantFilledIcon',
  ConsultantOutlineIcon = 'ConsultantOutlineIcon',
  ContentLibraryFilledIcon = 'ContentLibraryFilledIcon',
  ContentLibraryOutlineIcon = 'ContentLibraryOutlineIcon',
  ControlFilledIcon = 'ControlFilledIcon',
  ControlOutlineIcon = 'ControlOutlineIcon',
  CopyFilledIcon = 'CopyFilledIcon',
  CopyOutlineIcon = 'CopyOutlineIcon',
  CostaRicanColnIcon = 'CostaRicanColnIcon',
  CoursesFilledIcon = 'CoursesFilledIcon',
  CoursesOutlineIcon = 'CoursesOutlineIcon',
  CreateGroupShortcutFilledIcon = 'CreateGroupShortcutFilledIcon',
  CreateGroupShortcutOutlineIcon = 'CreateGroupShortcutOutlineIcon',
  CreditCardSpendingLimitFilledIcon = 'CreditCardSpendingLimitFilledIcon',
  CreditCardSpendingLimitOutlineIcon = 'CreditCardSpendingLimitOutlineIcon',
  CreditCardFilledIcon = 'CreditCardFilledIcon',
  CreditCardOutlineIcon = 'CreditCardOutlineIcon',
  CsvFilledIcon = 'CsvFilledIcon',
  CsvOutlineIcon = 'CsvOutlineIcon',
  CupFilledIcon = 'CupFilledIcon',
  CupOutlineIcon = 'CupOutlineIcon',
  CurrencyCoversionDollarIcon = 'CurrencyCoversionDollarIcon',
  CurrencyCoversionEurIcon = 'CurrencyCoversionEurIcon',
  CurrencyCoversionPoundIcon = 'CurrencyCoversionPoundIcon',
  CurrencyCoversionRupeeIcon = 'CurrencyCoversionRupeeIcon',
  CurrencyCoversionYenIcon = 'CurrencyCoversionYenIcon',
  CurrentStepFilledIcon = 'CurrentStepFilledIcon',
  CurrentStepOutlineIcon = 'CurrentStepOutlineIcon',
  CustomFieldsFilledIcon = 'CustomFieldsFilledIcon',
  CustomFieldsOutlineIcon = 'CustomFieldsOutlineIcon',
  CustomAppsFilledIcon = 'CustomAppsFilledIcon',
  CustomAppsOutlineIcon = 'CustomAppsOutlineIcon',
  CustomerMaturityFilledIcon = 'CustomerMaturityFilledIcon',
  CustomerMaturityOutlineIcon = 'CustomerMaturityOutlineIcon',
  CustomerSpotlightFilledIcon = 'CustomerSpotlightFilledIcon',
  CustomerSpotlightOutlineIcon = 'CustomerSpotlightOutlineIcon',
  CustomerTestimonialsFilledIcon = 'CustomerTestimonialsFilledIcon',
  CustomerTestimonialsOutlineIcon = 'CustomerTestimonialsOutlineIcon',
  CustomizePolicyFilledIcon = 'CustomizePolicyFilledIcon',
  CustomizePolicyOutlineIcon = 'CustomizePolicyOutlineIcon',
  CutIcon = 'CutIcon',
  CzechKorunaIcon = 'CzechKorunaIcon',
  DStackedBarFilledIcon = 'DStackedBarFilledIcon',
  DStackedBarOutlineIcon = 'DStackedBarOutlineIcon',
  DashboardIcon = 'DashboardIcon',
  DataConnectorFilledIcon = 'DataConnectorFilledIcon',
  DataConnectorOutlineIcon = 'DataConnectorOutlineIcon',
  DeliverytruckFilledIcon = 'DeliverytruckFilledIcon',
  DeliverytruckOutlineIcon = 'DeliverytruckOutlineIcon',
  DepartmentsFilledIcon = 'DepartmentsFilledIcon',
  DepartmentsOutlineIcon = 'DepartmentsOutlineIcon',
  DepartureFilledIcon = 'DepartureFilledIcon',
  DepartureOutlineIcon = 'DepartureOutlineIcon',
  DeployFilledIcon = 'DeployFilledIcon',
  DeployOutlineIcon = 'DeployOutlineIcon',
  DesktopFilledIcon = 'DesktopFilledIcon',
  DesktopOutlineIcon = 'DesktopOutlineIcon',
  DeviceInventoryManagementFilledIcon = 'DeviceInventoryManagementFilledIcon',
  DeviceInventoryManagementOutlineIcon = 'DeviceInventoryManagementOutlineIcon',
  DeviceInventoryFilledIcon = 'DeviceInventoryFilledIcon',
  DeviceInventoryOutlineIcon = 'DeviceInventoryOutlineIcon',
  DirectReportsFilledIcon = 'DirectReportsFilledIcon',
  DirectReportsOutlineIcon = 'DirectReportsOutlineIcon',
  DissatisfiedFilledIcon = 'DissatisfiedFilledIcon',
  DissatisfiedOutlineIcon = 'DissatisfiedOutlineIcon',
  DocumentFilledIcon = 'DocumentFilledIcon',
  DocumentOutlineIcon = 'DocumentOutlineIcon',
  DogFriendlyFilledIcon = 'DogFriendlyFilledIcon',
  DogFriendlyOutlineIcon = 'DogFriendlyOutlineIcon',
  DollarCircleFilledIcon = 'DollarCircleFilledIcon',
  DollarCircleOutlineIcon = 'DollarCircleOutlineIcon',
  DollarIcon = 'DollarIcon',
  DollarcirclearrowsFilledIcon = 'DollarcirclearrowsFilledIcon',
  DollarcirclearrowsOutlineIcon = 'DollarcirclearrowsOutlineIcon',
  DollarcirclecogFilledIcon = 'DollarcirclecogFilledIcon',
  DollarcirclecogOutlineIcon = 'DollarcirclecogOutlineIcon',
  DonutChartFilledIcon = 'DonutChartFilledIcon',
  DonutChartOutlineIcon = 'DonutChartOutlineIcon',
  DownloadIcon = 'DownloadIcon',
  DragIcon = 'DragIcon',
  DropdownSortedDownIcon = 'DropdownSortedDownIcon',
  DropdownSortedUpIcon = 'DropdownSortedUpIcon',
  DropdownFolderFilledIcon = 'DropdownFolderFilledIcon',
  DropdownFolderOutlineIcon = 'DropdownFolderOutlineIcon',
  DropdownFilledIcon = 'DropdownFilledIcon',
  DropdownOutlineIcon = 'DropdownOutlineIcon',
  DuplicateFilledIcon = 'DuplicateFilledIcon',
  DuplicateOutlineIcon = 'DuplicateOutlineIcon',
  EditFilledIcon = 'EditFilledIcon',
  EditOutlineIcon = 'EditOutlineIcon',
  EmailFilledIcon = 'EmailFilledIcon',
  EmailOutlineIcon = 'EmailOutlineIcon',
  EmploymentInfoFilledIcon = 'EmploymentInfoFilledIcon',
  EmploymentInfoOutlineIcon = 'EmploymentInfoOutlineIcon',
  EngineeringFilledIcon = 'EngineeringFilledIcon',
  EngineeringOutlineIcon = 'EngineeringOutlineIcon',
  EnterFullScreenIcon = 'EnterFullScreenIcon',
  EntertainmentFilledIcon = 'EntertainmentFilledIcon',
  EntertainmentOutlineIcon = 'EntertainmentOutlineIcon',
  EqualToIcon = 'EqualToIcon',
  EquityDollarFilledIcon = 'EquityDollarFilledIcon',
  EquityDollarOutlineIcon = 'EquityDollarOutlineIcon',
  EquityEuroFilledIcon = 'EquityEuroFilledIcon',
  EquityEuroOutlineIcon = 'EquityEuroOutlineIcon',
  EquityPoundFilledIcon = 'EquityPoundFilledIcon',
  EquityPoundOutlineIcon = 'EquityPoundOutlineIcon',
  EquityRupeeFilledIcon = 'EquityRupeeFilledIcon',
  EquityRupeeOutlineIcon = 'EquityRupeeOutlineIcon',
  EquityYenFilledIcon = 'EquityYenFilledIcon',
  EquityYenOutlineIcon = 'EquityYenOutlineIcon',
  EuroCircleFilledIcon = 'EuroCircleFilledIcon',
  EuroCircleOutlineIcon = 'EuroCircleOutlineIcon',
  EuroIcon = 'EuroIcon',
  EventsAndWebinarsFilledIcon = 'EventsAndWebinarsFilledIcon',
  EventsAndWebinarsOutlineIcon = 'EventsAndWebinarsOutlineIcon',
  ExitFullScreenIcon = 'ExitFullScreenIcon',
  ExpandPanelFilledIcon = 'ExpandPanelFilledIcon',
  ExpandPanelOutlineIcon = 'ExpandPanelOutlineIcon',
  ExpandIcon = 'ExpandIcon',
  ExpensePolicyDollarFilledIcon = 'ExpensePolicyDollarFilledIcon',
  ExpensePolicyDollarOutlineIcon = 'ExpensePolicyDollarOutlineIcon',
  ExpensePolicyEuroFilledIcon = 'ExpensePolicyEuroFilledIcon',
  ExpensePolicyEuroOutlineIcon = 'ExpensePolicyEuroOutlineIcon',
  ExpensePolicyPoundFilledIcon = 'ExpensePolicyPoundFilledIcon',
  ExpensePolicyPoundOutlineIcon = 'ExpensePolicyPoundOutlineIcon',
  ExpensePolicyRupeeFilledIcon = 'ExpensePolicyRupeeFilledIcon',
  ExpensePolicyRupeeOutlineIcon = 'ExpensePolicyRupeeOutlineIcon',
  ExpensePolicyYenFilledIcon = 'ExpensePolicyYenFilledIcon',
  ExpensePolicyYenOutlineIcon = 'ExpensePolicyYenOutlineIcon',
  FeatherFilledIcon = 'FeatherFilledIcon',
  FeatherOutlineIcon = 'FeatherOutlineIcon',
  FeedbackFormFilledIcon = 'FeedbackFormFilledIcon',
  FeedbackFormOutlineIcon = 'FeedbackFormOutlineIcon',
  FidoFilledIcon = 'FidoFilledIcon',
  FidoOutlineIcon = 'FidoOutlineIcon',
  FileFilledIcon = 'FileFilledIcon',
  FileOutlineIcon = 'FileOutlineIcon',
  FileshieldplusFilledIcon = 'FileshieldplusFilledIcon',
  FileshieldplusOutlineIcon = 'FileshieldplusOutlineIcon',
  FilledIcon11 = 'FilledIcon11',
  FilterByTypeFilledIcon = 'FilterByTypeFilledIcon',
  FilterByTypeOutlineIcon = 'FilterByTypeOutlineIcon',
  FilterIcon = 'FilterIcon',
  FinancesFilledIcon = 'FinancesFilledIcon',
  FinancesOutlineIcon = 'FinancesOutlineIcon',
  FingerprintIcon = 'FingerprintIcon',
  FitnessFilledIcon = 'FitnessFilledIcon',
  FitnessOutlineIcon = 'FitnessOutlineIcon',
  FlagFilledIcon = 'FlagFilledIcon',
  FlagOutlineIcon = 'FlagOutlineIcon',
  FlexibleWorkScheduleFilledIcon = 'FlexibleWorkScheduleFilledIcon',
  FlexibleWorkScheduleOutlineIcon = 'FlexibleWorkScheduleOutlineIcon',
  FlightsFilledIcon = 'FlightsFilledIcon',
  FlightsOutlineIcon = 'FlightsOutlineIcon',
  FolderFilledIcon = 'FolderFilledIcon',
  FolderOutlineIcon = 'FolderOutlineIcon',
  FontColorFilledIcon = 'FontColorFilledIcon',
  FontColorOutlineIcon = 'FontColorOutlineIcon',
  FontSizeIcon = 'FontSizeIcon',
  FontHighlightFilledIcon = 'FontHighlightFilledIcon',
  FontHighlightOutlineIcon = 'FontHighlightOutlineIcon',
  Forward10SecondsIcon = 'Forward10SecondsIcon',
  FsaFilledIcon = 'FsaFilledIcon',
  FsaOutlineIcon = 'FsaOutlineIcon',
  FunctionIcon = 'FunctionIcon',
  FunnelChartFilledIcon = 'FunnelChartFilledIcon',
  FunnelChartOutlineIcon = 'FunnelChartOutlineIcon',
  FutureStepFilledIcon = 'FutureStepFilledIcon',
  FutureStepOutlineIcon = 'FutureStepOutlineIcon',
  FxFilledIcon = 'FxFilledIcon',
  FxOutlineIcon = 'FxOutlineIcon',
  GPTFilledIcon = 'GPTFilledIcon',
  GPTOutlineIcon = 'GPTOutlineIcon',
  GaugeChartFilledIcon = 'GaugeChartFilledIcon',
  GaugeChartOutlineIcon = 'GaugeChartOutlineIcon',
  GeneralLedgerDollarFilledIcon = 'GeneralLedgerDollarFilledIcon',
  GeneralLedgerDollarOutlineIcon = 'GeneralLedgerDollarOutlineIcon',
  GeneralLedgerEuroFilledIcon = 'GeneralLedgerEuroFilledIcon',
  GeneralLedgerEuroOutlineIcon = 'GeneralLedgerEuroOutlineIcon',
  GeneralLedgerPoundFilledIcon = 'GeneralLedgerPoundFilledIcon',
  GeneralLedgerPoundOutlineIcon = 'GeneralLedgerPoundOutlineIcon',
  GeneralLedgerRupeeFilledIcon = 'GeneralLedgerRupeeFilledIcon',
  GeneralLedgerRupeeOutlineIcon = 'GeneralLedgerRupeeOutlineIcon',
  GeneralLedgerYenFilledIcon = 'GeneralLedgerYenFilledIcon',
  GeneralLedgerYenOutlineIcon = 'GeneralLedgerYenOutlineIcon',
  GifFilledIcon = 'GifFilledIcon',
  GifOutlineIcon = 'GifOutlineIcon',
  GiftFilledIcon = 'GiftFilledIcon',
  GiftOutlineIcon = 'GiftOutlineIcon',
  GlobeFilledIcon = 'GlobeFilledIcon',
  GlobeOutlineIcon = 'GlobeOutlineIcon',
  GoalsFilledIcon = 'GoalsFilledIcon',
  GoalsOutlineIcon = 'GoalsOutlineIcon',
  GraphchartIcon = 'GraphchartIcon',
  GreaterOrEqualToIcon = 'GreaterOrEqualToIcon',
  GreaterThanIcon = 'GreaterThanIcon',
  GroceriesFilledIcon = 'GroceriesFilledIcon',
  GroceriesOutlineIcon = 'GroceriesOutlineIcon',
  GuidepostFilledIcon = 'GuidepostFilledIcon',
  GuidepostOutlineIcon = 'GuidepostOutlineIcon',
  HamburgerIcon = 'HamburgerIcon',
  HanddollarFilledIcon = 'HanddollarFilledIcon',
  HanddollarOutlineIcon = 'HanddollarOutlineIcon',
  HandplusFilledIcon = 'HandplusFilledIcon',
  HandplusOutlineIcon = 'HandplusOutlineIcon',
  HandshakeFilledIcon = 'HandshakeFilledIcon',
  HandshakeOutlineIcon = 'HandshakeOutlineIcon',
  HardwareSecurityKeyFilledIcon = 'HardwareSecurityKeyFilledIcon',
  HardwareSecurityKeyOutlineIcon = 'HardwareSecurityKeyOutlineIcon',
  HeadphonesFilledIcon = 'HeadphonesFilledIcon',
  HeadphonesOutlineIcon = 'HeadphonesOutlineIcon',
  HealingFilledIcon = 'HealingFilledIcon',
  HealingOutlineIcon = 'HealingOutlineIcon',
  HealthFilledIcon = 'HealthFilledIcon',
  HealthOutlineIcon = 'HealthOutlineIcon',
  HeartFilledIcon = 'HeartFilledIcon',
  HeartOutlineIcon = 'HeartOutlineIcon',
  HelpCenterFilledIcon = 'HelpCenterFilledIcon',
  HelpCenterOutlineIcon = 'HelpCenterOutlineIcon',
  HelpFilledIcon = 'HelpFilledIcon',
  HelpOutlineIcon = 'HelpOutlineIcon',
  HideFilledIcon = 'HideFilledIcon',
  HideOutlineIcon = 'HideOutlineIcon',
  HighPriorityIcon = 'HighPriorityIcon',
  HistoryIcon = 'HistoryIcon',
  HomeExitIcon = 'HomeExitIcon',
  HomeFilledIcon = 'HomeFilledIcon',
  HomeOutlineIcon = 'HomeOutlineIcon',
  HotelFilledIcon = 'HotelFilledIcon',
  HotelOutlineIcon = 'HotelOutlineIcon',
  HotelclassFilledIcon = 'HotelclassFilledIcon',
  HotelclassOutlineIcon = 'HotelclassOutlineIcon',
  HrFilledIcon = 'HrFilledIcon',
  HrOutlineIcon = 'HrOutlineIcon',
  HsaFilledIcon = 'HsaFilledIcon',
  HsaOutlineIcon = 'HsaOutlineIcon',
  I9FilledIcon = 'I9FilledIcon',
  I9OutlineIcon = 'I9OutlineIcon',
  ImageAdjustOutlineBreakTextIcon = 'ImageAdjustOutlineBreakTextIcon',
  ImageAdjustOutlineInlineIcon = 'ImageAdjustOutlineInlineIcon',
  ImageAdjustOutlineWrapTextIcon = 'ImageAdjustOutlineWrapTextIcon',
  ImageFilledIcon = 'ImageFilledIcon',
  ImageOutlineIcon = 'ImageOutlineIcon',
  InPlanFilledIcon = 'InPlanFilledIcon',
  InPlanOutlineIcon = 'InPlanOutlineIcon',
  InProgressStepFilledIcon = 'InProgressStepFilledIcon',
  InProgressStepOutlineIcon = 'InProgressStepOutlineIcon',
  InPolicyFilledIcon = 'InPolicyFilledIcon',
  InPolicyOutlineIcon = 'InPolicyOutlineIcon',
  IndentDecreaseIcon = 'IndentDecreaseIcon',
  IndentIncreaseIcon = 'IndentIncreaseIcon',
  InfoFilledIcon = 'InfoFilledIcon',
  InfoOutlineIcon = 'InfoOutlineIcon',
  IntegratedAppsFilledIcon = 'IntegratedAppsFilledIcon',
  IntegratedAppsOutlineIcon = 'IntegratedAppsOutlineIcon',
  InteractiveCourseFilledIcon = 'InteractiveCourseFilledIcon',
  InteractiveCourseOutlineIcon = 'InteractiveCourseOutlineIcon',
  InvoiceDollarFilledIcon = 'InvoiceDollarFilledIcon',
  InvoiceDollarOutlineIcon = 'InvoiceDollarOutlineIcon',
  InvoiceEuroFilledIcon = 'InvoiceEuroFilledIcon',
  InvoiceEuroOutlineIcon = 'InvoiceEuroOutlineIcon',
  InvoicePoundFilledIcon = 'InvoicePoundFilledIcon',
  InvoicePoundOutlineIcon = 'InvoicePoundOutlineIcon',
  InvoiceRupeeFilledIcon = 'InvoiceRupeeFilledIcon',
  InvoiceRupeeOutlineIcon = 'InvoiceRupeeOutlineIcon',
  InvoiceYenFilledIcon = 'InvoiceYenFilledIcon',
  InvoiceYenOutlineIcon = 'InvoiceYenOutlineIcon',
  ItalicIcon = 'ItalicIcon',
  KanbanIcon = 'KanbanIcon',
  KeyIcon = 'KeyIcon',
  KeyholeFilledIcon = 'KeyholeFilledIcon',
  KeyholeOutlineIcon = 'KeyholeOutlineIcon',
  LabsIcon = 'LabsIcon',
  LaptopFilledIcon = 'LaptopFilledIcon',
  LaptopOutlineIcon = 'LaptopOutlineIcon',
  LeaveManagementFilledIcon = 'LeaveManagementFilledIcon',
  LeaveManagementOutlineIcon = 'LeaveManagementOutlineIcon',
  LegalFilledIcon = 'LegalFilledIcon',
  LegalOutlineIcon = 'LegalOutlineIcon',
  LegroomFilledIcon = 'LegroomFilledIcon',
  LegroomOutlineIcon = 'LegroomOutlineIcon',
  LessOrEqualToIcon = 'LessOrEqualToIcon',
  LessThanIcon = 'LessThanIcon',
  LifeFilledIcon = 'LifeFilledIcon',
  LifeOutlineIcon = 'LifeOutlineIcon',
  LineHeightIcon = 'LineHeightIcon',
  LinechartIcon = 'LinechartIcon',
  LinkOutletIcon = 'LinkOutletIcon',
  LinkIcon = 'LinkIcon',
  ListFilledIcon = 'ListFilledIcon',
  ListOutlineIcon = 'ListOutlineIcon',
  LithuanianLitasIcon = 'LithuanianLitasIcon',
  LmsFilledIcon = 'LmsFilledIcon',
  LmsOutlineIcon = 'LmsOutlineIcon',
  LocationFilledIcon = 'LocationFilledIcon',
  LocationOutlineIcon = 'LocationOutlineIcon',
  LockFilledIcon = 'LockFilledIcon',
  LockOutlineIcon = 'LockOutlineIcon',
  LongAnswerIcon = 'LongAnswerIcon',
  MarkAsStolenFilledIcon = 'MarkAsStolenFilledIcon',
  MarkAsStolenOutlineIcon = 'MarkAsStolenOutlineIcon',
  MarketingAndAdvertisingFilledIcon = 'MarketingAndAdvertisingFilledIcon',
  MarketingAndAdvertisingOutlineIcon = 'MarketingAndAdvertisingOutlineIcon',
  MealsSnacksDrinksFilledIcon = 'MealsSnacksDrinksFilledIcon',
  MealsSnacksDrinksOutlineIcon = 'MealsSnacksDrinksOutlineIcon',
  MealsFilledIcon = 'MealsFilledIcon',
  MealsOutlineIcon = 'MealsOutlineIcon',
  MedicalFilledIcon = 'MedicalFilledIcon',
  MedicalOutlineIcon = 'MedicalOutlineIcon',
  MeetingRoomsFilledIcon = 'MeetingRoomsFilledIcon',
  MeetingRoomsOutlineIcon = 'MeetingRoomsOutlineIcon',
  MergeCellsIcon = 'MergeCellsIcon',
  MessageFilledIcon = 'MessageFilledIcon',
  MessageOutlineIcon = 'MessageOutlineIcon',
  MetricChartIcon = 'MetricChartIcon',
  MinimizeIcon = 'MinimizeIcon',
  MonitoringIcon = 'MonitoringIcon',
  MoreHorizontalIcon = 'MoreHorizontalIcon',
  MoreVerticalIcon = 'MoreVerticalIcon',
  MultiSelectFilledIcon = 'MultiSelectFilledIcon',
  MultiSelectOutlineIcon = 'MultiSelectOutlineIcon',
  MultiStopFilledIcon = 'MultiStopFilledIcon',
  MultiStopOutlineIcon = 'MultiStopOutlineIcon',
  MuteFilledIcon = 'MuteFilledIcon',
  MuteOutlineIcon = 'MuteOutlineIcon',
  MyAccountSettingsFilledIcon = 'MyAccountSettingsFilledIcon',
  MyAccountSettingsOutlineIcon = 'MyAccountSettingsOutlineIcon',
  NeutralFilledIcon = 'NeutralFilledIcon',
  NeutralOutlineIcon = 'NeutralOutlineIcon',
  NextSpeakerIcon = 'NextSpeakerIcon',
  NonprofitFilledIcon = 'NonprofitFilledIcon',
  NonprofitOutlineIcon = 'NonprofitOutlineIcon',
  NotEqualToIcon = 'NotEqualToIcon',
  NoticeIcon = 'NoticeIcon',
  NotificationFilledIcon = 'NotificationFilledIcon',
  NotificationOutlineIcon = 'NotificationOutlineIcon',
  NumberedListIcon = 'NumberedListIcon',
  ObjectFilledIcon = 'ObjectFilledIcon',
  ObjectOutlineIcon = 'ObjectOutlineIcon',
  OffboardFilledIcon = 'OffboardFilledIcon',
  OffboardOutlineIcon = 'OffboardOutlineIcon',
  OfficeSuppliesFilledIcon = 'OfficeSuppliesFilledIcon',
  OfficeSuppliesOutlineIcon = 'OfficeSuppliesOutlineIcon',
  OfficeFilledIcon = 'OfficeFilledIcon',
  OfficeOutlineIcon = 'OfficeOutlineIcon',
  OfflineFilledIcon = 'OfflineFilledIcon',
  OfflineOutlineIcon = 'OfflineOutlineIcon',
  OnboardFilledIcon = 'OnboardFilledIcon',
  OnboardOutlineIcon = 'OnboardOutlineIcon',
  OnlineFilledIcon = 'OnlineFilledIcon',
  OnlineOutlineIcon = 'OnlineOutlineIcon',
  OpenApiIcon = 'OpenApiIcon',
  OptionsIcon = 'OptionsIcon',
  OriginFilledIcon = 'OriginFilledIcon',
  OriginOutlineIcon = 'OriginOutlineIcon',
  OutOfPlanFilledIcon = 'OutOfPlanFilledIcon',
  OutOfPlanOutlineIcon = 'OutOfPlanOutlineIcon',
  OutOfPolicyFilledIcon = 'OutOfPolicyFilledIcon',
  OutOfPolicyOutlineIcon = 'OutOfPolicyOutlineIcon',
  OutlineIcon11 = 'OutlineIcon11',
  OvernightFilledIcon = 'OvernightFilledIcon',
  OvernightOutlineIcon = 'OvernightOutlineIcon',
  OvertimePolicyFilledIcon = 'OvertimePolicyFilledIcon',
  OvertimePolicyOutlineIcon = 'OvertimePolicyOutlineIcon',
  PaidSeatSelectionFilledIcon = 'PaidSeatSelectionFilledIcon',
  PaidSeatSelectionOutlineIcon = 'PaidSeatSelectionOutlineIcon',
  PasteFilledIcon = 'PasteFilledIcon',
  PasteOutlineIcon = 'PasteOutlineIcon',
  PauseCircleFilledIcon = 'PauseCircleFilledIcon',
  PauseCircleOutlineIcon = 'PauseCircleOutlineIcon',
  PauseIcon = 'PauseIcon',
  PayScheduleDollarFilledIcon = 'PayScheduleDollarFilledIcon',
  PayScheduleDollarOutlineIcon = 'PayScheduleDollarOutlineIcon',
  PayScheduleEuroFilledIcon = 'PayScheduleEuroFilledIcon',
  PayScheduleEuroOutlineIcon = 'PayScheduleEuroOutlineIcon',
  PaySchedulePoundFilledIcon = 'PaySchedulePoundFilledIcon',
  PaySchedulePoundOutlineIcon = 'PaySchedulePoundOutlineIcon',
  PayScheduleRupeeFilledIcon = 'PayScheduleRupeeFilledIcon',
  PayScheduleRupeeOutlineIcon = 'PayScheduleRupeeOutlineIcon',
  PayScheduleYenFilledIcon = 'PayScheduleYenFilledIcon',
  PayScheduleYenOutlineIcon = 'PayScheduleYenOutlineIcon',
  PayrollReportDollarFilledIcon = 'PayrollReportDollarFilledIcon',
  PayrollReportDollarOutlineIcon = 'PayrollReportDollarOutlineIcon',
  PayrollReportEuroFilledIcon = 'PayrollReportEuroFilledIcon',
  PayrollReportEuroOutlineIcon = 'PayrollReportEuroOutlineIcon',
  PayrollReportPoundFilledIcon = 'PayrollReportPoundFilledIcon',
  PayrollReportPoundOutlineIcon = 'PayrollReportPoundOutlineIcon',
  PayrollReportRupeeFilledIcon = 'PayrollReportRupeeFilledIcon',
  PayrollReportRupeeOutlineIcon = 'PayrollReportRupeeOutlineIcon',
  PayrollReportYenFilledIcon = 'PayrollReportYenFilledIcon',
  PayrollReportYenOutlineIcon = 'PayrollReportYenOutlineIcon',
  PayrollTimeDollarFilledIcon = 'PayrollTimeDollarFilledIcon',
  PayrollTimeDollarOutlineIcon = 'PayrollTimeDollarOutlineIcon',
  PayrollTimeEuroFilledIcon = 'PayrollTimeEuroFilledIcon',
  PayrollTimeEuroOutlineIcon = 'PayrollTimeEuroOutlineIcon',
  PayrollTimePoundFilledIcon = 'PayrollTimePoundFilledIcon',
  PayrollTimePoundOutlineIcon = 'PayrollTimePoundOutlineIcon',
  PayrollTimeRupeeFilledIcon = 'PayrollTimeRupeeFilledIcon',
  PayrollTimeRupeeOutlineIcon = 'PayrollTimeRupeeOutlineIcon',
  PayrollTimeYenFilledIcon = 'PayrollTimeYenFilledIcon',
  PayrollTimeYenOutlineIcon = 'PayrollTimeYenOutlineIcon',
  PaystubDollarIcon = 'PaystubDollarIcon',
  PaystubEuroIcon = 'PaystubEuroIcon',
  PaystubPoundIcon = 'PaystubPoundIcon',
  PaystubRupeeIcon = 'PaystubRupeeIcon',
  PaystubYenIcon = 'PaystubYenIcon',
  PdfFilledIcon = 'PdfFilledIcon',
  PdfOutlineIcon = 'PdfOutlineIcon',
  PendingCircleFilledIcon = 'PendingCircleFilledIcon',
  PendingCircleOutlineIcon = 'PendingCircleOutlineIcon',
  PendingFilledIcon = 'PendingFilledIcon',
  PendingOutlineIcon = 'PendingOutlineIcon',
  PendingapprovalFilledIcon = 'PendingapprovalFilledIcon',
  PendingapprovalOutlineIcon = 'PendingapprovalOutlineIcon',
  PeoFilledIcon = 'PeoFilledIcon',
  PeoOutlineIcon = 'PeoOutlineIcon',
  PercentFilledIcon = 'PercentFilledIcon',
  PercentOutlineIcon = 'PercentOutlineIcon',
  PhilippinePesoIcon = 'PhilippinePesoIcon',
  PhoneFilledIcon = 'PhoneFilledIcon',
  PhoneOutlineIcon = 'PhoneOutlineIcon',
  PhotoGalleryFilledIcon = 'PhotoGalleryFilledIcon',
  PhotoGalleryOutlineIcon = 'PhotoGalleryOutlineIcon',
  PlayCircleFilledIcon = 'PlayCircleFilledIcon',
  PlayCircleOutlineIcon = 'PlayCircleOutlineIcon',
  PlayButtonFilledIcon = 'PlayButtonFilledIcon',
  PlayButtonOutlineIcon = 'PlayButtonOutlineIcon',
  PointInTimeFilledIcon = 'PointInTimeFilledIcon',
  PointInTimeOutlineIcon = 'PointInTimeOutlineIcon',
  PointerTargetIcon = 'PointerTargetIcon',
  PolishZlotyIcon = 'PolishZlotyIcon',
  PoundCircleFilledIcon = 'PoundCircleFilledIcon',
  PoundCircleOutlineIcon = 'PoundCircleOutlineIcon',
  PoundIcon = 'PoundIcon',
  PowerButtonIcon = 'PowerButtonIcon',
  PowerFilledIcon = 'PowerFilledIcon',
  PowerOutlineIcon = 'PowerOutlineIcon',
  PriceCapIcon = 'PriceCapIcon',
  ProvisionUsersFilledIcon = 'ProvisionUsersFilledIcon',
  ProvisionUsersOutlineIcon = 'ProvisionUsersOutlineIcon',
  PublicTransportationFilledIcon = 'PublicTransportationFilledIcon',
  PublicTransportationOutlineIcon = 'PublicTransportationOutlineIcon',
  PushFilledIcon = 'PushFilledIcon',
  PushOutlineIcon = 'PushOutlineIcon',
  QuestionCircleFilledIcon = 'QuestionCircleFilledIcon',
  QuestionCircleOutlineIcon = 'QuestionCircleOutlineIcon',
  QuoteFilledIcon = 'QuoteFilledIcon',
  QuoteOutlineIcon = 'QuoteOutlineIcon',
  ReadinessFilledIcon = 'ReadinessFilledIcon',
  ReadinessOutlineIcon = 'ReadinessOutlineIcon',
  ReassignComputerFilledIcon = 'ReassignComputerFilledIcon',
  ReassignComputerOutlineIcon = 'ReassignComputerOutlineIcon',
  ReceiptFilledIcon = 'ReceiptFilledIcon',
  ReceiptOutlineIcon = 'ReceiptOutlineIcon',
  RecipesFilledIcon = 'RecipesFilledIcon',
  RecipesOutlineIcon = 'RecipesOutlineIcon',
  ReclineExtraFilledIcon = 'ReclineExtraFilledIcon',
  ReclineExtraOutlineIcon = 'ReclineExtraOutlineIcon',
  ReclineNormalFilledIcon = 'ReclineNormalFilledIcon',
  ReclineNormalOutlineIcon = 'ReclineNormalOutlineIcon',
  RecruitingFilledIcon = 'RecruitingFilledIcon',
  RecruitingOutlineIcon = 'RecruitingOutlineIcon',
  RedoCrossedIcon = 'RedoCrossedIcon',
  RedoIcon = 'RedoIcon',
  RefreshCrossedIcon = 'RefreshCrossedIcon',
  RefreshIcon = 'RefreshIcon',
  RemediationFilledIcon = 'RemediationFilledIcon',
  RemediationOutlineIcon = 'RemediationOutlineIcon',
  RemoveFilledIcon = 'RemoveFilledIcon',
  RemoveOutlineIcon = 'RemoveOutlineIcon',
  ReportMonitoringFilledIcon = 'ReportMonitoringFilledIcon',
  ReportMonitoringOutlineIcon = 'ReportMonitoringOutlineIcon',
  ReportIcon = 'ReportIcon',
  ReportsFilledIcon = 'ReportsFilledIcon',
  ReportsOutlineIcon = 'ReportsOutlineIcon',
  RetailDollarFilledIcon = 'RetailDollarFilledIcon',
  RetailDollarOutlineIcon = 'RetailDollarOutlineIcon',
  RetailEuroFilledIcon = 'RetailEuroFilledIcon',
  RetailEuroOutlineIcon = 'RetailEuroOutlineIcon',
  RetailPoundFilledIcon = 'RetailPoundFilledIcon',
  RetailPoundOutlineIcon = 'RetailPoundOutlineIcon',
  RetailRupeeFilledIcon = 'RetailRupeeFilledIcon',
  RetailRupeeOutlineIcon = 'RetailRupeeOutlineIcon',
  RetailYenFilledIcon = 'RetailYenFilledIcon',
  RetailYenOutlineIcon = 'RetailYenOutlineIcon',
  ReturnIcon = 'ReturnIcon',
  ReviewCyclesIcon = 'ReviewCyclesIcon',
  Rewind10SecondsIcon = 'Rewind10SecondsIcon',
  RichtextIcon = 'RichtextIcon',
  RideshareFilledIcon = 'RideshareFilledIcon',
  RideshareOutlineIcon = 'RideshareOutlineIcon',
  RiskFilledIcon = 'RiskFilledIcon',
  RiskOutlineIcon = 'RiskOutlineIcon',
  RoiFilledIcon = 'RoiFilledIcon',
  RoiOutlineIcon = 'RoiOutlineIcon',
  RpassFilledIcon = 'RpassFilledIcon',
  RpassOutlineIcon = 'RpassOutlineIcon',
  RupeeCircleFilledIcon = 'RupeeCircleFilledIcon',
  RupeeCircleOutlineIcon = 'RupeeCircleOutlineIcon',
  RupeeIcon = 'RupeeIcon',
  SalesFilledIcon = 'SalesFilledIcon',
  SalesOutlineIcon = 'SalesOutlineIcon',
  SandboxFilledIcon = 'SandboxFilledIcon',
  SandboxOutlineIcon = 'SandboxOutlineIcon',
  SatisfiedFilledIcon = 'SatisfiedFilledIcon',
  SatisfiedOutlineIcon = 'SatisfiedOutlineIcon',
  SaveAndExitFilledIcon = 'SaveAndExitFilledIcon',
  SaveAndExitOutlineIcon = 'SaveAndExitOutlineIcon',
  ScaleFilledIcon = 'ScaleFilledIcon',
  ScaleOutlineIcon = 'ScaleOutlineIcon',
  ScatterChartIcon = 'ScatterChartIcon',
  SearchIcon = 'SearchIcon',
  ServerFilledIcon = 'ServerFilledIcon',
  ServerOutlineIcon = 'ServerOutlineIcon',
  SettingsFilledIcon = 'SettingsFilledIcon',
  SettingsOutlineIcon = 'SettingsOutlineIcon',
  ShareFilledIcon = 'ShareFilledIcon',
  ShareOutlineIcon = 'ShareOutlineIcon',
  SharedFilledIcon = 'SharedFilledIcon',
  SharedOutlineIcon = 'SharedOutlineIcon',
  ShieldDisabledFilledIcon = 'ShieldDisabledFilledIcon',
  ShieldDisabledOutlineIcon = 'ShieldDisabledOutlineIcon',
  ShieldFilledIcon = 'ShieldFilledIcon',
  ShieldOutlineIcon = 'ShieldOutlineIcon',
  ShortAnswerIcon = 'ShortAnswerIcon',
  ShowFilledIcon = 'ShowFilledIcon',
  ShowOutlineIcon = 'ShowOutlineIcon',
  SignatureFilledIcon = 'SignatureFilledIcon',
  SignatureOutlineIcon = 'SignatureOutlineIcon',
  SkipLogicFilledIcon = 'SkipLogicFilledIcon',
  SkipLogicOutlineIcon = 'SkipLogicOutlineIcon',
  SkipIcon = 'SkipIcon',
  SortAscendingIcon = 'SortAscendingIcon',
  SortDescendingIcon = 'SortDescendingIcon',
  SortIcon = 'SortIcon',
  SoundFilledIcon = 'SoundFilledIcon',
  SoundOutlineIcon = 'SoundOutlineIcon',
  SpellCheckIcon = 'SpellCheckIcon',
  StackedVChartFilledIcon = 'StackedVChartFilledIcon',
  StackedVChartOutlineIcon = 'StackedVChartOutlineIcon',
  StackedcoinsFilledIcon = 'StackedcoinsFilledIcon',
  StackedcoinsOutlineIcon = 'StackedcoinsOutlineIcon',
  StarCircleFilledIcon = 'StarCircleFilledIcon',
  StarCircleOutlineIcon = 'StarCircleOutlineIcon',
  StarEmptyIcon = 'StarEmptyIcon',
  StarFillIcon = 'StarFillIcon',
  StarHalfIcon = 'StarHalfIcon',
  StartupsFilledIcon = 'StartupsFilledIcon',
  StartupsOutlineIcon = 'StartupsOutlineIcon',
  Stretch1Icon = 'Stretch1Icon',
  Stretch2Icon = 'Stretch2Icon',
  StrikethroughIcon = 'StrikethroughIcon',
  StrongNoIcon = 'StrongNoIcon',
  StrongYesIcon = 'StrongYesIcon',
  SumFilledIcon = 'SumFilledIcon',
  SumOutlineIcon = 'SumOutlineIcon',
  SunFilledIcon = 'SunFilledIcon',
  SunOutlineIcon = 'SunOutlineIcon',
  SwapIcon = 'SwapIcon',
  SwissFrancIcon = 'SwissFrancIcon',
  TableColumnFilledIcon = 'TableColumnFilledIcon',
  TableColumnOutlineIcon = 'TableColumnOutlineIcon',
  TableIcon = 'TableIcon',
  TalentSignalFilledIcon = 'TalentSignalFilledIcon',
  TalentSignalOutlineIcon = 'TalentSignalOutlineIcon',
  TalentFilledIcon = 'TalentFilledIcon',
  TalentOutlineIcon = 'TalentOutlineIcon',
  TaskFilledIcon = 'TaskFilledIcon',
  TaskOutlineIcon = 'TaskOutlineIcon',
  TasksFilledIcon = 'TasksFilledIcon',
  TasksOutlineIcon = 'TasksOutlineIcon',
  TaxWithholdingFilledIcon = 'TaxWithholdingFilledIcon',
  TaxWithholdingOutlineIcon = 'TaxWithholdingOutlineIcon',
  TaxesFilledIcon = 'TaxesFilledIcon',
  TaxesOutlineIcon = 'TaxesOutlineIcon',
  TechnologyFilledIcon = 'TechnologyFilledIcon',
  TechnologyOutlineIcon = 'TechnologyOutlineIcon',
  ThaiBahtIcon = 'ThaiBahtIcon',
  ThirdPartyDataIcon = 'ThirdPartyDataIcon',
  ThumbsDownFilledIcon = 'ThumbsDownFilledIcon',
  ThumbsDownOutlineIcon = 'ThumbsDownOutlineIcon',
  ThumbsUpFilledIcon = 'ThumbsUpFilledIcon',
  ThumbsUpOutlineIcon = 'ThumbsUpOutlineIcon',
  ThumbtackFilledIcon = 'ThumbtackFilledIcon',
  ThumbtackOutlineIcon = 'ThumbtackOutlineIcon',
  ThunderboltFilledIcon = 'ThunderboltFilledIcon',
  ThunderboltOutlineIcon = 'ThunderboltOutlineIcon',
  TimeFilledIcon = 'TimeFilledIcon',
  TimeOutlineIcon = 'TimeOutlineIcon',
  ToiletFilledIcon = 'ToiletFilledIcon',
  ToiletOutlineIcon = 'ToiletOutlineIcon',
  ToolsFilledIcon = 'ToolsFilledIcon',
  ToolsOutlineIcon = 'ToolsOutlineIcon',
  TranslateIcon = 'TranslateIcon',
  TrashFilledIcon = 'TrashFilledIcon',
  TrashOutlineIcon = 'TrashOutlineIcon',
  TravelFilledIcon = 'TravelFilledIcon',
  TravelOutlineIcon = 'TravelOutlineIcon',
  TrendReportFilledIcon = 'TrendReportFilledIcon',
  TrendReportOutlineIcon = 'TrendReportOutlineIcon',
  TripIcon = 'TripIcon',
  TrophyIcon = 'TrophyIcon',
  TwoFactorDevicesFilledIcon = 'TwoFactorDevicesFilledIcon',
  TwoFactorDevicesOutlineIcon = 'TwoFactorDevicesOutlineIcon',
  UShieldFilledIcon = 'UShieldFilledIcon',
  UShieldOutlineIcon = 'UShieldOutlineIcon',
  UnarchiveFilledIcon = 'UnarchiveFilledIcon',
  UnarchiveOutlineIcon = 'UnarchiveOutlineIcon',
  UnassignComputerFilledIcon = 'UnassignComputerFilledIcon',
  UnassignComputerOutlineIcon = 'UnassignComputerOutlineIcon',
  UnderlineIcon = 'UnderlineIcon',
  UndoIcon = 'UndoIcon',
  UnifiedSystemFilledIcon = 'UnifiedSystemFilledIcon',
  UnifiedSystemOutlineIcon = 'UnifiedSystemOutlineIcon',
  UninstallFilledIcon = 'UninstallFilledIcon',
  UninstallOutlineIcon = 'UninstallOutlineIcon',
  UniqueIdFilledIcon = 'UniqueIdFilledIcon',
  UniqueIdOutlineIcon = 'UniqueIdOutlineIcon',
  UnlimitedPtoFilledIcon = 'UnlimitedPtoFilledIcon',
  UnlimitedPtoOutlineIcon = 'UnlimitedPtoOutlineIcon',
  UnlockFilledIcon = 'UnlockFilledIcon',
  UnlockOutlineIcon = 'UnlockOutlineIcon',
  UpdateAddressIcon = 'UpdateAddressIcon',
  UploadIcon = 'UploadIcon',
  UserCogFilledIcon = 'UserCogFilledIcon',
  UserCogOutlineIcon = 'UserCogOutlineIcon',
  UserDepartmentFilledIcon = 'UserDepartmentFilledIcon',
  UserDepartmentOutlineIcon = 'UserDepartmentOutlineIcon',
  UserFileFilledIcon = 'UserFileFilledIcon',
  UserFileOutlineIcon = 'UserFileOutlineIcon',
  UserLaptopFilledIcon = 'UserLaptopFilledIcon',
  UserLaptopOutlineIcon = 'UserLaptopOutlineIcon',
  UserFilledIcon = 'UserFilledIcon',
  UserOutlineIcon = 'UserOutlineIcon',
  UsercircleplusFilledIcon = 'UsercircleplusFilledIcon',
  UsercircleplusOutlineIcon = 'UsercircleplusOutlineIcon',
  UsersFilledIcon = 'UsersFilledIcon',
  UsersOutlineIcon = 'UsersOutlineIcon',
  UserticketFilledIcon = 'UserticketFilledIcon',
  UserticketOutlineIcon = 'UserticketOutlineIcon',
  VariableIcon = 'VariableIcon',
  VaultFilledIcon = 'VaultFilledIcon',
  VaultOutlineIcon = 'VaultOutlineIcon',
  VendorFilledIcon = 'VendorFilledIcon',
  VendorOutlineIcon = 'VendorOutlineIcon',
  VennDiagramAndFilledIcon = 'VennDiagramAndFilledIcon',
  VennDiagramAndOutlineIcon = 'VennDiagramAndOutlineIcon',
  VennDiagramOrFilledIcon = 'VennDiagramOrFilledIcon',
  VennDiagramOrOutlineIcon = 'VennDiagramOrOutlineIcon',
  VerifiedFilledIcon = 'VerifiedFilledIcon',
  VerifiedOutlineIcon = 'VerifiedOutlineIcon',
  VeryDissatisfiedFilledIcon = 'VeryDissatisfiedFilledIcon',
  VeryDissatisfiedOutlineIcon = 'VeryDissatisfiedOutlineIcon',
  VerySatisfiedFilledIcon = 'VerySatisfiedFilledIcon',
  VerySatisfiedOutlineIcon = 'VerySatisfiedOutlineIcon',
  VideoFilledIcon = 'VideoFilledIcon',
  VideoOutlineIcon = 'VideoOutlineIcon',
  VpnFilledIcon = 'VpnFilledIcon',
  VpnOutlineIcon = 'VpnOutlineIcon',
  WarningCircleFilledIcon = 'WarningCircleFilledIcon',
  WarningCircleOutlineIcon = 'WarningCircleOutlineIcon',
  WarningTriangleFilledIcon = 'WarningTriangleFilledIcon',
  WarningTriangleOutlineIcon = 'WarningTriangleOutlineIcon',
  WellnessAndMeditationClassesFilledIcon = 'WellnessAndMeditationClassesFilledIcon',
  WellnessAndMeditationClassesOutlineIcon = 'WellnessAndMeditationClassesOutlineIcon',
  WifiCrossedIcon = 'WifiCrossedIcon',
  WifiIcon = 'WifiIcon',
  WineClubFilledIcon = 'WineClubFilledIcon',
  WineClubOutlineIcon = 'WineClubOutlineIcon',
  WithdrawFilledIcon = 'WithdrawFilledIcon',
  WithdrawOutlineIcon = 'WithdrawOutlineIcon',
  WorkFromHomeStipendFilledIcon = 'WorkFromHomeStipendFilledIcon',
  WorkFromHomeStipendOutlineIcon = 'WorkFromHomeStipendOutlineIcon',
  WrenchFilledIcon = 'WrenchFilledIcon',
  WrenchOutlineIcon = 'WrenchOutlineIcon',
  XmlFilledIcon = 'XmlFilledIcon',
  XmlOutlineIcon = 'XmlOutlineIcon',
  YenCircleFilledIcon = 'YenCircleFilledIcon',
  YenCircleOutlineIcon = 'YenCircleOutlineIcon',
  YenIcon = 'YenIcon',
  ZipFilledIcon = 'ZipFilledIcon',
  ZipOutlineIcon = 'ZipOutlineIcon',
  ZoomInFilledIcon = 'ZoomInFilledIcon',
  ZoomInOutlineIcon = 'ZoomInOutlineIcon',
  ZoomOutFilledIcon = 'ZoomOutFilledIcon',
  ZoomOutOutlineIcon = 'ZoomOutOutlineIcon',
  ArrowsdownLeftRightIcon = 'ArrowsdownLeftRightIcon',
  BoldIcon = 'BoldIcon',
  DepartdateIcon = 'DepartdateIcon',
  IPhoneFilledIcon = 'IPhoneFilledIcon',
  IPhoneOutlineIcon = 'IPhoneOutlineIcon',
  Illustration401kRetirementDark = 'Illustration401kRetirementDark',
  Illustration401kRetirementLight = 'Illustration401kRetirementLight',
  IllustrationAIDark = 'IllustrationAIDark',
  IllustrationAIDark1 = 'IllustrationAIDark1',
  IllustrationAIDark2 = 'IllustrationAIDark2',
  IllustrationAILight = 'IllustrationAILight',
  IllustrationAddDark = 'IllustrationAddDark',
  IllustrationAddLight = 'IllustrationAddLight',
  IllustrationAutomateDark = 'IllustrationAutomateDark',
  IllustrationAutomateLight = 'IllustrationAutomateLight',
  IllustrationBarChartDecreaseDark = 'IllustrationBarChartDecreaseDark',
  IllustrationBarChartDecreaseLight = 'IllustrationBarChartDecreaseLight',
  IllustrationBarChartIncreasedark = 'IllustrationBarChartIncreasedark',
  IllustrationBarChartIncreaselight = 'IllustrationBarChartIncreaselight',
  IllustrationBenefitsAdministrationDark = 'IllustrationBenefitsAdministrationDark',
  IllustrationBenefitsAdministrationLight = 'IllustrationBenefitsAdministrationLight',
  IllustrationBrainDark = 'IllustrationBrainDark',
  IllustrationBrainLight = 'IllustrationBrainLight',
  IllustrationCalendarDark = 'IllustrationCalendarDark',
  IllustrationCalendarLight = 'IllustrationCalendarLight',
  IllustrationClockDark = 'IllustrationClockDark',
  IllustrationClockLight = 'IllustrationClockLight',
  IllustrationComplianceDark = 'IllustrationComplianceDark',
  IllustrationComplianceLight = 'IllustrationComplianceLight',
  IllustrationCoreDark = 'IllustrationCoreDark',
  IllustrationCoreLight = 'IllustrationCoreLight',
  IllustrationCreditCardDark = 'IllustrationCreditCardDark',
  IllustrationCreditCardDark1 = 'IllustrationCreditCardDark1',
  IllustrationCreditCardDark2 = 'IllustrationCreditCardDark2',
  IllustrationCreditCardLight = 'IllustrationCreditCardLight',
  IllustrationDeveloperDark = 'IllustrationDeveloperDark',
  IllustrationDeveloperLight = 'IllustrationDeveloperLight',
  IllustrationDeviceDark = 'IllustrationDeviceDark',
  IllustrationDeviceLight = 'IllustrationDeviceLight',
  IllustrationDocumentsDark = 'IllustrationDocumentsDark',
  IllustrationDocumentsLight = 'IllustrationDocumentsLight',
  IllustrationEnterpriseDark = 'IllustrationEnterpriseDark',
  IllustrationEnterpriseLight = 'IllustrationEnterpriseLight',
  IllustrationErrorDark = 'IllustrationErrorDark',
  IllustrationErrorLight = 'IllustrationErrorLight',
  IllustrationFolderDark = 'IllustrationFolderDark',
  IllustrationFolderLight = 'IllustrationFolderLight',
  IllustrationGearDark = 'IllustrationGearDark',
  IllustrationGearLight = 'IllustrationGearLight',
  IllustrationGlobeDark = 'IllustrationGlobeDark',
  IllustrationGlobeLight = 'IllustrationGlobeLight',
  IllustrationGlobeLight1 = 'IllustrationGlobeLight1',
  IllustrationGlobeLight2 = 'IllustrationGlobeLight2',
  IllustrationHandshakeDark = 'IllustrationHandshakeDark',
  IllustrationHandshakeDark1 = 'IllustrationHandshakeDark1',
  IllustrationHandshakeLight = 'IllustrationHandshakeLight',
  IllustrationHeadcountPlanningDark = 'IllustrationHeadcountPlanningDark',
  IllustrationHeadcountPlanningLight = 'IllustrationHeadcountPlanningLight',
  IllustrationHealthInsuranceDark = 'IllustrationHealthInsuranceDark',
  IllustrationHealthInsuranceDark1 = 'IllustrationHealthInsuranceDark1',
  IllustrationHealthInsuranceDark2 = 'IllustrationHealthInsuranceDark2',
  IllustrationHealthInsuranceLight = 'IllustrationHealthInsuranceLight',
  IllustrationIntegrationDark = 'IllustrationIntegrationDark',
  IllustrationIntegrationLight = 'IllustrationIntegrationLight',
  IllustrationInventoryManagementDark = 'IllustrationInventoryManagementDark',
  IllustrationInventoryManagementLight = 'IllustrationInventoryManagementLight',
  IllustrationLearningDark = 'IllustrationLearningDark',
  IllustrationLearningLight = 'IllustrationLearningLight',
  IllustrationLightningBoltDark = 'IllustrationLightningBoltDark',
  IllustrationLightningBoltLight = 'IllustrationLightningBoltLight',
  IllustrationLocalCurrencyDark = 'IllustrationLocalCurrencyDark',
  IllustrationLocalCurrencyLight = 'IllustrationLocalCurrencyLight',
  IllustrationLockDark = 'IllustrationLockDark',
  IllustrationLockLight = 'IllustrationLockLight',
  IllustrationMagnifyingGlasQuestionMarkDark = 'IllustrationMagnifyingGlasQuestionMarkDark',
  IllustrationMagnifyingGlasQuestionMarkDark1 = 'IllustrationMagnifyingGlasQuestionMarkDark1',
  IllustrationMagnifyingGlasQuestionMarkLight = 'IllustrationMagnifyingGlasQuestionMarkLight',
  IllustrationMagnifyingGlassDark = 'IllustrationMagnifyingGlassDark',
  IllustrationMagnifyingGlassLight = 'IllustrationMagnifyingGlassLight',
  IllustrationOffboardingEmployeeDark = 'IllustrationOffboardingEmployeeDark',
  IllustrationOffboardingEmployeeLight = 'IllustrationOffboardingEmployeeLight',
  IllustrationOnboardingEmployeeDark = 'IllustrationOnboardingEmployeeDark',
  IllustrationOnboardingEmployeeLight = 'IllustrationOnboardingEmployeeLight',
  IllustrationPayrollEuroDark = 'IllustrationPayrollEuroDark',
  IllustrationPayrollEuroLight = 'IllustrationPayrollEuroLight',
  IllustrationPayrollPoundDark = 'IllustrationPayrollPoundDark',
  IllustrationPayrollPoundLight = 'IllustrationPayrollPoundLight',
  IllustrationPayrollRupeeDark = 'IllustrationPayrollRupeeDark',
  IllustrationPayrollRupeeLight = 'IllustrationPayrollRupeeLight',
  IllustrationPayrollUSDDark = 'IllustrationPayrollUSDDark',
  IllustrationPayrollUSDLight = 'IllustrationPayrollUSDLight',
  IllustrationPayrollYenDark = 'IllustrationPayrollYenDark',
  IllustrationPayrollYenDark1 = 'IllustrationPayrollYenDark1',
  IllustrationPayrollYenDark2 = 'IllustrationPayrollYenDark2',
  IllustrationPayrollYenLight = 'IllustrationPayrollYenLight',
  IllustrationProDark = 'IllustrationProDark',
  IllustrationProLight = 'IllustrationProLight',
  IllustrationQuestionMarkDark = 'IllustrationQuestionMarkDark',
  IllustrationQuestionMarkLight = 'IllustrationQuestionMarkLight',
  IllustrationReceiptDark = 'IllustrationReceiptDark',
  IllustrationReceiptLight = 'IllustrationReceiptLight',
  IllustrationRemoveDark = 'IllustrationRemoveDark',
  IllustrationRemoveLight = 'IllustrationRemoveLight',
  IllustrationRemoveLight1 = 'IllustrationRemoveLight1',
  IllustrationRemoveLight2 = 'IllustrationRemoveLight2',
  IllustrationSupportDark = 'IllustrationSupportDark',
  IllustrationSupportLight = 'IllustrationSupportLight',
  IllustrationSyncDark = 'IllustrationSyncDark',
  IllustrationSyncLight = 'IllustrationSyncLight',
  IllustrationThirdPartyAppsDark = 'IllustrationThirdPartyAppsDark',
  IllustrationThirdPartyAppsLight = 'IllustrationThirdPartyAppsLight',
  IllustrationUnifiedSystemDark = 'IllustrationUnifiedSystemDark',
  IllustrationUnifiedSystemLight = 'IllustrationUnifiedSystemLight',
  IllustrationUnlimitedDark = 'IllustrationUnlimitedDark',
  IllustrationUnlimitedLight = 'IllustrationUnlimitedLight',
  IllustrationUnlockDark = 'IllustrationUnlockDark',
  IllustrationUnlockLight = 'IllustrationUnlockLight',
  IllustrationApprovalDark = 'IllustrationApprovalDark',
  IllustrationApprovalLight = 'IllustrationApprovalLight',
  IllustrationApprovalLight1 = 'IllustrationApprovalLight1',
  IllustrationApprovalLight2 = 'IllustrationApprovalLight2',
  IllustrationCelebrationDark = 'IllustrationCelebrationDark',
  IllustrationCelebrationLight = 'IllustrationCelebrationLight',
  IllustrationCelebrationLight1 = 'IllustrationCelebrationLight1',
  IllustrationCelebrationLight2 = 'IllustrationCelebrationLight2',
  IllustrationCheckmarkDark = 'IllustrationCheckmarkDark',
  IllustrationCheckmarkLight = 'IllustrationCheckmarkLight',
  IllustrationCommunityDark = 'IllustrationCommunityDark',
  IllustrationCommunityLight = 'IllustrationCommunityLight',
  IllustrationCommuterBenefitsDark = 'IllustrationCommuterBenefitsDark',
  IllustrationCommuterBenefitsLight = 'IllustrationCommuterBenefitsLight',
  IllustrationDentalDark = 'IllustrationDentalDark',
  IllustrationDentalLight = 'IllustrationDentalLight',
  IllustrationEnterpriseBusinessDark = 'IllustrationEnterpriseBusinessDark',
  IllustrationEnterpriseBusinessLight = 'IllustrationEnterpriseBusinessLight',
  IllustrationExpenseManagementDark = 'IllustrationExpenseManagementDark',
  IllustrationExpenseManagementLight = 'IllustrationExpenseManagementLight',
  IllustrationIndividualDark = 'IllustrationIndividualDark',
  IllustrationIndividualLight = 'IllustrationIndividualLight',
  IllustrationLeaderDark = 'IllustrationLeaderDark',
  IllustrationLeaderLight = 'IllustrationLeaderLight',
  IllustrationMegaphoneDark = 'IllustrationMegaphoneDark',
  IllustrationMegaphoneLight = 'IllustrationMegaphoneLight',
  IllustrationMegaphoneLight1 = 'IllustrationMegaphoneLight1',
  IllustrationMegaphoneLight2 = 'IllustrationMegaphoneLight2',
  IllustrationMidSizeBusinessDark = 'IllustrationMidSizeBusinessDark',
  IllustrationMidSizeBusinessLight = 'IllustrationMidSizeBusinessLight',
  IllustrationPerformanceManagementDark = 'IllustrationPerformanceManagementDark',
  IllustrationPerformanceManagementLight = 'IllustrationPerformanceManagementLight',
  IllustrationPieChartDark = 'IllustrationPieChartDark',
  IllustrationPieChartLight = 'IllustrationPieChartLight',
  IllustrationPulseSurveyDark = 'IllustrationPulseSurveyDark',
  IllustrationPulseSurveyDark1 = 'IllustrationPulseSurveyDark1',
  IllustrationPulseSurveyDark2 = 'IllustrationPulseSurveyDark2',
  IllustrationPulseSurveyLight = 'IllustrationPulseSurveyLight',
  IllustrationSmallBusinessDark = 'IllustrationSmallBusinessDark',
  IllustrationSmallBusinessLight = 'IllustrationSmallBusinessLight',
  IllustrationSmartPhoneDark = 'IllustrationSmartPhoneDark',
  IllustrationSmartPhoneLight = 'IllustrationSmartPhoneLight',
  IllustrationTaxesDark = 'IllustrationTaxesDark',
  IllustrationTaxesLight = 'IllustrationTaxesLight',
  IllustrationThumbsUpdark = 'IllustrationThumbsUpdark',
  IllustrationThumbsUplight = 'IllustrationThumbsUplight',
  IllustrationTravelDark = 'IllustrationTravelDark',
  IllustrationTravelLight = 'IllustrationTravelLight',
  IllustrationVisionDark = 'IllustrationVisionDark',
  IllustrationVisionLight = 'IllustrationVisionLight',
  IllustrationWorkforcePlanningDark = 'IllustrationWorkforcePlanningDark',
  IllustrationWorkforcePlanningLight = 'IllustrationWorkforcePlanningLight',
  Product11s = 'Product11s',
  Product401k = 'Product401k',
  ProductADD = 'ProductADD',
  ProductAPI = 'ProductAPI',
  ProductASO = 'ProductASO',
  ProductEAP = 'ProductEAP',
  ProductEOR = 'ProductEOR',
  ProductFluxInbox = 'ProductFluxInbox',
  ProductRipplingBrowserExtension = 'ProductRipplingBrowserExtension',
  ProductAca = 'ProductAca',
  ProductAccidentInsurance = 'ProductAccidentInsurance',
  ProductAccountAndSettings = 'ProductAccountAndSettings',
  ProductAccountant = 'ProductAccountant',
  ProductAccountingIntegrationsEuro = 'ProductAccountingIntegrationsEuro',
  ProductAccountingIntegrationsPound = 'ProductAccountingIntegrationsPound',
  ProductAccountingIntegrationsRupee = 'ProductAccountingIntegrationsRupee',
  ProductAccountingIntegrationsUsd = 'ProductAccountingIntegrationsUsd',
  ProductAccountingIntegrationsYen = 'ProductAccountingIntegrationsYen',
  ProductAddApp = 'ProductAddApp',
  ProductAddictionSupport = 'ProductAddictionSupport',
  ProductAdoptionServices = 'ProductAdoptionServices',
  ProductAnniversaries = 'ProductAnniversaries',
  ProductAntivirus = 'ProductAntivirus',
  ProductAppManagement = 'ProductAppManagement',
  ProductAppShop = 'ProductAppShop',
  ProductApprovals = 'ProductApprovals',
  ProductAuditLog = 'ProductAuditLog',
  ProductAutism = 'ProductAutism',
  ProductAutomatedCompliance = 'ProductAutomatedCompliance',
  ProductBenefitsAdmin = 'ProductBenefitsAdmin',
  ProductBillPayEuro = 'ProductBillPayEuro',
  ProductBillPayPound = 'ProductBillPayPound',
  ProductBillPayRupee = 'ProductBillPayRupee',
  ProductBillPayUsd = 'ProductBillPayUsd',
  ProductBillPayYen = 'ProductBillPayYen',
  ProductBillingEuro = 'ProductBillingEuro',
  ProductBillingPound = 'ProductBillingPound',
  ProductBillingRupee = 'ProductBillingRupee',
  ProductBillingUsd = 'ProductBillingUsd',
  ProductBillingYen = 'ProductBillingYen',
  ProductCancer = 'ProductCancer',
  ProductCareManagement = 'ProductCareManagement',
  ProductCareNavigation = 'ProductCareNavigation',
  ProductCelebrations = 'ProductCelebrations',
  ProductClientCommandCenter = 'ProductClientCommandCenter',
  ProductCobra = 'ProductCobra',
  ProductCommuterBenefits = 'ProductCommuterBenefits',
  ProductCompanyAdmins = 'ProductCompanyAdmins',
  ProductCompanyDetails = 'ProductCompanyDetails',
  ProductCompanySettings = 'ProductCompanySettings',
  ProductCompensationBands = 'ProductCompensationBands',
  ProductCompliance360 = 'ProductCompliance360',
  ProductContractors = 'ProductContractors',
  ProductCovid19 = 'ProductCovid19',
  ProductCriticalIllnessInsurance = 'ProductCriticalIllnessInsurance',
  ProductCustomAlerts = 'ProductCustomAlerts',
  ProductCustomApps = 'ProductCustomApps',
  ProductCustomFields = 'ProductCustomFields',
  ProductCustomFluxCustomFluxOption1 = 'ProductCustomFluxCustomFluxOption1',
  ProductCustomFluxCustomFluxOption2 = 'ProductCustomFluxCustomFluxOption2',
  ProductCustomFluxCustomFluxOption3 = 'ProductCustomFluxCustomFluxOption3',
  ProductCustomIntegrations = 'ProductCustomIntegrations',
  ProductDataManager1 = 'ProductDataManager1',
  ProductDataManager = 'ProductDataManager',
  ProductDental = 'ProductDental',
  ProductDeviceManagement = 'ProductDeviceManagement',
  ProductDocuments = 'ProductDocuments',
  ProductEeoReporting = 'ProductEeoReporting',
  ProductEmployeeHome = 'ProductEmployeeHome',
  ProductEmploymentAuthorization = 'ProductEmploymentAuthorization',
  ProductEmploymentVerification = 'ProductEmploymentVerification',
  ProductEstatePlanning = 'ProductEstatePlanning',
  ProductExpenseManagementEuro = 'ProductExpenseManagementEuro',
  ProductExpenseManagementPound = 'ProductExpenseManagementPound',
  ProductExpenseManagementRupee = 'ProductExpenseManagementRupee',
  ProductExpenseManagementUsd = 'ProductExpenseManagementUsd',
  ProductExpenseManagementYen = 'ProductExpenseManagementYen',
  ProductFamilyCareServices = 'ProductFamilyCareServices',
  ProductFeedback = 'ProductFeedback',
  ProductFertility = 'ProductFertility',
  ProductFinancialWellness = 'ProductFinancialWellness',
  ProductFlexibleSpendingAccount = 'ProductFlexibleSpendingAccount',
  ProductForms = 'ProductForms',
  ProductGettingStarted = 'ProductGettingStarted',
  ProductGlobalPayroll = 'ProductGlobalPayroll',
  ProductGlobalSpend = 'ProductGlobalSpend',
  ProductGoals = 'ProductGoals',
  ProductGymStipend = 'ProductGymStipend',
  ProductHeadcountPlanning = 'ProductHeadcountPlanning',
  ProductHealthSavingsAccount = 'ProductHealthSavingsAccount',
  ProductHelp = 'ProductHelp',
  ProductHome = 'ProductHome',
  ProductHospitalIndemnity = 'ProductHospitalIndemnity',
  ProductHrHelpDesk = 'ProductHrHelpDesk',
  ProductI9Verification = 'ProductI9Verification',
  ProductIdentityTheft = 'ProductIdentityTheft',
  ProductIncomeVerificationEuro = 'ProductIncomeVerificationEuro',
  ProductIncomeVerificationPound = 'ProductIncomeVerificationPound',
  ProductIncomeVerificationRupee = 'ProductIncomeVerificationRupee',
  ProductIncomeVerificationUsd = 'ProductIncomeVerificationUsd',
  ProductIncomeVerificationYen = 'ProductIncomeVerificationYen',
  ProductInstantSso = 'ProductInstantSso',
  ProductInsuranceBroker = 'ProductInsuranceBroker',
  ProductInsurance = 'ProductInsurance',
  ProductIntegrations = 'ProductIntegrations',
  ProductInventoryManagement = 'ProductInventoryManagement',
  ProductItHub = 'ProductItHub',
  ProductJobCodes = 'ProductJobCodes',
  ProductLearningManagement = 'ProductLearningManagement',
  ProductLeaveManagement = 'ProductLeaveManagement',
  ProductLegalAid = 'ProductLegalAid',
  ProductLifeInsurance = 'ProductLifeInsurance',
  ProductLogInAs = 'ProductLogInAs',
  ProductLongTermCareInsurance = 'ProductLongTermCareInsurance',
  ProductLongTermDisability = 'ProductLongTermDisability',
  ProductMaternity = 'ProductMaternity',
  ProductMealVoucher = 'ProductMealVoucher',
  ProductMedical = 'ProductMedical',
  ProductMeditationMindfulness = 'ProductMeditationMindfulness',
  ProductMentalHealth = 'ProductMentalHealth',
  ProductMore = 'ProductMore',
  ProductMyBenefits = 'ProductMyBenefits',
  ProductMyPayEuro = 'ProductMyPayEuro',
  ProductMyPayPound = 'ProductMyPayPound',
  ProductMyPayRupee = 'ProductMyPayRupee',
  ProductMyPayUsd = 'ProductMyPayUsd',
  ProductMyPayYen = 'ProductMyPayYen',
  ProductNeedToTalk = 'ProductNeedToTalk',
  ProductNewsfeed = 'ProductNewsfeed',
  ProductNotifications = 'ProductNotifications',
  ProductNutritionCounseling = 'ProductNutritionCounseling',
  ProductOnboardAndOffboard = 'ProductOnboardAndOffboard',
  ProductOrgChart = 'ProductOrgChart',
  ProductOtherBenefits = 'ProductOtherBenefits',
  ProductPayrollEuro = 'ProductPayrollEuro',
  ProductPayrollPound = 'ProductPayrollPound',
  ProductPayrollRupee = 'ProductPayrollRupee',
  ProductPayrollUsd = 'ProductPayrollUsd',
  ProductPayrollYen = 'ProductPayrollYen',
  ProductPensionManagementEuro = 'ProductPensionManagementEuro',
  ProductPensionManagementPound = 'ProductPensionManagementPound',
  ProductPensionManagementRupee = 'ProductPensionManagementRupee',
  ProductPensionManagementUsd = 'ProductPensionManagementUsd',
  ProductPensionManagementYen = 'ProductPensionManagementYen',
  ProductPeoWorkersComp = 'ProductPeoWorkersComp',
  ProductPeo = 'ProductPeo',
  ProductPeopleDirectory = 'ProductPeopleDirectory',
  ProductPermissions = 'ProductPermissions',
  ProductPetInsurance = 'ProductPetInsurance',
  ProductPrimaryCare = 'ProductPrimaryCare',
  ProductPrivate = 'ProductPrivate',
  ProductProcurement = 'ProductProcurement',
  ProductProductWebinars = 'ProductProductWebinars',
  ProductPulse = 'ProductPulse',
  ProductRecipes = 'ProductRecipes',
  ProductRecruiting = 'ProductRecruiting',
  ProductReports = 'ProductReports',
  ProductReviewCycles = 'ProductReviewCycles',
  ProductRipplingLogo = 'ProductRipplingLogo',
  ProductRpass = 'ProductRpass',
  ProductSandbox = 'ProductSandbox',
  ProductScheduling = 'ProductScheduling',
  ProductSecondaryLifeInsurance = 'ProductSecondaryLifeInsurance',
  ProductSecurityTool = 'ProductSecurityTool',
  ProductShortTermDisability = 'ProductShortTermDisability',
  ProductSpendManagement = 'ProductSpendManagement',
  ProductSpoof = 'ProductSpoof',
  ProductSshKeyManager = 'ProductSshKeyManager',
  ProductStateTaxAccounts = 'ProductStateTaxAccounts',
  ProductStudentLoanRepayment = 'ProductStudentLoanRepayment',
  ProductSupergroupManager = 'ProductSupergroupManager',
  ProductSwagStore = 'ProductSwagStore',
  ProductTalentSignal = 'ProductTalentSignal',
  ProductTalkToSalesEuro = 'ProductTalkToSalesEuro',
  ProductTalkToSalesPound = 'ProductTalkToSalesPound',
  ProductTalkToSalesRupee = 'ProductTalkToSalesRupee',
  ProductTalkToSalesUsd = 'ProductTalkToSalesUsd',
  ProductTalkToSalesYen = 'ProductTalkToSalesYen',
  ProductTalkToSomeone = 'ProductTalkToSomeone',
  ProductTalkToSupport = 'ProductTalkToSupport',
  ProductTasks = 'ProductTasks',
  ProductTelemedicine = 'ProductTelemedicine',
  ProductThinkHr = 'ProductThinkHr',
  ProductThirdPartyBenefitsAdmin = 'ProductThirdPartyBenefitsAdmin',
  ProductThirdPartyFlex = 'ProductThirdPartyFlex',
  ProductTimeAndAttendanceKiosk = 'ProductTimeAndAttendanceKiosk',
  ProductTimeAndAttendance = 'ProductTimeAndAttendance',
  ProductTimeOff = 'ProductTimeOff',
  ProductTransformations = 'ProductTransformations',
  ProductTravelInsurance = 'ProductTravelInsurance',
  ProductTravel = 'ProductTravel',
  ProductUniversalSearch = 'ProductUniversalSearch',
  ProductVariableCompensation = 'ProductVariableCompensation',
  ProductVirtualLdap = 'ProductVirtualLdap',
  ProductVision = 'ProductVision',
  ProductWallet = 'ProductWallet',
  ProductWorkersComp = 'ProductWorkersComp',
  ProductWorkflowAutomator = 'ProductWorkflowAutomator',
  ProductWorkplaceIssue = 'ProductWorkplaceIssue',
  ProductWorkplacePosters = 'ProductWorkplacePosters',
  ProductWorkshops = 'ProductWorkshops',
  QrcodeIcon = 'QrcodeIcon',
  ReturndateIcon = 'ReturndateIcon',
  StairsIcon = 'StairsIcon',
  StopatairportFilledIcon = 'StopatairportFilledIcon',
  StopatairportOutlineIcon = 'StopatairportOutlineIcon',
  WindowpopoutoutlineFilledIcon = 'WindowpopoutoutlineFilledIcon',
  WindowpopoutoutlineOutlineIcon = 'WindowpopoutoutlineOutlineIcon',
}
