import {
  FC, lazy, LazyExoticComponent, SVGProps
} from 'react';

import { FlagName } from './flagName';

const ADAndorra = lazy(() => import('./flag-icons').then((module) => ({ default: module.ADAndorra })));
const AEUnitedArabEmirates = lazy(() => import('./flag-icons').then((module) => ({ default: module.AEUnitedArabEmirates })));
const AFAfghanistan = lazy(() => import('./flag-icons').then((module) => ({ default: module.AFAfghanistan })));
const AGAntiguaAndBarbuda = lazy(() => import('./flag-icons').then((module) => ({ default: module.AGAntiguaAndBarbuda })));
const ALAlbania = lazy(() => import('./flag-icons').then((module) => ({ default: module.ALAlbania })));
const AMArmenia = lazy(() => import('./flag-icons').then((module) => ({ default: module.AMArmenia })));
const AOAnguilla = lazy(() => import('./flag-icons').then((module) => ({ default: module.AOAnguilla })));
const AQAntarctica = lazy(() => import('./flag-icons').then((module) => ({ default: module.AQAntarctica })));
const ARArgentina = lazy(() => import('./flag-icons').then((module) => ({ default: module.ARArgentina })));
const ASAmericanSamoa = lazy(() => import('./flag-icons').then((module) => ({ default: module.ASAmericanSamoa })));
const ATAustria = lazy(() => import('./flag-icons').then((module) => ({ default: module.ATAustria })));
const AUAustralia = lazy(() => import('./flag-icons').then((module) => ({ default: module.AUAustralia })));
const AWAruba = lazy(() => import('./flag-icons').then((module) => ({ default: module.AWAruba })));
const AXAlandIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.AXAlandIslands })));
const AZAzerbaijan = lazy(() => import('./flag-icons').then((module) => ({ default: module.AZAzerbaijan })));
const BABosniaAndHerzegovina = lazy(() => import('./flag-icons').then((module) => ({ default: module.BABosniaAndHerzegovina })));
const BBBarbados = lazy(() => import('./flag-icons').then((module) => ({ default: module.BBBarbados })));
const BDBangladesh = lazy(() => import('./flag-icons').then((module) => ({ default: module.BDBangladesh })));
const BEBelgium = lazy(() => import('./flag-icons').then((module) => ({ default: module.BEBelgium })));
const BFBurkinaFaso = lazy(() => import('./flag-icons').then((module) => ({ default: module.BFBurkinaFaso })));
const BGBulgaria = lazy(() => import('./flag-icons').then((module) => ({ default: module.BGBulgaria })));
const BHBahrain = lazy(() => import('./flag-icons').then((module) => ({ default: module.BHBahrain })));
const BIBurundi = lazy(() => import('./flag-icons').then((module) => ({ default: module.BIBurundi })));
const BJBenin = lazy(() => import('./flag-icons').then((module) => ({ default: module.BJBenin })));
const BLSaintBarthlemy = lazy(() => import('./flag-icons').then((module) => ({ default: module.BLSaintBarthlemy })));
const BMBermuda = lazy(() => import('./flag-icons').then((module) => ({ default: module.BMBermuda })));
const BNBruneiDarussalam = lazy(() => import('./flag-icons').then((module) => ({ default: module.BNBruneiDarussalam })));
const BOBolivia = lazy(() => import('./flag-icons').then((module) => ({ default: module.BOBolivia })));
const BQBonaire = lazy(() => import('./flag-icons').then((module) => ({ default: module.BQBonaire })));
const BRBrazil = lazy(() => import('./flag-icons').then((module) => ({ default: module.BRBrazil })));
const BSBahamas = lazy(() => import('./flag-icons').then((module) => ({ default: module.BSBahamas })));
const BTBhutan = lazy(() => import('./flag-icons').then((module) => ({ default: module.BTBhutan })));
const BVBouvetIsland = lazy(() => import('./flag-icons').then((module) => ({ default: module.BVBouvetIsland })));
const BWBotswana = lazy(() => import('./flag-icons').then((module) => ({ default: module.BWBotswana })));
const BYBelarus = lazy(() => import('./flag-icons').then((module) => ({ default: module.BYBelarus })));
const BZBelize = lazy(() => import('./flag-icons').then((module) => ({ default: module.BZBelize })));
const CACanada = lazy(() => import('./flag-icons').then((module) => ({ default: module.CACanada })));
const CCCocosIsland = lazy(() => import('./flag-icons').then((module) => ({ default: module.CCCocosIsland })));
const CDDemocraticRepublicOfTheCongo = lazy(() => import('./flag-icons').then((module) => ({ default: module.CDDemocraticRepublicOfTheCongo })));
const CFCentralAfricanRepublic = lazy(() => import('./flag-icons').then((module) => ({ default: module.CFCentralAfricanRepublic })));
const CGRepublicOfTheCongo = lazy(() => import('./flag-icons').then((module) => ({ default: module.CGRepublicOfTheCongo })));
const CHSwitzerland = lazy(() => import('./flag-icons').then((module) => ({ default: module.CHSwitzerland })));
const CICoteDIvoire = lazy(() => import('./flag-icons').then((module) => ({ default: module.CICoteDIvoire })));
const CKCookIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.CKCookIslands })));
const CLChile = lazy(() => import('./flag-icons').then((module) => ({ default: module.CLChile })));
const CMCameroon = lazy(() => import('./flag-icons').then((module) => ({ default: module.CMCameroon })));
const CNChina = lazy(() => import('./flag-icons').then((module) => ({ default: module.CNChina })));
const COColombia = lazy(() => import('./flag-icons').then((module) => ({ default: module.COColombia })));
const CRCostaRica = lazy(() => import('./flag-icons').then((module) => ({ default: module.CRCostaRica })));
const CUCuba = lazy(() => import('./flag-icons').then((module) => ({ default: module.CUCuba })));
const CVCaboVerde = lazy(() => import('./flag-icons').then((module) => ({ default: module.CVCaboVerde })));
const CWCuracao = lazy(() => import('./flag-icons').then((module) => ({ default: module.CWCuracao })));
const CXChristmasIsland = lazy(() => import('./flag-icons').then((module) => ({ default: module.CXChristmasIsland })));
const CYCyprus = lazy(() => import('./flag-icons').then((module) => ({ default: module.CYCyprus })));
const CZCzechRepublic = lazy(() => import('./flag-icons').then((module) => ({ default: module.CZCzechRepublic })));
const DEGermany = lazy(() => import('./flag-icons').then((module) => ({ default: module.DEGermany })));
const DJDjibouti = lazy(() => import('./flag-icons').then((module) => ({ default: module.DJDjibouti })));
const DKDenmark = lazy(() => import('./flag-icons').then((module) => ({ default: module.DKDenmark })));
const DMDominica = lazy(() => import('./flag-icons').then((module) => ({ default: module.DMDominica })));
const DODominicanRepublic = lazy(() => import('./flag-icons').then((module) => ({ default: module.DODominicanRepublic })));
const DZAlgeria = lazy(() => import('./flag-icons').then((module) => ({ default: module.DZAlgeria })));
const ECEcuador = lazy(() => import('./flag-icons').then((module) => ({ default: module.ECEcuador })));
const EEEstonia = lazy(() => import('./flag-icons').then((module) => ({ default: module.EEEstonia })));
const EGEgypt = lazy(() => import('./flag-icons').then((module) => ({ default: module.EGEgypt })));
const EREritrea = lazy(() => import('./flag-icons').then((module) => ({ default: module.EREritrea })));
const ESSpain = lazy(() => import('./flag-icons').then((module) => ({ default: module.ESSpain })));
const ETEthiopia = lazy(() => import('./flag-icons').then((module) => ({ default: module.ETEthiopia })));
const FIFinland = lazy(() => import('./flag-icons').then((module) => ({ default: module.FIFinland })));
const FJFiji = lazy(() => import('./flag-icons').then((module) => ({ default: module.FJFiji })));
const FKIslasMalvinasFalklandIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.FKIslasMalvinasFalklandIslands })));
const FOFaroeIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.FOFaroeIslands })));
const FRFrance = lazy(() => import('./flag-icons').then((module) => ({ default: module.FRFrance })));
const GAGabon = lazy(() => import('./flag-icons').then((module) => ({ default: module.GAGabon })));
const GBUnitedKingdom = lazy(() => import('./flag-icons').then((module) => ({ default: module.GBUnitedKingdom })));
const GDGrenada = lazy(() => import('./flag-icons').then((module) => ({ default: module.GDGrenada })));
const GEGeorgia = lazy(() => import('./flag-icons').then((module) => ({ default: module.GEGeorgia })));
const GFFrenchGuiana = lazy(() => import('./flag-icons').then((module) => ({ default: module.GFFrenchGuiana })));
const GGGuernsey = lazy(() => import('./flag-icons').then((module) => ({ default: module.GGGuernsey })));
const GHGhana = lazy(() => import('./flag-icons').then((module) => ({ default: module.GHGhana })));
const GIGibraltar = lazy(() => import('./flag-icons').then((module) => ({ default: module.GIGibraltar })));
const GLGreenland = lazy(() => import('./flag-icons').then((module) => ({ default: module.GLGreenland })));
const GMGambia = lazy(() => import('./flag-icons').then((module) => ({ default: module.GMGambia })));
const GNGuinea = lazy(() => import('./flag-icons').then((module) => ({ default: module.GNGuinea })));
const GPGuadeloupe = lazy(() => import('./flag-icons').then((module) => ({ default: module.GPGuadeloupe })));
const GQEquatorialGuinea = lazy(() => import('./flag-icons').then((module) => ({ default: module.GQEquatorialGuinea })));
const GRGreece = lazy(() => import('./flag-icons').then((module) => ({ default: module.GRGreece })));
const GTGuatemala = lazy(() => import('./flag-icons').then((module) => ({ default: module.GTGuatemala })));
const GUGuam = lazy(() => import('./flag-icons').then((module) => ({ default: module.GUGuam })));
const GWGuineaBissau = lazy(() => import('./flag-icons').then((module) => ({ default: module.GWGuineaBissau })));
const GYGuyana = lazy(() => import('./flag-icons').then((module) => ({ default: module.GYGuyana })));
const HKHongKong = lazy(() => import('./flag-icons').then((module) => ({ default: module.HKHongKong })));
const HNHonduras = lazy(() => import('./flag-icons').then((module) => ({ default: module.HNHonduras })));
const HRCroatia = lazy(() => import('./flag-icons').then((module) => ({ default: module.HRCroatia })));
const HTHaiti = lazy(() => import('./flag-icons').then((module) => ({ default: module.HTHaiti })));
const HUHungary = lazy(() => import('./flag-icons').then((module) => ({ default: module.HUHungary })));
const IDIndonesia = lazy(() => import('./flag-icons').then((module) => ({ default: module.IDIndonesia })));
const IEIreland = lazy(() => import('./flag-icons').then((module) => ({ default: module.IEIreland })));
const ILIsrael = lazy(() => import('./flag-icons').then((module) => ({ default: module.ILIsrael })));
const IMIsleOfMan = lazy(() => import('./flag-icons').then((module) => ({ default: module.IMIsleOfMan })));
const INIndia = lazy(() => import('./flag-icons').then((module) => ({ default: module.INIndia })));
const IOBritishIndianOceanTerritory = lazy(() => import('./flag-icons').then((module) => ({ default: module.IOBritishIndianOceanTerritory })));
const IQIraq = lazy(() => import('./flag-icons').then((module) => ({ default: module.IQIraq })));
const IRIran = lazy(() => import('./flag-icons').then((module) => ({ default: module.IRIran })));
const ISIceland = lazy(() => import('./flag-icons').then((module) => ({ default: module.ISIceland })));
const ITItaly = lazy(() => import('./flag-icons').then((module) => ({ default: module.ITItaly })));
const ITLithuania = lazy(() => import('./flag-icons').then((module) => ({ default: module.ITLithuania })));
const JEJersey = lazy(() => import('./flag-icons').then((module) => ({ default: module.JEJersey })));
const JMJamaica = lazy(() => import('./flag-icons').then((module) => ({ default: module.JMJamaica })));
const JOJordan = lazy(() => import('./flag-icons').then((module) => ({ default: module.JOJordan })));
const JPJapan = lazy(() => import('./flag-icons').then((module) => ({ default: module.JPJapan })));
const KEKenya = lazy(() => import('./flag-icons').then((module) => ({ default: module.KEKenya })));
const KGKyrgyzstan = lazy(() => import('./flag-icons').then((module) => ({ default: module.KGKyrgyzstan })));
const KHCambodia = lazy(() => import('./flag-icons').then((module) => ({ default: module.KHCambodia })));
const KIKiribati = lazy(() => import('./flag-icons').then((module) => ({ default: module.KIKiribati })));
const KMComoros = lazy(() => import('./flag-icons').then((module) => ({ default: module.KMComoros })));
const KNSaintKittsAndNevis = lazy(() => import('./flag-icons').then((module) => ({ default: module.KNSaintKittsAndNevis })));
const KPNorthKorea = lazy(() => import('./flag-icons').then((module) => ({ default: module.KPNorthKorea })));
const KRSouthKorea = lazy(() => import('./flag-icons').then((module) => ({ default: module.KRSouthKorea })));
const KWKuwait = lazy(() => import('./flag-icons').then((module) => ({ default: module.KWKuwait })));
const KYCaymanIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.KYCaymanIslands })));
const KZKazakhstan = lazy(() => import('./flag-icons').then((module) => ({ default: module.KZKazakhstan })));
const LALaos = lazy(() => import('./flag-icons').then((module) => ({ default: module.LALaos })));
const LBLebanon = lazy(() => import('./flag-icons').then((module) => ({ default: module.LBLebanon })));
const LCSaintLucia = lazy(() => import('./flag-icons').then((module) => ({ default: module.LCSaintLucia })));
const LILiechtenstein = lazy(() => import('./flag-icons').then((module) => ({ default: module.LILiechtenstein })));
const LKSriLanka = lazy(() => import('./flag-icons').then((module) => ({ default: module.LKSriLanka })));
const LRLiberia = lazy(() => import('./flag-icons').then((module) => ({ default: module.LRLiberia })));
const LSLesotho = lazy(() => import('./flag-icons').then((module) => ({ default: module.LSLesotho })));
const LULuxembourg = lazy(() => import('./flag-icons').then((module) => ({ default: module.LULuxembourg })));
const LVLatvia = lazy(() => import('./flag-icons').then((module) => ({ default: module.LVLatvia })));
const LYLibya = lazy(() => import('./flag-icons').then((module) => ({ default: module.LYLibya })));
const MAMorocco = lazy(() => import('./flag-icons').then((module) => ({ default: module.MAMorocco })));
const MCMonaco = lazy(() => import('./flag-icons').then((module) => ({ default: module.MCMonaco })));
const MDMoldova = lazy(() => import('./flag-icons').then((module) => ({ default: module.MDMoldova })));
const MEMontenegro = lazy(() => import('./flag-icons').then((module) => ({ default: module.MEMontenegro })));
const MGMadagascar = lazy(() => import('./flag-icons').then((module) => ({ default: module.MGMadagascar })));
const MHMarshallIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.MHMarshallIslands })));
const MKNorthMacedonia = lazy(() => import('./flag-icons').then((module) => ({ default: module.MKNorthMacedonia })));
const MLMali = lazy(() => import('./flag-icons').then((module) => ({ default: module.MLMali })));
const MMMyanmar = lazy(() => import('./flag-icons').then((module) => ({ default: module.MMMyanmar })));
const MNMongolia = lazy(() => import('./flag-icons').then((module) => ({ default: module.MNMongolia })));
const MOMacau = lazy(() => import('./flag-icons').then((module) => ({ default: module.MOMacau })));
const MQMartinique = lazy(() => import('./flag-icons').then((module) => ({ default: module.MQMartinique })));
const MRMauritania = lazy(() => import('./flag-icons').then((module) => ({ default: module.MRMauritania })));
const MSMontserrat = lazy(() => import('./flag-icons').then((module) => ({ default: module.MSMontserrat })));
const MTMalta = lazy(() => import('./flag-icons').then((module) => ({ default: module.MTMalta })));
const MUMauritius = lazy(() => import('./flag-icons').then((module) => ({ default: module.MUMauritius })));
const MVMaldives = lazy(() => import('./flag-icons').then((module) => ({ default: module.MVMaldives })));
const MWMalawi = lazy(() => import('./flag-icons').then((module) => ({ default: module.MWMalawi })));
const MXMexico = lazy(() => import('./flag-icons').then((module) => ({ default: module.MXMexico })));
const MYMalaysia = lazy(() => import('./flag-icons').then((module) => ({ default: module.MYMalaysia })));
const MZMozambique = lazy(() => import('./flag-icons').then((module) => ({ default: module.MZMozambique })));
const NANamibia = lazy(() => import('./flag-icons').then((module) => ({ default: module.NANamibia })));
const NCNewCeledonia = lazy(() => import('./flag-icons').then((module) => ({ default: module.NCNewCeledonia })));
const NENiger = lazy(() => import('./flag-icons').then((module) => ({ default: module.NENiger })));
const NFNorfolkIsland = lazy(() => import('./flag-icons').then((module) => ({ default: module.NFNorfolkIsland })));
const NGNigeria = lazy(() => import('./flag-icons').then((module) => ({ default: module.NGNigeria })));
const NINicaragua = lazy(() => import('./flag-icons').then((module) => ({ default: module.NINicaragua })));
const NLNetherlands = lazy(() => import('./flag-icons').then((module) => ({ default: module.NLNetherlands })));
const NONorway = lazy(() => import('./flag-icons').then((module) => ({ default: module.NONorway })));
const NPNepal = lazy(() => import('./flag-icons').then((module) => ({ default: module.NPNepal })));
const NRNauru = lazy(() => import('./flag-icons').then((module) => ({ default: module.NRNauru })));
const NUNiue = lazy(() => import('./flag-icons').then((module) => ({ default: module.NUNiue })));
const NZNewZealand = lazy(() => import('./flag-icons').then((module) => ({ default: module.NZNewZealand })));
const OMOman = lazy(() => import('./flag-icons').then((module) => ({ default: module.OMOman })));
const PAPanama = lazy(() => import('./flag-icons').then((module) => ({ default: module.PAPanama })));
const PEPeru = lazy(() => import('./flag-icons').then((module) => ({ default: module.PEPeru })));
const PFFrenchPolynesia = lazy(() => import('./flag-icons').then((module) => ({ default: module.PFFrenchPolynesia })));
const PGPapuaNewGuinea = lazy(() => import('./flag-icons').then((module) => ({ default: module.PGPapuaNewGuinea })));
const PHPhilippines = lazy(() => import('./flag-icons').then((module) => ({ default: module.PHPhilippines })));
const PKPakistan = lazy(() => import('./flag-icons').then((module) => ({ default: module.PKPakistan })));
const PLPoland = lazy(() => import('./flag-icons').then((module) => ({ default: module.PLPoland })));
const PNPitcairn = lazy(() => import('./flag-icons').then((module) => ({ default: module.PNPitcairn })));
const PRPuertoRico = lazy(() => import('./flag-icons').then((module) => ({ default: module.PRPuertoRico })));
const PSStateOfPalestine = lazy(() => import('./flag-icons').then((module) => ({ default: module.PSStateOfPalestine })));
const PTPortugal = lazy(() => import('./flag-icons').then((module) => ({ default: module.PTPortugal })));
const PWPalau = lazy(() => import('./flag-icons').then((module) => ({ default: module.PWPalau })));
const PYParaguay = lazy(() => import('./flag-icons').then((module) => ({ default: module.PYParaguay })));
const QAQatar = lazy(() => import('./flag-icons').then((module) => ({ default: module.QAQatar })));
const REReunion = lazy(() => import('./flag-icons').then((module) => ({ default: module.REReunion })));
const RSSerbia = lazy(() => import('./flag-icons').then((module) => ({ default: module.RSSerbia })));
const RURussia = lazy(() => import('./flag-icons').then((module) => ({ default: module.RURussia })));
const RWRwanda = lazy(() => import('./flag-icons').then((module) => ({ default: module.RWRwanda })));
const RoRomania = lazy(() => import('./flag-icons').then((module) => ({ default: module.RoRomania })));
const SASaudiArabia = lazy(() => import('./flag-icons').then((module) => ({ default: module.SASaudiArabia })));
const SBSolomonIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.SBSolomonIslands })));
const SCSeychelles = lazy(() => import('./flag-icons').then((module) => ({ default: module.SCSeychelles })));
const SDSudan = lazy(() => import('./flag-icons').then((module) => ({ default: module.SDSudan })));
const SESweden = lazy(() => import('./flag-icons').then((module) => ({ default: module.SESweden })));
const SGSingapore = lazy(() => import('./flag-icons').then((module) => ({ default: module.SGSingapore })));
const SHSaintHelena = lazy(() => import('./flag-icons').then((module) => ({ default: module.SHSaintHelena })));
const SISlovenia = lazy(() => import('./flag-icons').then((module) => ({ default: module.SISlovenia })));
const SJSvalbardAndJanMayen = lazy(() => import('./flag-icons').then((module) => ({ default: module.SJSvalbardAndJanMayen })));
const SKSlovakia = lazy(() => import('./flag-icons').then((module) => ({ default: module.SKSlovakia })));
const SLSierraLeone = lazy(() => import('./flag-icons').then((module) => ({ default: module.SLSierraLeone })));
const SMSanMarino = lazy(() => import('./flag-icons').then((module) => ({ default: module.SMSanMarino })));
const SNSenegal = lazy(() => import('./flag-icons').then((module) => ({ default: module.SNSenegal })));
const SOSomalia = lazy(() => import('./flag-icons').then((module) => ({ default: module.SOSomalia })));
const SRSuriname = lazy(() => import('./flag-icons').then((module) => ({ default: module.SRSuriname })));
const SSSouthSudan = lazy(() => import('./flag-icons').then((module) => ({ default: module.SSSouthSudan })));
const STSaoTomeAndPrincipe = lazy(() => import('./flag-icons').then((module) => ({ default: module.STSaoTomeAndPrincipe })));
const SVElSalavador = lazy(() => import('./flag-icons').then((module) => ({ default: module.SVElSalavador })));
const SXSintMaarten = lazy(() => import('./flag-icons').then((module) => ({ default: module.SXSintMaarten })));
const SYSyria = lazy(() => import('./flag-icons').then((module) => ({ default: module.SYSyria })));
const SZEswatini = lazy(() => import('./flag-icons').then((module) => ({ default: module.SZEswatini })));
const TCTurksAndCaicosIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.TCTurksAndCaicosIslands })));
const TDChad = lazy(() => import('./flag-icons').then((module) => ({ default: module.TDChad })));
const TGTogo = lazy(() => import('./flag-icons').then((module) => ({ default: module.TGTogo })));
const THThailand = lazy(() => import('./flag-icons').then((module) => ({ default: module.THThailand })));
const TJTajikistan = lazy(() => import('./flag-icons').then((module) => ({ default: module.TJTajikistan })));
const TKTokelau = lazy(() => import('./flag-icons').then((module) => ({ default: module.TKTokelau })));
const TLTimorLeste = lazy(() => import('./flag-icons').then((module) => ({ default: module.TLTimorLeste })));
const TMTurkmenistan = lazy(() => import('./flag-icons').then((module) => ({ default: module.TMTurkmenistan })));
const TNTunisia = lazy(() => import('./flag-icons').then((module) => ({ default: module.TNTunisia })));
const TOTonga = lazy(() => import('./flag-icons').then((module) => ({ default: module.TOTonga })));
const TTTrinidadAndTobago = lazy(() => import('./flag-icons').then((module) => ({ default: module.TTTrinidadAndTobago })));
const TVTuvalu = lazy(() => import('./flag-icons').then((module) => ({ default: module.TVTuvalu })));
const TWTaiwan = lazy(() => import('./flag-icons').then((module) => ({ default: module.TWTaiwan })));
const TZTanzania = lazy(() => import('./flag-icons').then((module) => ({ default: module.TZTanzania })));
const UAUkraine = lazy(() => import('./flag-icons').then((module) => ({ default: module.UAUkraine })));
const UGUganda = lazy(() => import('./flag-icons').then((module) => ({ default: module.UGUganda })));
const UMUnitedStatesMinorOutlyingIslands = lazy(() => import('./flag-icons').then((module) => ({ default: module.UMUnitedStatesMinorOutlyingIslands })));
const USUnitedStatesOfAmerica = lazy(() => import('./flag-icons').then((module) => ({ default: module.USUnitedStatesOfAmerica })));
const VAHolySee = lazy(() => import('./flag-icons').then((module) => ({ default: module.VAHolySee })));
const VCSaintVincentAndTheGrenadines = lazy(() => import('./flag-icons').then((module) => ({ default: module.VCSaintVincentAndTheGrenadines })));
const WSSamoa = lazy(() => import('./flag-icons').then((module) => ({ default: module.WSSamoa })));
const ZASouthAfrica = lazy(() => import('./flag-icons').then((module) => ({ default: module.ZASouthAfrica })));

const FLAGS: Record<FlagName, LazyExoticComponent<FC<SVGProps<SVGSVGElement>>>> = {
  ADAndorra: ADAndorra,
  AEUnitedArabEmirates: AEUnitedArabEmirates,
  AFAfghanistan: AFAfghanistan,
  AGAntiguaAndBarbuda: AGAntiguaAndBarbuda,
  ALAlbania: ALAlbania,
  AMArmenia: AMArmenia,
  AOAnguilla: AOAnguilla,
  AQAntarctica: AQAntarctica,
  ARArgentina: ARArgentina,
  ASAmericanSamoa: ASAmericanSamoa,
  ATAustria: ATAustria,
  AUAustralia: AUAustralia,
  AWAruba: AWAruba,
  AXAlandIslands: AXAlandIslands,
  AZAzerbaijan: AZAzerbaijan,
  BABosniaAndHerzegovina: BABosniaAndHerzegovina,
  BBBarbados: BBBarbados,
  BDBangladesh: BDBangladesh,
  BEBelgium: BEBelgium,
  BFBurkinaFaso: BFBurkinaFaso,
  BGBulgaria: BGBulgaria,
  BHBahrain: BHBahrain,
  BIBurundi: BIBurundi,
  BJBenin: BJBenin,
  BLSaintBarthlemy: BLSaintBarthlemy,
  BMBermuda: BMBermuda,
  BNBruneiDarussalam: BNBruneiDarussalam,
  BOBolivia: BOBolivia,
  BQBonaire: BQBonaire,
  BRBrazil: BRBrazil,
  BSBahamas: BSBahamas,
  BTBhutan: BTBhutan,
  BVBouvetIsland: BVBouvetIsland,
  BWBotswana: BWBotswana,
  BYBelarus: BYBelarus,
  BZBelize: BZBelize,
  CACanada: CACanada,
  CCCocosIsland: CCCocosIsland,
  CDDemocraticRepublicOfTheCongo: CDDemocraticRepublicOfTheCongo,
  CFCentralAfricanRepublic: CFCentralAfricanRepublic,
  CGRepublicOfTheCongo: CGRepublicOfTheCongo,
  CHSwitzerland: CHSwitzerland,
  CICoteDIvoire: CICoteDIvoire,
  CKCookIslands: CKCookIslands,
  CLChile: CLChile,
  CMCameroon: CMCameroon,
  CNChina: CNChina,
  COColombia: COColombia,
  CRCostaRica: CRCostaRica,
  CUCuba: CUCuba,
  CVCaboVerde: CVCaboVerde,
  CWCuracao: CWCuracao,
  CXChristmasIsland: CXChristmasIsland,
  CYCyprus: CYCyprus,
  CZCzechRepublic: CZCzechRepublic,
  DEGermany: DEGermany,
  DJDjibouti: DJDjibouti,
  DKDenmark: DKDenmark,
  DMDominica: DMDominica,
  DODominicanRepublic: DODominicanRepublic,
  DZAlgeria: DZAlgeria,
  ECEcuador: ECEcuador,
  EEEstonia: EEEstonia,
  EGEgypt: EGEgypt,
  EREritrea: EREritrea,
  ESSpain: ESSpain,
  ETEthiopia: ETEthiopia,
  FIFinland: FIFinland,
  FJFiji: FJFiji,
  FKIslasMalvinasFalklandIslands: FKIslasMalvinasFalklandIslands,
  FOFaroeIslands: FOFaroeIslands,
  FRFrance: FRFrance,
  GAGabon: GAGabon,
  GBUnitedKingdom: GBUnitedKingdom,
  GDGrenada: GDGrenada,
  GEGeorgia: GEGeorgia,
  GFFrenchGuiana: GFFrenchGuiana,
  GGGuernsey: GGGuernsey,
  GHGhana: GHGhana,
  GIGibraltar: GIGibraltar,
  GLGreenland: GLGreenland,
  GMGambia: GMGambia,
  GNGuinea: GNGuinea,
  GPGuadeloupe: GPGuadeloupe,
  GQEquatorialGuinea: GQEquatorialGuinea,
  GRGreece: GRGreece,
  GTGuatemala: GTGuatemala,
  GUGuam: GUGuam,
  GWGuineaBissau: GWGuineaBissau,
  GYGuyana: GYGuyana,
  HKHongKong: HKHongKong,
  HNHonduras: HNHonduras,
  HRCroatia: HRCroatia,
  HTHaiti: HTHaiti,
  HUHungary: HUHungary,
  IDIndonesia: IDIndonesia,
  IEIreland: IEIreland,
  ILIsrael: ILIsrael,
  IMIsleOfMan: IMIsleOfMan,
  INIndia: INIndia,
  IOBritishIndianOceanTerritory: IOBritishIndianOceanTerritory,
  IQIraq: IQIraq,
  IRIran: IRIran,
  ISIceland: ISIceland,
  ITItaly: ITItaly,
  ITLithuania: ITLithuania,
  JEJersey: JEJersey,
  JMJamaica: JMJamaica,
  JOJordan: JOJordan,
  JPJapan: JPJapan,
  KEKenya: KEKenya,
  KGKyrgyzstan: KGKyrgyzstan,
  KHCambodia: KHCambodia,
  KIKiribati: KIKiribati,
  KMComoros: KMComoros,
  KNSaintKittsAndNevis: KNSaintKittsAndNevis,
  KPNorthKorea: KPNorthKorea,
  KRSouthKorea: KRSouthKorea,
  KWKuwait: KWKuwait,
  KYCaymanIslands: KYCaymanIslands,
  KZKazakhstan: KZKazakhstan,
  LALaos: LALaos,
  LBLebanon: LBLebanon,
  LCSaintLucia: LCSaintLucia,
  LILiechtenstein: LILiechtenstein,
  LKSriLanka: LKSriLanka,
  LRLiberia: LRLiberia,
  LSLesotho: LSLesotho,
  LULuxembourg: LULuxembourg,
  LVLatvia: LVLatvia,
  LYLibya: LYLibya,
  MAMorocco: MAMorocco,
  MCMonaco: MCMonaco,
  MDMoldova: MDMoldova,
  MEMontenegro: MEMontenegro,
  MGMadagascar: MGMadagascar,
  MHMarshallIslands: MHMarshallIslands,
  MKNorthMacedonia: MKNorthMacedonia,
  MLMali: MLMali,
  MMMyanmar: MMMyanmar,
  MNMongolia: MNMongolia,
  MOMacau: MOMacau,
  MQMartinique: MQMartinique,
  MRMauritania: MRMauritania,
  MSMontserrat: MSMontserrat,
  MTMalta: MTMalta,
  MUMauritius: MUMauritius,
  MVMaldives: MVMaldives,
  MWMalawi: MWMalawi,
  MXMexico: MXMexico,
  MYMalaysia: MYMalaysia,
  MZMozambique: MZMozambique,
  NANamibia: NANamibia,
  NCNewCeledonia: NCNewCeledonia,
  NENiger: NENiger,
  NFNorfolkIsland: NFNorfolkIsland,
  NGNigeria: NGNigeria,
  NINicaragua: NINicaragua,
  NLNetherlands: NLNetherlands,
  NONorway: NONorway,
  NPNepal: NPNepal,
  NRNauru: NRNauru,
  NUNiue: NUNiue,
  NZNewZealand: NZNewZealand,
  OMOman: OMOman,
  PAPanama: PAPanama,
  PEPeru: PEPeru,
  PFFrenchPolynesia: PFFrenchPolynesia,
  PGPapuaNewGuinea: PGPapuaNewGuinea,
  PHPhilippines: PHPhilippines,
  PKPakistan: PKPakistan,
  PLPoland: PLPoland,
  PNPitcairn: PNPitcairn,
  PRPuertoRico: PRPuertoRico,
  PSStateOfPalestine: PSStateOfPalestine,
  PTPortugal: PTPortugal,
  PWPalau: PWPalau,
  PYParaguay: PYParaguay,
  QAQatar: QAQatar,
  REReunion: REReunion,
  RoRomania: RoRomania,
  RSSerbia: RSSerbia,
  RURussia: RURussia,
  RWRwanda: RWRwanda,
  SASaudiArabia: SASaudiArabia,
  SBSolomonIslands: SBSolomonIslands,
  SCSeychelles: SCSeychelles,
  SDSudan: SDSudan,
  SESweden: SESweden,
  SGSingapore: SGSingapore,
  SHSaintHelena: SHSaintHelena,
  SISlovenia: SISlovenia,
  SJSvalbardAndJanMayen: SJSvalbardAndJanMayen,
  SKSlovakia: SKSlovakia,
  SLSierraLeone: SLSierraLeone,
  SMSanMarino: SMSanMarino,
  SNSenegal: SNSenegal,
  SOSomalia: SOSomalia,
  SRSuriname: SRSuriname,
  SSSouthSudan: SSSouthSudan,
  STSaoTomeAndPrincipe: STSaoTomeAndPrincipe,
  SVElSalavador: SVElSalavador,
  SXSintMaarten: SXSintMaarten,
  SYSyria: SYSyria,
  SZEswatini: SZEswatini,
  TCTurksAndCaicosIslands: TCTurksAndCaicosIslands,
  TDChad: TDChad,
  TGTogo: TGTogo,
  THThailand: THThailand,
  TJTajikistan: TJTajikistan,
  TKTokelau: TKTokelau,
  TLTimorLeste: TLTimorLeste,
  TMTurkmenistan: TMTurkmenistan,
  TNTunisia: TNTunisia,
  TOTonga: TOTonga,
  TTTrinidadAndTobago: TTTrinidadAndTobago,
  TVTuvalu: TVTuvalu,
  TWTaiwan: TWTaiwan,
  TZTanzania: TZTanzania,
  UAUkraine: UAUkraine,
  UGUganda: UGUganda,
  UMUnitedStatesMinorOutlyingIslands: UMUnitedStatesMinorOutlyingIslands,
  USUnitedStatesOfAmerica: USUnitedStatesOfAmerica,
  VAHolySee: VAHolySee,
  VCSaintVincentAndTheGrenadines: VCSaintVincentAndTheGrenadines,
  WSSamoa: WSSamoa,
  ZASouthAfrica: ZASouthAfrica,
};

export default FLAGS;
