import { FC } from 'react';

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import { RipplingLogo } from '../../icons';
import Link from '../atoms/Link';

export interface FooterCondensedProps {
  data: {
    licenses?: string[];
    links: {
      href: string,
      image?: string;
      imageClassName?: string;
      text: string
    }[];
  }
  className?: string;
  locale?: string;
}

const FooterCondensed: FC<FooterCondensedProps> = ({
  className,
  data: { licenses, links },
}) => {
  return (
    <footer className={twMerge('w-full bg-surface-l0 py-2xl', className)}>
      <div className="max-w-3xl mx-auto text-text-primary-inactive">
        <div className="flex flex-col gap-md">
          <RipplingLogo className="max-w-32 text-white" />
          <div className="text-caption">
            <ul className="flex items-center flex-wrap">
              <li className='after:content-["·"] after:p-2xs'>
                <span>© {new Date().getFullYear()} Rippling People Center Inc.</span>
              </li>
              {links.map(({
                href, image, imageClassName, text,
              }, idx) => {
                const isLastOne = idx === links.length - 1;

                return (
                  <li key={idx}>
                    <Link
                      key={href}
                      className={clsx(!isLastOne && 'after:content-["·"] after:p-2xs')}
                      disableDefaultStyle
                      href={href}
                    >
                      {image ? (
                        <span className="relative flex text-balance items-center gap-1 [&>img]:max-w-[30px] [&>img]:absolute [&>img]:-right-9">{text}
                          <img
                            alt={text}
                            className={imageClassName}
                            loading="lazy"
                            src={image}
                          />
                        </span>
                      ) :
                        <span>{text}</span>
                      }
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          {licenses && (
            <div className="text-caption">
              <ul className="flex items-center flex-wrap">
                {licenses.map((text, idx) => {
                  const isLastOne = idx === links.length - 1;

                  return (
                    <li
                      key={idx}
                      className={clsx(!isLastOne && 'after:content-["·"] after:p-2xs')}
                    >
                      {text}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

export default FooterCondensed;
