import { LANDING_PAGE_CONTENTFUL_TYPE } from ':contentTypes/constants';
import { PAGE_WP_TYPE } from ':contentTypes/page';
import { getContentfulGeneratedPaths } from ':contentTypes/utils';
import { isNotNull } from '@rippling/utils';
import path from 'path';

import { VERCEL_ENV } from ':constants/env';
import { getProductionCssFiles } from ':helpers/caching-helpers';
import { readFileWithLog } from ':helpers/fs-helpers';
import { isProductionBuild } from ':helpers/is-env';
import { logWithPrefix } from ':helpers/log-helpers';
import { returnTemplate } from ':lib/_client';
import { checkIsDevelopment, returnStaticProps } from ':lib/_helpers';
import {
  fetchEntry, fetchEntryBySlug, fetchPageLocalePaths, transformContent
} from ':lib/contentful';
import { queryGlobalData } from ':lib/globals';
import LandingPage, { LandingPageProps } from ':templates/LandingPage';
import Authenticator from ':templates/partials/Authenticator';
import SectionBuilder from ':templates/SectionBuilder';

import { LOCALES } from '../locales';

import type { GetStaticPaths, GetStaticProps } from 'next';

const logPrefix = 'pages/[...slug]';
const buildCount = 1000;

const getWPData = ({ locale, slug }: {
  locale: string;
  slug: string
}) => {
  if (!process.env.VERCEL || process.env.CI === '1') {
    let wpData = readFileWithLog({
      defaultValue: null,
      filePath: path.join('./cache', PAGE_WP_TYPE, `${slug}__${locale}.json`),
      logPrefix,
      noSuccessLog: true,
    });

    if (!wpData && locale !== LOCALES.EN_US) {
      // See if the US version is available. If yes, we will use that (backward compatible)
      wpData = readFileWithLog({
        defaultValue: null,
        filePath: path.join(
          './cache',
          PAGE_WP_TYPE,
          `${slug}__${LOCALES.EN_US}.json`
        ),
        logPrefix,
        noSuccessLog: true,
      });
    }

    return wpData;
  }

  return null;
};

const getPageEntries = async ({ locale, slugs }: {
  locale: string;
  slugs: string[];
}) => {
  try {
    const entries = await Promise.all(slugs.map((slug, idx) => {
      let select: string | undefined;

      // First part of path -- should not have parent
      const where = { 'fields.parentPage[exists]': idx !== 0 };

      if (idx > 0) {
        where['fields.parentPage.sys.contentType.sys.id'] = LANDING_PAGE_CONTENTFUL_TYPE;
      }

      if (idx !== slugs.length - 1) {
        // Except last one, we only care about slug and id
        select = 'fields.slug,sys.id';
      }

      return fetchEntryBySlug({
        contentType: LANDING_PAGE_CONTENTFUL_TYPE,
        locale,
        select,
        slug,
        where,
      });
    }));

    const isResolved = entries.every(isNotNull) && entries.every((entry, idx) => {
      return entry.fields.slug === slugs[idx];
    });

    if (isResolved) {
      return entries;
    }
  } catch (error) {
    console.error(error);
  }

  // If one of the entry returns a null, this means one of the page has incorrect parentPage
  return null;
};

const getPaths = async ({ locales }: { locales: string[] }) => {
  const cfPaths = locales.flatMap((locale) => {
    const json = getContentfulGeneratedPaths({
      contentType: LANDING_PAGE_CONTENTFUL_TYPE,
      locale,
      logPrefix,
    }) as {
      id: string;
      locale: string,
      params: { slug: string[] }
      prebuild: boolean;
    }[];

    return json.filter(({ prebuild }) => prebuild);
  });

  const wpPaths = readFileWithLog({
    defaultValue: [],
    filePath: path.join('./cache', PAGE_WP_TYPE, '_paths.json'),
    logPrefix,
    noSuccessLog: true,
  });

  return [...cfPaths, ...wpPaths].map(({ locale, params: { slug } }) => {
    return {
      locale,
      params: { slug },
    };
  });
};

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  let paths = isProductionBuild() ? await getPaths({ locales }) : [];

  if (paths.length > 0) {
    logWithPrefix({
      message: `Total build-time paths: ${paths.length}`,
      prefix: logPrefix,
    });
  }

  if (paths.length > buildCount) {
    logWithPrefix({
      message: `Generated build-time paths: ${buildCount}`,
      prefix: logPrefix,
    });

    paths = paths.slice(0, buildCount);
  }

  return {
    fallback: 'blocking',
    paths,
  };
};

// @ts-expect-error Property 'props' is optional in type
// Remove once fully migrate to Contentful
export const getStaticProps: GetStaticProps<LandingPageProps, { slug: string[] }> = async (context) => {
  const {
    draftMode = false, locale, params: { slug: slugs },
  } = context;

  const entries = await getPageEntries({
    locale,
    slugs,
  });

  if (!entries) {
    // Uncomment once fully migrate to Contentful
    // return { notFound: true };

    // WP code path
    const slug = slugs.join('/');

    // @ts-expect-error Property 'props' is optional in type
    // Remove once fully migrate to Contentful
    return await returnStaticProps({
      locale,
      params: { slug },
      type: PAGE_WP_TYPE,
      wpData: getWPData({
        locale: context.locale,
        slug,
      }),
    });
  }

  // Last item is the current page
  const data = entries.at(-1);

  const [
    prodCssFiles,
    globals,
    footerCondensed,
    stringsData,
  ] = await Promise.all([
    getProductionCssFiles(),

    // @TODO: in the future, we should only pass the right globals to the page. Ex: footerCondensed vs footer
    queryGlobalData({ locale }),
    fetchEntry({
      contentType: 'data',
      locale,
      where: { 'fields.id': 'footer-condensed' },
    }),
    fetchEntry({
      contentType: 'data',
      locale,
      where: { 'fields.id': 'text-list' },
    }),
  ]);

  if (!data) {
    return { notFound: true };
  }

  const allLocalePaths: {
    locale: string;
    path: string;
  }[] = await fetchPageLocalePaths({
    contentType: LANDING_PAGE_CONTENTFUL_TYPE,
    entries: entries.map((entry) => {
      return {
        id: entry.sys.id,
        slug: (entry.fields.slug) as string,
      };
    }),
  });

  return {
    props: {
      data: transformContent(data, { locale }),
      draftMode,
      enableLivePreview: checkIsDevelopment(),
      fromContentful: true,
      globals: {
        ...globals,
        footerCondensed,
        strings: (stringsData.fields.data ?? {}) as Record<string, string>,
      },
      locale,
      localePaths: allLocalePaths,
      prodCssFiles,
      requiresAuth: false,
      slug: slugs.at(-1),
    },
    revalidate: VERCEL_ENV === 'preview' || VERCEL_ENV === 'development' ? 1 : undefined,
  };
};

const LandingPageTemplate = (props: LandingPageProps) => {
  const { fromContentful, requiresAuth } = props;

  if (requiresAuth) {
    return (
      <Authenticator
        action="Redirecting"
        endpoint="www-vercel-authenticate"
      />
    );
  }

  if (fromContentful) {
    return <LandingPage {...props} />;
  }

  return returnTemplate(props, SectionBuilder);
};

export default LandingPageTemplate;
