import { ReactNode } from 'react';

import { GAEventName, sendGAEvent } from '@rippling/utils/gaUtils';
import { twMerge } from 'tailwind-merge';

import { CfLink } from '../../types';
import Link from '../atoms/Link';

export enum ButtonVariant {
  Filled = 'Filled',
  Outline = 'Outline'
}

export enum ButtonSize {
  Fit = 'Fit',
  Full = 'Full'
}

interface DefaultButtonBehavior {
  children: ReactNode;
  cfLinkData?: never;
  href?: string;
}

interface ButtonWithCfLinkData {
  cfLinkData: CfLink;
  children?: never;
  href?: never;
}

export type ButtonProps = {
  className?: string;
  disabled?: boolean;
  id?: string;
  newTab?: boolean;
  onClick?: () => void;
  size?: ButtonSize;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariant;
} & (ButtonWithCfLinkData | DefaultButtonBehavior);

const baselineClasses = 'transition-colors text-p2 font-medium block text-center';

const filledBaselineStyles = 'bg-yellow text-plum-800 can-hover:hover:bg-[#EB9D02]';
const outlinedBaselineStyles =
  'bg-transparent text-text-primary border border-lg border-yellow can-hover:hover:border-yellow-200 can-hover:hover:bg-surface-l1';

const fullWidthStyles = 'rounded-sm w-full p-sm';
const fitWidthStyles = 'rounded-sm px-lg py-sm';

const getDisabledClassNames = ({ disabled, variant }: Pick<ButtonProps, 'disabled' | 'variant'>) => {
  if (!disabled) {
    return null;
  }

  return [
    'text-text-secondary-inactive cursor-not-allowed pointer-events-none',
    variant === ButtonVariant.Filled && 'bg-surface-disabled',
    variant === ButtonVariant.Outline && 'border-surface-disabled',
  ];
};

const Button = ({
  cfLinkData,
  children,
  className,
  disabled,
  href,
  id,
  newTab,
  onClick,
  size = ButtonSize.Fit,
  type = 'button',
  variant = ButtonVariant.Filled,
}: ButtonProps) => {
  const onClickWithEvent = () => {
    sendGAEvent({
      click_url: href ?? '',
      event: GAEventName.ButtonClick,
    });
    onClick?.();
  };

  const sharedProps = {
    children: children ?? cfLinkData?.fields.text,
    className: twMerge(
      baselineClasses,
      variant === ButtonVariant.Filled && filledBaselineStyles,
      variant === ButtonVariant.Outline && outlinedBaselineStyles,
      size === ButtonSize.Full && fullWidthStyles,
      size === ButtonSize.Fit && fitWidthStyles,
      getDisabledClassNames({
        disabled,
        variant,
      }),
      className
    ),
    id,
    onClick: onClickWithEvent,
  };

  if (cfLinkData || href) {
    return (
      <Link
        {...sharedProps}
        cfLinkData={cfLinkData}
        disableDefaultStyle
        href={href}
        newTab={newTab}
      />
    );
  }

  return (
    <button
      {...sharedProps}
      disabled={disabled}
      type={type}
    />
  );
};

export default Button;
